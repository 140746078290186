import { createContext, PropsWithChildren, useEffect, useState } from "react"
interface ThemeContextProps {
  theme: "light" | "dark" | null
  setTheme: (theme: "light" | "dark" | null) => void
}

const ThemeContext = createContext<ThemeContextProps>({
  theme: "light",
  setTheme: () => {},
})

export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const localTheme = localStorage.getItem("theme")

  const systemTheme = () => {
    return window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light"
  }

  const getLocalTheme = (): "dark" | "light" | null => {
    if (localTheme === "dark" || localTheme === "light") {
      return localTheme
    }
    return null
  }

  const setMode = (theme: "dark" | "light") => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark")
      document.documentElement.setAttribute("data-theme", "dark")
      document.body.setAttribute("data-theme", "dark")
    } else {
      document.documentElement.classList.remove("dark")
      document.documentElement.setAttribute("data-theme", "light")
      document.body.setAttribute("data-theme", "light")
    }
  }

  const getMode = (): "dark" | "light" => {
    return document.documentElement.classList.contains("dark")
      ? "dark"
      : "light"
  }

  const [theme, setTheme] = useState<"light" | "dark" | null>(getLocalTheme())

  const mqListener = (ev: MediaQueryListEvent) => {
    if (theme === null) {
      if (getMode() !== (ev.matches ? "dark" : "light")) {
        setMode(ev.matches ? "dark" : "light")
      }
    }
  }

  useEffect(() => {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)")
    darkThemeMq.addEventListener("change", mqListener)
    return () => darkThemeMq.removeEventListener("change", mqListener)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (theme !== null) {
      if (getMode() !== theme) {
        setMode(theme)
      }
      localStorage.setItem("theme", theme)
    } else {
      if (getMode() !== systemTheme()) {
        setMode(systemTheme())
      }
    }
  }, [theme])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContext
