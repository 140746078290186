import { useContext } from "react"
import BannersContext, {
  BannerListData as BannerListDataType,
} from "../context/BannersProvider"

export type BannerListData = BannerListDataType

const useBanners = () => {
  return useContext(BannersContext)
}

export default useBanners
