import Footer from "../../components/Footer"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"
import NavbarPublic from "../../components/navbar/NavbarPublic"
import FAQs from "../../components/FAQs"
import useAuth from "../../hooks/useAuth"

const FAQsPage = () => {
  const { auth } = useAuth()
  const faqs = {
    questions: [
      {
        index: 0,
        question:
          "Are there any prerequisites for enrolling in your cybersecurity trainings?",
        answer:
          "No, there are no specific prerequisites for enrolling in our training. However, we recommend basic knowledge of cybersecurity and networking for a better understanding of the contents of the courses and for taking advantage of the technical content.",
      },
      {
        index: 1,
        question: "Is the cybersecurity course self-paced or instructor-led?",
        answer:
          "HackTricks Training courses are self-paced, allowing you to study and progress through the material at your own convenience and speed. This flexibility allows you to balance your learning with other commitments.",
      },
      {
        index: 2,
        question:
          "How long does it typically take to complete your cybersecurity training?",
        answer:
          "The duration of our cybersecurity course varies depending on the specific course and the time commitment you can dedicate. However, since the course is self-paced, you have the freedom to adjust the pace according to your schedule.\n\nFor ARTE (AWS Red Team Expert) we have estimated a workload of +70 hours.",
      },
      {
        index: 3,
        question:
          "What learning materials and resources are provided with the course?",
        answer:
          "When you enroll in HackTricks Training courses, you will gain access to our online learning platform, where you can track your progress and engage with the course content, that includes video recording of the courses, slides and laboratories.",
      },
      {
        index: 4,
        question:
          "Do you offer any certifications upon completion of the cybersecurity course?",
        answer:
          "Yes, upon successful completion of our cybersecurity training, meaning you have successfully passed the exam,  you will receive a certification of completion, awarded by HackTricks Training . This certificate validates your achievement and can be included in your professional portfolio to showcase your cybersecurity knowledge and skills.",
      },
      {
        index: 5,
        question: "How much time do I have to redeem the voucher?",
        answer:
          "In the case of vouchers for the course + 1st exam attempt, once you purchase the voucher, you have one year to redeem it. However, we’re sure you will be excited to start our training as soon as possible!\n\nOn vouchers for extra opportunities to take the exam, or extension of the access to the labs, you have one year to redeem the voucher.",
      },
      {
        index: 6,
        question: "How much time do I have to complete the course?",
        answer:
          "You have one year since you redeem the voucher to schedule your exam.\n\nPlease, note that access to the course material (excluding labs) will be available indefinitely.",
      },
      {
        index: 7,
        question:
          "Can I access the course materials even after completing the course?",
        answer:
          "Yes, you will have continued access to the course materials even after completing the cybersecurity course. This allows you to revisit the content, refresh your knowledge, and reference the materials whenever you need them.",
      },
      {
        index: 8,
        question:
          "What should I expect from the exam? How many opportunities do I have to pass it?",
        answer:
          "The exam consists of solving laboratories and finding flags, having half a day (12 hours) to finish.\n\nWith the purchase of the course, you have 1 opportunity to take the exam. If you need further opportunities, there are exam vouchers available for purchase.\n\nIn order to pass the exam and achieve the certification, you will need to solve all the challenges proposed.\n\nExam vouchers can only be purchased after having acquired the course.",
      },
      {
        index: 9,
        question:
          "What is the cost of your cybersecurity course? Are there any additional fees?",
        answer:
          "The cost of our cybersecurity training may vary depending on the specific course you choose, or any specific promotion or discount that we decide to apply. Please refer to our website for detailed pricing information.\n\nThere are no hidden fees from our side. Please, note that your bank might apply fees for currency exchange.",
      },
      {
        index: 10,
        question: "Is there a refund policy?",
        answer:
          "Yes, we offer a refund policy for our cybersecurity courses and labs. If, for any reason, you have changed your mind about purchasing a voucher to access one of HackTricks Trainings, you can request a refund. Refunds are only issued if the voucher has not been redeemed and the request is made within 30 days from the date of purchase. Please note that any fees associated with the purchase (approximately 2%) cannot be refunded. Refunds typically take 5 to 10 business days to process.\n\nOnce the voucher has been redeemed, we are unable to offer a refund.\n\nIf you have any specific query regarding refunds, please contact our support team at: support-training@hacktricks.xyz.",
      },
      {
        index: 11,
        question: "Do discounts combine to create a larger discount?",
        answer:
          "No, discounts cannot be combined. Each discount is applied individually, and only the highest applicable discount will be considered at checkout.",
      },
      {
        index: 12,
        question: "What is the enrollment process for HackTricks Training?",
        answer:
          "To enroll in our cybersecurity courses, simply visit our website and select the course you are interested in. You will be able to purchase a voucher for the selected course. The voucher and its code will be sent to your registration email.\n\nOnce you redeem the voucher in our platform, you will then gain immediate access to the course materials and can start learning right away.",
      },
      {
        index: 13,
        question:
          "How can I contact you if I have any questions related to the purchase, certification, billing, and other related topics?",
        answer:
          "If you have any questions or need assistance regarding the purchase, certification, billing, or any other related topics, you can reach out to our dedicated customer support team in discord or email us at support-training@hacktricks.xyz. We are here to help and will respond to your inquiries promptly.",
      },
      {
        index: 14,
        question:
          "Can I communicate with other course participants or instructors during the course to ask questions or seek clarification?",
        answer:
          "Yes, we encourage active engagement and learning community interaction. We have a Discord Server for all the community that enrolls in our training. You can ask questions, seek clarification, and engage in meaningful discussions related to the course content. Our instructors are also available to provide guidance and support throughout your learning journey.",
      },
      {
        index: 15,
        question: "Can I download and/or share the contents of the courses?",
        answer:
          "No, the downloading and sharing of course contents are not permitted. Our courses are protected by copyright, and the materials provided are for personal use only. They are intended to be accessed through our online learning platform for the duration of your enrollment. Sharing or distributing the course materials without explicit permission is strictly prohibited to maintain the integrity of our courses and protect the intellectual property rights of our instructors and content creators.\n\nIn case you have distributed the materials from our course, you will lose access to our learning platform and will be banned from HackTricks Training, eliminating and invalidating any certification previously issued by HackTricks Training.",
      },
    ],
  }

  return (
    <section className="min-h-screen flex flex-col justify-between">
      {auth ? <NavbarPrivate /> : <NavbarPublic />}
      <div className="mb-auto">
        <div className="container mx-auto px-5 md:px-16">
          <section id="faqs" className="mt-10">
            <FAQs faqs={faqs}></FAQs>
          </section>
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default FAQsPage
