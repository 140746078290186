// import Footer from "../../components/Footer"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"
import { UserExamSchema } from "../../api"
import useAuthedApi from "../../hooks/useAuthedApi"
import { useState, useEffect, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { isAxiosError } from "axios"
import Loading from "../../components/Loading"
import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Modal,
  Spinner,
  TextInput,
  Tooltip,
} from "flowbite-react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import { LuPartyPopper } from "react-icons/lu"
import { IoMdSchool } from "react-icons/io"
import ExamroomFlag from "../../components/ExamroomFlag"
import Timer from "../../components/Timer"
import useToasts from "../../hooks/useToasts"
import { BiReset } from "react-icons/bi"
import { HiOutlineExclamationCircle } from "react-icons/hi"

const Examroom = () => {
  const { toastList, setToastList } = useToasts()
  const [userExam, setUserExam] = useState<UserExamSchema>()
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const [isCompletedWithPR, setIsCompletedWithPR] = useState<boolean>(false)
  const [completedExamFlagIds] = useState<string[]>([])
  const [loading, setLoading] = useState(true)
  const [now, setNow] = useState(new Date())
  const { ExamsAuthedApi, CertificatesAuthedApi } = useAuthedApi()
  const navigate = useNavigate()
  const { slug } = useParams()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const [openExamResetModal, setOpenExamResetModal] = useState<boolean>(false)
  const [examResetErrMsg, setExamResetErrMsg] = useState("")
  const examResetErrRef = useRef<HTMLInputElement>(null)
  const [openExamCompleteModal, setOpenExamCompleteModal] =
    useState<boolean>(false)
  const [generateCertificateErrMsg, setGenerateCertificateErrMsg] = useState("")
  const generateCertificateErrRef = useRef<HTMLInputElement>(null)
  const [certFullName, setCertFullName] = useState<string>("")
  const [generateCertLoading, setGenerateCertLoading] = useState(false)

  const initExam = async (slug: string) => {
    let _userExam

    setErrMsg("")

    // Get exam data
    try {
      const response = await ExamsAuthedApi.examsReadExamMe(slug)
      if (!response.data) {
        setErrMsg("Get exam failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))
      _userExam = response.data
      setUserExam(_userExam)
      setCertFullName(
        `${_userExam.user.first_name} ${_userExam.user.last_name}`
      )
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 404) {
          setErrMsg("We could not find that exam")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get exam failed with unknown error")
        }
      } else {
        console.log(err)
        setErrMsg("Get exam failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  const checkCompleted = () => {
    if (!userExam || !userExam.exam || userExam.exam.examflags === undefined) {
      setErrMsg("No exam data")
      return
    }
    const examComplete = userExam.exam.examflags
      .map((flag) => {
        return completedExamFlagIds.includes(flag.id)
      })
      .every((s: any) => {
        return s
      })

    setIsCompleted(examComplete)
    setOpenExamCompleteModal(examComplete)
    if (examComplete) {
      return
    }

    const completedFlags = userExam.exam.examflags.map((flag) => {
      return completedExamFlagIds.includes(flag.id)
    }).length
    const examCompleteWithPR =
      userExam.extra_points_pr !== null &&
      completedFlags >= userExam.exam.examflags.length - 1

    setIsCompletedWithPR(examCompleteWithPR)
    if (userExam.exam_end !== undefined && new Date(userExam.exam_end) < now) {
      setOpenExamCompleteModal(examCompleteWithPR)
    }
  }

  const calculateTimeLeftExam = (userExam: UserExamSchema) => {
    let delta: number

    if (!userExam.exam_start || !userExam.exam_end) {
      return 0
    }

    let start = new Date(userExam.exam_start)
    let end = new Date(userExam.exam_end)

    if (start > now) {
      // exam not started
      delta = Math.floor((start.getTime() - now.getTime()) / 1000)
      return delta
    } else if (start < now && now < end) {
      // exam started
      delta = Math.floor((end.getTime() - now.getTime()) / 1000)
      return delta
    } else {
      // exam ended
      return 0
    }
  }

  const resetExam = async () => {
    if (!userExam) {
      setExamResetErrMsg("Exam to reset is undefined")
      examResetErrRef.current?.focus()
      return
    }
    try {
      const response = await ExamsAuthedApi.examsResetExam(userExam.exam.slug)
      if (!response.data) {
        setErrMsg("Exam reset failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setUserExam(response.data.data)
      setOpenExamResetModal(false)
      setToastList(
        toastList.concat({
          type: "success",
          children: `Exam reset started successfully!`,
        })
      )
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setExamResetErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setExamResetErrMsg("Data validation error")
        } else if (err.response?.status === 400) {
          setExamResetErrMsg(err.response?.data.detail)
        } else {
          setExamResetErrMsg("Exam reset failed with unknown error")
        }
      } else {
        setExamResetErrMsg("Exam reset failed with unknown error")
      }
      examResetErrRef.current?.focus()
    }
  }

  function timeConversion(duration: number) {
    const portions: string[] = []

    const msInHour = 1000 * 60 * 60
    const hours = Math.trunc(duration / msInHour)
    if (hours > 0) {
      portions.push(hours + "h")
      duration = duration - hours * msInHour
    }

    const msInMinute = 1000 * 60
    const minutes = Math.trunc(duration / msInMinute)
    if (minutes > 0) {
      portions.push(minutes + "m")
      duration = duration - minutes * msInMinute
    }

    const seconds = Math.trunc(duration / 1000)
    if (seconds > 0) {
      portions.push(seconds + "s")
    }

    return portions.join(" ")
  }

  const generateCertificate = async () => {
    setGenerateCertificateErrMsg("")
    setGenerateCertLoading(true)
    if (!userExam) {
      setGenerateCertificateErrMsg("Exam is undefined")
      generateCertificateErrRef.current?.focus()
      setGenerateCertLoading(false)
      return
    }
    try {
      const response =
        await CertificatesAuthedApi.certificatesGenerateCertificate(
          userExam.exam.course.id,
          { full_name: certFullName }
        )
      if (!response.data) {
        setGenerateCertificateErrMsg(
          "Certificate generation failed with unknown error"
        )
        setGenerateCertLoading(false)
        return
      }
      // console.log(JSON.stringify(response?.data))

      setOpenExamCompleteModal(false)
      setToastList(
        toastList.concat({
          type: "success",
          children: `Certificate generated and emailed successfully!`,
        })
      )
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setGenerateCertificateErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setGenerateCertificateErrMsg("Data validation error")
        } else if (err.response?.status === 400) {
          setGenerateCertificateErrMsg(err.response?.data.detail)
        } else {
          setGenerateCertificateErrMsg(
            "Certificate generation failed with unknown error"
          )
        }
      } else {
        setGenerateCertificateErrMsg(
          "Certificate generation failed with unknown error"
        )
      }
      generateCertificateErrRef.current?.focus()
    }
    setGenerateCertLoading(false)
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(new Date())
    }, 1000)

    if (!slug) {
      navigate("/dashboard")
      return
    }

    initExam(slug).finally(() => {
      setLoading(false)
    })

    return () => clearInterval(intervalId)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Modal
        show={openExamCompleteModal}
        dismissible
        size="md"
        popup
        onClose={() => setOpenExamCompleteModal(false)}
        theme={{
          content: {
            inner:
              "relative rounded-lg bg-white shadow dark:bg-neutral-700 flex flex-col max-h-[90vh]",
          },
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="">
            <LuPartyPopper className="mx-auto mb-4 h-14 w-14 text-green-600 dark:text-green-400 text-center" />
            <h3 className="mb-5 text-lg font-normal text-neutral-500 dark:text-neutral-400 text-center">
              Congratulations! You have completed the exam
            </h3>

            {userExam?.data && (userExam.data as any).certificate ? (
              <>
                <div className="flex justify-center gap-4 text-center">
                  <Button
                    color="success"
                    onClick={() =>
                      navigate(
                        `/certificates/${(userExam.data as any).certificate}`
                      )
                    }
                  >
                    View certificate
                  </Button>
                  <Button
                    color="gray"
                    onClick={() => setOpenExamCompleteModal(false)}
                  >
                    Close
                  </Button>
                </div>
              </>
            ) : (
              <>
                <p className="mb-2 text-neutral-500 dark:text-neutral-400 text-center">
                  It is time to generate your certificate. Please specify the
                  full name you want your certificate to show (this can not be
                  changed later).
                </p>
                <div className={!generateCertificateErrMsg ? "sr-only" : ""}>
                  <Alert
                    color="failure"
                    icon={ExclamationTriangleIcon}
                    onDismiss={function onDismiss() {
                      setGenerateCertificateErrMsg("")
                    }}
                  >
                    <span ref={generateCertificateErrRef}>
                      {generateCertificateErrMsg}
                    </span>
                  </Alert>
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor="full_name"
                      value="Full name"
                      className="text-left"
                    />
                  </div>
                  <TextInput
                    id="full_name"
                    value={certFullName}
                    onChange={(e) => {
                      setCertFullName(e.target.value)
                    }}
                    required
                    type="text"
                    className="mb-3"
                    theme={{
                      field: {
                        input: {
                          colors: {
                            gray: "bg-neutral-50 border-neutral-300 text-neutral-900 focus:border-red-logo focus:ring-red-logo dark:border-neutral-600 dark:bg-neutral-800 dark:text-white dark:placeholder-neutral-400 dark:focus:border-red-logo dark:focus:ring-red-logo",
                          },
                        },
                      },
                    }}
                  />
                </div>
                <div className="flex justify-center gap-4 text-center">
                  <Button
                    isProcessing={generateCertLoading}
                    processingSpinner={<Spinner color="failure" />}
                    color="success"
                    onClick={() => generateCertificate()}
                  >
                    Generate certificate
                  </Button>
                  <Button
                    color="gray"
                    onClick={() => setOpenExamCompleteModal(false)}
                  >
                    Close
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <div className="fixed top-0 inset-x-0 z-40 flex justify-between items-center">
        <NavbarPrivate />
      </div>

      {/* Content */}
      <main className="pt-20">
        <div className="container mx-auto">
          {loading ? (
            <Loading />
          ) : (
            <>
              {userExam?.exam === undefined ? (
                <div className="p-5">
                  <Alert
                    className="mx-auto w-1/3"
                    color="failure"
                    icon={ExclamationTriangleIcon}
                    onDismiss={function onDismiss() {
                      setErrMsg("")
                    }}
                  >
                    <span>Could not load exam</span>
                  </Alert>
                </div>
              ) : (
                <>
                  {userExam.exam_start === null ||
                  userExam.exam_start === undefined ||
                  userExam.exam_end === undefined ||
                  userExam.exam_end === null ||
                  !(new Date(userExam.exam_start) <= new Date()) ? (
                    <div className="p-5">
                      <Alert
                        className="mx-auto w-1/3"
                        color="failure"
                        icon={ExclamationTriangleIcon}
                        onDismiss={function onDismiss() {
                          setErrMsg("")
                        }}
                      >
                        <span>This exam has not started</span>
                      </Alert>
                    </div>
                  ) : (
                    <div className="p-5">
                      <section className="mb-5" id="examroom">
                        <Modal
                          show={openExamResetModal}
                          dismissible
                          size="md"
                          popup
                          onClose={() => setOpenExamResetModal(false)}
                          theme={{
                            content: {
                              inner:
                                "relative rounded-lg bg-white shadow dark:bg-neutral-700 flex flex-col max-h-[90vh]",
                            },
                          }}
                        >
                          <Modal.Header />
                          <Modal.Body>
                            <div className="text-center">
                              <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                Are you sure you want to reset exam{" "}
                                {userExam.exam.title}?
                              </h3>
                              <div
                                className={!examResetErrMsg ? "sr-only" : ""}
                              >
                                <Alert
                                  color="failure"
                                  icon={ExclamationTriangleIcon}
                                  onDismiss={function onDismiss() {
                                    setExamResetErrMsg("")
                                  }}
                                >
                                  <span ref={examResetErrRef}>
                                    {examResetErrMsg}
                                  </span>
                                </Alert>
                              </div>
                              <Alert
                                className="mb-4"
                                color="warning"
                                icon={ExclamationTriangleIcon}
                              >
                                <p className="text-start">
                                  An exam reset will provision a new{" "}
                                  {userExam.exam.platform === "AWS"
                                    ? "AWS account"
                                    : userExam.exam.platform === "GCP"
                                    ? "GCP project"
                                    : ""}
                                  . Only 3 resets are allowed during the exam
                                  and they must be at least 30 minutes apart.
                                </p>
                                <p className="text-start font-semibold">
                                  ({userExam.last_resets.length}
                                  /3) resets.
                                </p>
                                <p className="text-start font-semibold">
                                  (
                                  {
                                    userExam.last_resets.filter(
                                      (d) =>
                                        new Date(d) >
                                        new Date(
                                          now.getTime() - 1 * 1000 * 60 * 30
                                        )
                                    ).length
                                  }
                                  /1) resets in the last 30 minutes.
                                </p>
                              </Alert>
                              <div className="flex justify-center gap-4">
                                <Button
                                  color="failure"
                                  onClick={() => resetExam()}
                                >
                                  Yes, I'm sure
                                </Button>
                                <Button
                                  color="gray"
                                  onClick={() => setOpenExamResetModal(false)}
                                >
                                  No, cancel
                                </Button>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                        <div className="md:flex mt-5 mx-3 md:mx-20">
                          <h2 className="text-2xl md:text-3xl md:my-auto dark:text-white align-middle">
                            Exam: {userExam.exam.title}
                          </h2>
                          <div className="flex justify-center md:justify-start">
                            <Badge
                              className="flex justify-center md:justify-start mt-2 md:mt-0 md:ml-3 md:px-3 md:my-auto"
                              color={
                                (userExam.exam_start === null ||
                                  userExam.exam_start === undefined) &&
                                (userExam.exam_end === null ||
                                  userExam.exam_end === undefined)
                                  ? "warning"
                                  : new Date(userExam.exam_start as string) >=
                                    new Date()
                                  ? "success"
                                  : new Date(userExam.exam_end as string) >=
                                    new Date()
                                  ? "purple"
                                  : "failure"
                              }
                            >
                              {(userExam.exam_start === null ||
                                userExam.exam_start === undefined) &&
                              (userExam.exam_end === null ||
                                userExam.exam_end === undefined) ? (
                                "Exam not scheduled"
                              ) : (
                                <>
                                  {new Date(userExam.exam_start as string) >=
                                  new Date() ? (
                                    "Exam scheduled"
                                  ) : (
                                    <>
                                      {new Date(userExam.exam_end as string) >=
                                      new Date()
                                        ? "Exam started"
                                        : "Exam ended"}
                                    </>
                                  )}
                                </>
                              )}
                            </Badge>
                          </div>
                          {(((userExam.data as any).state?.[
                            userExam.exam.platform.toLowerCase()
                          ]?.state as string) === "PROVISIONED" ||
                            ((userExam.data as any).state?.[
                              userExam.exam.platform.toLowerCase()
                            ]?.state as string) === "DIRTY") &&
                          new Date(userExam.exam_end as string) >=
                            new Date() ? (
                            <div className="mt-3 md:ml-3 md:flex md:my-auto flex justify-center">
                              {userExam.last_resets.length >= 3 ? (
                                <Tooltip
                                  content={`You have reached the max resets for an exam (3)`}
                                  placement="top"
                                  className="font-sans"
                                >
                                  <Button
                                    type="button"
                                    onClick={() => {
                                      setOpenExamResetModal(true)
                                    }}
                                    disabled={true}
                                    className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                                  >
                                    <BiReset fontSize="24" /> Reset
                                  </Button>
                                </Tooltip>
                              ) : (
                                <>
                                  {userExam.last_resets.filter(
                                    (d) =>
                                      new Date(d) >
                                      new Date(
                                        now.getTime() - 1 * 1000 * 60 * 30
                                      )
                                  ).length >= 1 ? (
                                    <Tooltip
                                      content={`You have reached the max resets in 30 minutes (1) - Time left ${timeConversion(
                                        1000 * 60 * 30 -
                                          (now.getTime() -
                                            new Date(
                                              userExam.last_resets.sort(
                                                (a, b) =>
                                                  new Date(b).getTime() -
                                                  new Date(a).getTime()
                                              )[0]
                                            ).getTime())
                                      )}`}
                                      placement="top"
                                      className="font-sans"
                                    >
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          setOpenExamResetModal(true)
                                        }}
                                        disabled={true}
                                        className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                                      >
                                        <BiReset fontSize="24" /> Reset
                                      </Button>
                                    </Tooltip>
                                  ) : (
                                    <Button
                                      type="button"
                                      onClick={() => {
                                        setOpenExamResetModal(true)
                                      }}
                                      className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                                    >
                                      <BiReset fontSize="24" /> Reset
                                    </Button>
                                  )}
                                </>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="mt-3 md:mt-0 md:flex grow justify-items-center md:justify-end">
                            <Timer delta={calculateTimeLeftExam(userExam)} />
                          </div>
                        </div>
                        <p className="mt-5 mx-3 md:mx-20 dark:text-white">
                          {userExam.exam.description}
                        </p>

                        <Alert
                          className="mt-5 mx-3 md:mx-20"
                          color="warning"
                          icon={ExclamationTriangleIcon}
                        >
                          <span>
                            Everything in the{" "}
                            {userExam.exam.platform === "AWS"
                              ? "AWS account"
                              : userExam.exam.platform === "GCP"
                              ? "GCP project"
                              : ""}{" "}
                            provided is in scope for the exam with the exception
                            of the{" "}
                            <code className="font-mono text-red-logo">
                              LabManagementOutOfScope
                            </code>{" "}
                            <strong>
                              {userExam.exam.platform === "AWS"
                                ? "role"
                                : userExam.exam.platform === "GCP"
                                ? "service account"
                                : ""}
                            </strong>{" "}
                            (resources created by this{" "}
                            {userExam.exam.platform === "AWS"
                              ? "role"
                              : userExam.exam.platform === "GCP"
                              ? "service account"
                              : ""}{" "}
                            are in scope)
                          </span>
                        </Alert>

                        <div className={!errMsg ? "sr-only" : ""}>
                          <Alert
                            className="mt-3"
                            color="failure"
                            icon={ExclamationTriangleIcon}
                            onDismiss={function onDismiss() {
                              setErrMsg("")
                            }}
                          >
                            <span ref={errRef}>{errMsg}</span>
                          </Alert>
                        </div>
                        {userExam.exam.examflags === undefined ||
                        userExam.exam.examflags === null ||
                        userExam.exam.examflags.length === 0 ? (
                          <></>
                        ) : (
                          <Card
                            className="mx-3 md:mx-20 mt-6 md:mt-10"
                            theme={{
                              root: {
                                base: "flex rounded-lg border border-neutral-200 bg-white shadow-md dark:border-neutral-900 dark:bg-neutral-900 flex-col mx-3 md:mx-20 mt-6 md:mt-10",
                              },
                            }}
                          >
                            <div className="md:flex">
                              <h5 className="flex text-2xl font-bold tracking-tight text-neutral-800 dark:text-white">
                                <IoMdSchool className="mr-3" />
                                Flags
                              </h5>
                              <Tooltip
                                content={
                                  {
                                    LOCKED:
                                      "Exam provisioning can take up to 15 minutes",
                                    PROVISIONING:
                                      "Exam provisioning can take up to 15 minutes",
                                    PROVISIONED: "",
                                    DIRTY: "",
                                    NUKING: "",
                                    NUKED: "",
                                  }[
                                    (userExam.data as any).state?.[
                                      userExam.exam.platform.toLowerCase()
                                    ]?.state as string
                                  ] ??
                                  "Exam provisioning will start 6 hours before the exam"
                                }
                                placement="top"
                                theme={{
                                  target: "my-auto",
                                }}
                                className={
                                  {
                                    LOCKED: "font-sans",
                                    PROVISIONING: "font-sans",
                                    PROVISIONED:
                                      "font-sans invisible opacity-0",
                                    DIRTY: "font-sans invisible opacity-0",
                                    NUKING: "font-sans invisible opacity-0",
                                    NUKED: "font-sans invisible opacity-0",
                                  }[
                                    (userExam.data as any).state?.[
                                      userExam.exam.platform.toLowerCase()
                                    ]?.state as string
                                  ] ?? "font-sans"
                                }
                              >
                                <Badge
                                  className="flex justify-center md:justify-start mt-2 md:mt-0 md:ml-3 md:px-3 md:my-auto"
                                  color={
                                    {
                                      LOCKED: "warning",
                                      PROVISIONING: "warning",
                                      PROVISIONED: "success",
                                      DIRTY: "failure",
                                      NUKING: "warning",
                                      NUKED: "failure",
                                    }[
                                      (userExam.data as any).state?.[
                                        userExam.exam.platform.toLowerCase()
                                      ]?.state as string
                                    ] ?? "info"
                                  }
                                >
                                  {{
                                    LOCKED:
                                      "Initiating exam environment provisioning",
                                    PROVISIONING:
                                      "Provisioning exam environment",
                                    PROVISIONED:
                                      "Exam environment provisioned (" +
                                      ((userExam.data as any).state?.[
                                        userExam.exam.platform.toLowerCase()
                                      ]?.[
                                        userExam.exam.platform === "AWS"
                                          ? "account_id"
                                          : userExam.exam.platform === "GCP"
                                          ? "project_id"
                                          : ""
                                      ] as string) +
                                      ")",
                                    DIRTY:
                                      "Exam environment marked for cleanup",
                                    NUKING: "Destroying exam environment",
                                    NUKED: "Exam environment destroyed",
                                  }[
                                    (userExam.data as any).state?.[
                                      userExam.exam.platform.toLowerCase()
                                    ]?.state as string
                                  ] ?? "Exam environment not provisioned"}
                                </Badge>
                              </Tooltip>
                              {userExam.extra_points_pr !== null ? (
                                <Badge
                                  className="flex justify-center md:justify-start mt-2 md:mt-0 md:ml-3 md:px-3 md:my-auto"
                                  color="success"
                                >
                                  Extra Points PR submitted
                                </Badge>
                              ) : (
                                <Badge
                                  className="flex justify-center md:justify-start mt-2 md:mt-0 md:ml-3 md:px-3 md:my-auto"
                                  color="failure"
                                >
                                  Extra Points PR not submitted
                                </Badge>
                              )}
                            </div>
                            {userExam.exam.examflags.map((flag) => (
                              <ExamroomFlag
                                key={flag.id}
                                flag={flag}
                                tf_outputs={
                                  (userExam?.data as any).state?.[
                                    userExam.exam.platform.toLowerCase()
                                  ].tf_outputs
                                }
                                disabled={
                                  new Date(userExam.exam_end as string) <=
                                  new Date()
                                }
                                onChange={(completed: boolean) => {
                                  if (completed) {
                                    if (
                                      !completedExamFlagIds.includes(flag.id)
                                    ) {
                                      completedExamFlagIds.push(flag.id)
                                    }
                                    checkCompleted()
                                  }
                                }}
                              />
                            ))}
                          </Card>
                        )}

                        {isCompleted ? (
                          <div className="mx-3 md:mx-20 mt-6 md:mt-10">
                            <Alert color="success">
                              <LuPartyPopper className="inline mr-2" />
                              Congratulations you have completed all the flags.{" "}
                              {userExam.data &&
                              (userExam.data as any).certificate ? (
                                <span
                                  className="hover:underline hover:cursor-pointer font-medium"
                                  onClick={() => {
                                    navigate(
                                      `/certificates/${
                                        (userExam.data as any).certificate
                                      }`
                                    )
                                  }}
                                >
                                  View certificate
                                </span>
                              ) : (
                                <span
                                  className="hover:underline hover:cursor-pointer font-medium"
                                  onClick={() => {
                                    setOpenExamCompleteModal(true)
                                  }}
                                >
                                  Generate certificate
                                </span>
                              )}
                            </Alert>
                          </div>
                        ) : (
                          <></>
                        )}
                        {isCompletedWithPR ? (
                          <div className="mx-3 md:mx-20 mt-6 md:mt-10">
                            <Alert color="warning">
                              <LuPartyPopper className="inline mr-2" />
                              Congratulations you have passed the exam by
                              submitting an extra points PR. We encourage you to
                              find the final flag!{" "}
                              {userExam.data &&
                              (userExam.data as any).certificate ? (
                                <span
                                  className="hover:underline hover:cursor-pointer font-medium"
                                  onClick={() => {
                                    navigate(
                                      `/certificates/${
                                        (userExam.data as any).certificate
                                      }`
                                    )
                                  }}
                                >
                                  View certificate
                                </span>
                              ) : (
                                <span
                                  className="hover:underline hover:cursor-pointer font-medium"
                                  onClick={() => {
                                    setOpenExamCompleteModal(true)
                                  }}
                                >
                                  Generate certificate
                                </span>
                              )}
                            </Alert>
                          </div>
                        ) : (
                          <></>
                        )}
                      </section>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </main>
    </>
  )
}

export default Examroom
