import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  Table as ReactTable,
} from "@tanstack/react-table"
import { Pagination } from "flowbite-react"
import { useState } from "react"
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc"

interface AdminTableProps<TData> {
  data: TData[]
  columns: ColumnDef<TData>[]
}

const AdminTable = <TData extends object>({
  columns,
  data,
}: AdminTableProps<TData>) => {
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  })

  const onPageChange = (p: number) => {
    const page = p - 1
    table.setPageIndex(page)
  }

  // Render the UI for your table
  return (
    <>
      <div className="relative overflow-x-auto shadow rounded-lg">
        <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
          <thead className="bg-gray-50 text-xs text-gray-700 dark:bg-gray-700 dark:text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      className="px-3 py-3 md:px-6"
                      key={header.id}
                      colSpan={header.colSpan}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none flex items-center"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <VscTriangleUp className="ml-2" />,
                              desc: <VscTriangleDown className="ml-2" />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y">
            {table
              .getRowModel()
              .rows.slice(0, table.getState().pagination.pageSize)
              .map((row) => {
                return (
                  <tr
                    className="hover:bg-gray-50 dark:hover:bg-gray-600 bg-white dark:border-gray-700 dark:bg-gray-800"
                    key={row.id}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td className="px-3 md:px-6 py-4" key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
      <div className="flex flex-wrap items-center gap-5 mt-3">
        <Pagination
          currentPage={table.getState().pagination.pageIndex + 1}
          onPageChange={onPageChange}
          showIcons={true}
          totalPages={table.getPageCount()}
          theme={{
            pages: {
              selector: {
                active:
                  "!bg-blue-50 !text-blue-600 hover:!bg-blue-100 hover:!text-blue-700 dark:!border-gray-700 dark:!bg-gray-700 dark:!text-white",
              },
            },
          }}
        />
        <div className="flex items-center gap-5 xs:mt-0 mt-2">
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value))
            }}
            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            {[10, 25, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  )
}

function Filter({
  column,
  table,
}: {
  column: Column<any, any>
  table: ReactTable<any>
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            e.target.value,
            old?.[1],
          ])
        }
        placeholder={`Min`}
        className="w-1/2 focus:ring-0 font-normal text-sm text-gray-500 dark:text-gray-400 mt-2 p-0 border-0 border-b-2 border-gray-300 dark:border-gray-600 focus:border-gray-500 dark:focus:border-gray-400 bg-transparent"
      />
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            old?.[0],
            e.target.value,
          ])
        }
        placeholder={`Max`}
        className="w-1/2 focus:ring-0 font-normal text-sm text-gray-500 dark:text-gray-400 mt-2 p-0 border-0 border-b-2 border-gray-300 dark:border-gray-600 focus:border-gray-500 dark:focus:border-gray-400 bg-transparent"
      />
    </div>
  ) : (
    <input
      type="text"
      value={(columnFilterValue ?? "") as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
      className="w-full focus:ring-0 font-normal text-sm text-gray-500 dark:text-gray-400 mt-2 p-0 border-0 border-b-2 border-gray-300 dark:border-gray-600 focus:border-gray-500 dark:focus:border-gray-400 bg-transparent"
    />
  )
}

export default AdminTable
