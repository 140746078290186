import { ExclamationCircleIcon } from "@heroicons/react/24/solid"
import { isAxiosError } from "axios"
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Label,
  TextInput,
  Tooltip,
} from "flowbite-react"
import { useEffect, useRef, useState } from "react"
import { HiInformationCircle } from "react-icons/hi"
import { EmailSubscriptionEnum } from "../../api"
import Footer from "../../components/Footer"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"
import useAuth from "../../hooks/useAuth"
import useAuthedApi from "../../hooks/useAuthedApi"
import useToasts from "../../hooks/useToasts"

const USERNAME_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,64}$/
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/

const Profile = () => {
  const { toastList, setToastList } = useToasts()
  const { UsersAuthedApi } = useAuthedApi()
  const { auth } = useAuth()

  const usernameRef = useRef<HTMLInputElement>(null)
  const errRef = useRef<HTMLInputElement>(null)

  const [username, setUsername] = useState("")
  const [validUsername, setValidUsername] = useState(false)
  const [usernameFocus, setUsernameFocus] = useState(false)

  const [email, setEmail] = useState("")
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [firstName, setFirstName] = useState("")
  const [validFirstName, setValidFirstName] = useState(false)

  const [lastName, setLastName] = useState("")
  const [validLastName, setValidLastName] = useState(false)

  const [company, setCompany] = useState<string | undefined>(undefined)
  const [discord, setDiscord] = useState<string | undefined>(undefined)

  const [emailSubscriptions, setEmailSubscriptions] = useState<
    EmailSubscriptionEnum[]
  >([EmailSubscriptionEnum.Required])

  const [errMsg, setErrMsg] = useState("")

  useEffect(() => {
    setUsername(auth?.user?.username || "")
    setEmail(auth?.user?.email || "")
    setFirstName(auth?.user?.first_name || "")
    setLastName(auth?.user?.last_name || "")
    setCompany(auth?.user?.company || undefined)
    setDiscord(auth?.user?.discord || undefined)
    setEmailSubscriptions(auth?.user?.email_subscriptions || [])
    usernameRef.current?.focus()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setValidUsername(USERNAME_REGEX.test(username))
  }, [username])

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email))
  }, [email])

  useEffect(() => {
    setValidFirstName(firstName !== "")
  }, [firstName])

  useEffect(() => {
    setValidLastName(lastName !== "")
  }, [lastName])

  useEffect(() => {
    setErrMsg("")
  }, [username, email, firstName, lastName])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    // if button enabled with JS hack
    const v1 = USERNAME_REGEX.test(username)
    const v2 = EMAIL_REGEX.test(email)
    const v3 = firstName !== ""
    const v4 = lastName !== ""
    if (!v1 || !v2 || !v3 || !v4) {
      setErrMsg("Invalid data")
      return
    }

    try {
      const response = await UsersAuthedApi.usersUpdateUserMe({
        first_name: firstName,
        last_name: lastName,
        company: company === "" ? undefined : company,
        discord: discord === "" ? undefined : discord,
        email_subscriptions: emailSubscriptions,
      })
      if (!response.data.success) {
        setErrMsg("Profile update failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setToastList(
        toastList.concat({
          type: "success",
          children: "Profile updated successfully!",
        })
      )

      setFirstName(response.data.user.first_name)
      setLastName(response.data.user.last_name)
      setCompany(response.data.user.company)
      setDiscord(response.data.user.discord)
    } catch (err) {
      if (isAxiosError(err)) {
        console.log(err)
        if (!err.response?.status) {
          setErrMsg("No server response")
        } else if (err.response?.status === 404) {
          setErrMsg("User not found")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else if (err.response?.status === 400) {
          setErrMsg(err.response?.data.detail)
        } else {
          setErrMsg("Profile update failed with unknown error")
        }
      } else {
        setErrMsg("Profile update failed with unknown error")
      }
      errRef.current?.focus()
    }
  }
  return (
    <section className="min-h-screen flex flex-col justify-between">
      <NavbarPrivate />
      <div className="mb-auto">
        <div className="container mx-auto">
          <section id="profile">
            <Card className="mx-3 md:mx-auto my-6 md:my-10 max-w-lg dark:!bg-neutral-900 dark:!border-neutral-800">
              <h5 className="text-2xl font-bold tracking-tight text-neutral-900 dark:text-white">
                Profile
              </h5>
              <div className={!errMsg ? "sr-only" : ""}>
                <Alert
                  color="failure"
                  icon={ExclamationCircleIcon}
                  onDismiss={function onDismiss() {
                    setErrMsg("")
                  }}
                >
                  <span ref={errRef}>{errMsg}</span>
                </Alert>
              </div>
              <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="username" value="Username" />
                  </div>
                  <TextInput
                    id="username"
                    type="text"
                    placeholder="Username"
                    autoComplete="username"
                    required={true}
                    disabled={true}
                    onChange={(e) => {
                      setUsername(e.target.value)
                    }}
                    onFocus={() => setUsernameFocus(true)}
                    onBlur={() => setUsernameFocus(false)}
                    value={username}
                    ref={usernameRef}
                    aria-invalid={validUsername ? "false" : "true"}
                    color={
                      validUsername ? "success" : !username ? "" : "failure"
                    }
                    helperText={
                      <>
                        <span
                          className={
                            usernameFocus && username && !validUsername
                              ? ""
                              : "sr-only"
                          }
                        >
                          <ExclamationCircleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                          Invalid username:
                          <br />
                          Must have 4 to 64 characters.
                          <br />
                          Must begin with a letter.
                          <br />
                          Can contain letters, numbers, underscores and hyphens.
                        </span>
                      </>
                    }
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="email" value="Email" />
                  </div>
                  <TextInput
                    id="email"
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                    required={true}
                    disabled={true}
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
                    value={email}
                    aria-invalid={validEmail ? "false" : "true"}
                    color={validEmail ? "success" : !email ? "" : "failure"}
                    helperText={
                      <>
                        <span
                          className={
                            emailFocus && email && !validEmail ? "" : "sr-only"
                          }
                        >
                          <ExclamationCircleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                          Invalid email:
                          <br />
                          Must be a valid email
                        </span>
                      </>
                    }
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="firstname" value="First name" />
                  </div>
                  <TextInput
                    id="firstname"
                    type="text"
                    placeholder="First name"
                    autoComplete="off"
                    required={true}
                    onChange={(e) => {
                      setFirstName(e.target.value)
                    }}
                    value={firstName}
                    aria-invalid={validFirstName ? "false" : "true"}
                    color={
                      validFirstName ? "success" : !firstName ? "" : "failure"
                    }
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="lastname" value="Last name" />
                  </div>
                  <TextInput
                    id="lastname"
                    type="text"
                    placeholder="Last name"
                    autoComplete="off"
                    required={true}
                    onChange={(e) => {
                      setLastName(e.target.value)
                    }}
                    value={lastName}
                    aria-invalid={validLastName ? "false" : "true"}
                    color={
                      validLastName ? "success" : !lastName ? "" : "failure"
                    }
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="company" value="Company (Optional)" />
                  </div>
                  <TextInput
                    id="company"
                    type="text"
                    placeholder="Company"
                    autoComplete="off"
                    required={false}
                    onChange={(e) => {
                      setCompany(e.target.value)
                    }}
                    value={company || ""}
                    color=""
                  />
                </div>
                <div>
                  <div className="mb-2 flex">
                    <Label
                      htmlFor="discord"
                      value="Discord handle (Optional)"
                    />{" "}
                    <Tooltip
                      content={
                        <p className="max-w-xs">
                          Join the{" "}
                          <a
                            href="https://discord.gg/hRep4RUj7f"
                            target="_blank"
                            rel="noreferrer"
                            className="text-red-logo hover:text-red-900"
                          >
                            HackTricks discord server
                          </a>{" "}
                          and then indicate your discord handler here to access
                          the course's channels
                        </p>
                      }
                      placement="right"
                    >
                      <HiInformationCircle className="text-lg ml-2 font-medium text-indigo-600 hover:text-indigo-500" />
                    </Tooltip>
                  </div>
                  <TextInput
                    id="discord"
                    type="text"
                    placeholder="Discord handle"
                    autoComplete="off"
                    required={false}
                    onChange={(e) => {
                      setDiscord(e.target.value)
                    }}
                    value={discord || ""}
                    color=""
                  />
                </div>
                <div className="flex items-center gap-2">
                  <Checkbox
                    id="comercial_emails"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setEmailSubscriptions(
                          emailSubscriptions.concat(
                            EmailSubscriptionEnum.Commercial
                          )
                        )
                      } else {
                        setEmailSubscriptions(
                          emailSubscriptions.filter(
                            (i) => i !== EmailSubscriptionEnum.Commercial
                          )
                        )
                      }
                    }}
                    checked={emailSubscriptions.includes(
                      EmailSubscriptionEnum.Commercial
                    )}
                  />
                  <Label htmlFor="comercial_emails">
                    <div className="flex ">
                      {
                        "I wish to receive emails with announcements and updates"
                      }
                      <Tooltip
                        content={
                          <p className="max-w-xs">
                            Receive new course release alerts and other
                            important platform updates
                          </p>
                        }
                        placement="right"
                      >
                        <HiInformationCircle className="text-lg ml-2 font-medium text-indigo-600 hover:text-indigo-500" />
                      </Tooltip>
                    </div>
                  </Label>
                </div>
                <div className="flex">
                  <Button
                    className="grow-0"
                    disabled={
                      !validUsername || !validFirstName || !validLastName
                        ? true
                        : false
                    }
                    type="submit"
                    color="failure"
                  >
                    Update details
                  </Button>
                </div>
              </form>
            </Card>
          </section>
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Profile
