import axios from "axios"
import {
  AuthApi,
  UsersApi,
  CoursesApi,
  VouchersApi,
  CertificatesApi,
  FlagsApi,
  LabsApi,
  ExamsApi,
  AdminApi,
} from "../api"

const useApi = () => {
  const BASE_URL = process.env.REACT_APP_API_URL
  const myAxios = axios.create({
    withCredentials: true,
  })

  const api = {
    AuthApi: new AuthApi(undefined, BASE_URL, myAxios),
    UsersApi: new UsersApi(undefined, BASE_URL, myAxios),
    CoursesApi: new CoursesApi(undefined, BASE_URL, myAxios),
    FlagsApi: new FlagsApi(undefined, BASE_URL, myAxios),
    LabsApi: new LabsApi(undefined, BASE_URL, myAxios),
    ExamsApi: new ExamsApi(undefined, BASE_URL, myAxios),
    VouchersApi: new VouchersApi(undefined, BASE_URL, myAxios),
    CertificatesApi: new CertificatesApi(undefined, BASE_URL, myAxios),
    AdminApi: new AdminApi(undefined, BASE_URL, myAxios),
  }
  return api
}

export default useApi
