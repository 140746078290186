import { Button, MegaMenu, Navbar } from "flowbite-react"
import { FC, PropsWithChildren, useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import useRefreshToken from "../../hooks/useRefreshToken"
import useSignOut from "../../hooks/useSignOut"
import useToasts from "../../hooks/useToasts"
import NavbarUserDropdown from "./NavbarUserDropdown"
import HT_TRAINING_logo_letters_horiz_big from "../../assets/images/HT_TRAINING_logo_letters_horiz_big.svg"

const NavbarCustom: FC<PropsWithChildren> = ({ children }) => {
  const { auth } = useAuth()
  const { toastList, setToastList } = useToasts()
  const navigate = useNavigate()
  const location = useLocation()
  const signOut = useSignOut()

  const refresh = useRefreshToken()

  useEffect(() => {
    if (!auth) {
      refresh()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const signout = async () => {
    const result = await signOut()
    if (result) {
      setToastList(
        toastList.concat({
          type: "success",
          link: { to: "/signin", text: "Sign in" },
          children: "Successfully signed out!",
        })
      )
    } else {
      setToastList(
        toastList.concat({
          type: "failure",
          children: "Error signing out!",
        })
      )
    }

    navigate("/")
  }

  return (
    <MegaMenu
      fluid={true}
      rounded={true}
      className="w-full z-40 bg-neutral-200 dark:bg-neutral-900 rounded-t-none sticky top-0"
    >
      <div className="container mx-auto flex flex-wrap items-center justify-between">
        <Navbar.Brand as={Link} to="/">
          <img
            src={HT_TRAINING_logo_letters_horiz_big}
            className="mr-3 h-16 sm:h-16"
            alt="HackTricks Training Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          className="md:hidden"
          theme={{
            base: "inline-flex items-center rounded-lg p-2 text-sm text-neutral-500 hover:bg-neutral-700 focus:outline-none focus:ring-1 focus:ring-neutral-600 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:focus:ring-neutral-600 md:hidden",
            icon: "h-6 w-6 shrink-0",
          }}
        />

        <Navbar.Collapse
          className="md:grow md:ml-10"
          theme={{
            base: "w-full md:block md:w-auto",
            list: "mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium",
            hidden: {
              on: "hidden",
              off: "",
            },
          }}
        >
          {children}
        </Navbar.Collapse>
        <Navbar.Collapse>
          {auth && auth.user ? (
            <>
              {location.pathname === "/" ? (
                <Button
                  as={Link}
                  to="/dashboard"
                  className="md:!ml-3 mb-4 md:mb-0 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                >
                  User Area
                </Button>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          <NavbarUserDropdown auth={auth} signout={signout} />
        </Navbar.Collapse>
      </div>
    </MegaMenu>
  )
}

export default NavbarCustom
