import useAuth from "./useAuth"
import useApi from "./useApi"

const useRefreshToken = () => {
  const { setAuth } = useAuth()
  const { AuthApi } = useApi()

  const refresh = async () => {
    try {
      const response = await AuthApi.authRefresh()
      setAuth(response?.data)
      return response.data.access_token
    } catch (err) {
      return null
    }
  }
  return refresh
}

export default useRefreshToken
