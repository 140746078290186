import { useRef, useState } from "react"
import { isAxiosError } from "axios"
import { Alert } from "flowbite-react"
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import useToasts from "../../hooks/useToasts"
import React from "react"
import useAuthedApi from "../../hooks/useAuthedApi"
import HT_TRAINING_logo from "../../assets/images/HT_TRAINING_logo.svg"

const InactiveAccount = () => {
  const { toastList, setToastList } = useToasts()
  const { AuthAuthedApi: AuthApi } = useAuthedApi()

  const errRef = useRef<HTMLInputElement>(null)

  const [errMsg, setErrMsg] = useState("")

  const handleClick = async () => {
    try {
      const response = await AuthApi.authResendActivation({})
      if (!response.data.success) {
        setErrMsg(
          "Resending account activation email failed with unknown error"
        )
        return
      }
      // console.log(JSON.stringify(response?.data))

      setToastList(
        toastList.concat({
          type: "success",
          link: { to: "/signin", text: "Sign in" },
          children: "Account activation email sent successfully!",
        })
      )
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg(
            "Resending account activation email failed with unknown error"
          )
        }
      } else {
        setErrMsg(
          "Resending account activation email failed with unknown error"
        )
      }
      errRef.current?.focus()
    }
  }

  return (
    <section>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-48 w-auto"
              src={HT_TRAINING_logo}
              alt="HackTricks Training"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
              Activate your account!
            </h2>
          </div>
          <div className={!errMsg ? "sr-only" : ""}>
            <Alert
              color="failure"
              icon={ExclamationCircleIcon}
              onDismiss={function onDismiss() {
                setErrMsg("")
              }}
            >
              <span ref={errRef}>{errMsg}</span>
            </Alert>
          </div>
          <Alert
            color="warning"
            additionalContent={
              <React.Fragment>
                <div className="mt-2 mb-4 text-sm text-yellow-700 dark:text-yellow-800">
                  Please check your email, if you can't find it you can try to
                  resend it.
                </div>
                <div className="flex">
                  <button
                    onClick={handleClick}
                    type="button"
                    className="mr-2 inline-flex items-center rounded-lg bg-yellow-700 px-3 py-1.5 text-center text-xs font-medium text-white hover:bg-yellow-800 focus:ring-4 focus:ring-yellow-300 dark:bg-yellow-800 dark:hover:bg-yellow-900"
                  >
                    Resend email
                  </button>
                </div>
              </React.Fragment>
            }
            icon={ExclamationTriangleIcon}
          >
            <h3 className="text-lg font-medium text-yellow-700 dark:text-yellow-800">
              Your account has not been activated yet.
            </h3>
          </Alert>
        </div>
      </div>
    </section>
  )
}

export default InactiveAccount
