import { Alert, Button, Modal, Tooltip } from "flowbite-react"
import { useEffect, useMemo, useRef, useState } from "react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import AdminSidebar from "../../components/sidebar/AdminSidebar"
import { RiEdit2Fill } from "react-icons/ri"
import { ColumnDef } from "@tanstack/react-table"
import { AdminGCPProjectSchema } from "../../api"
import useAuthedApi from "../../hooks/useAuthedApi"
import useToasts from "../../hooks/useToasts"
import { isAxiosError } from "axios"
import AdminTable from "../../components/tables/AdminTable"
import { MdDelete } from "react-icons/md"
import { HiOutlineExclamationCircle } from "react-icons/hi"
import AdminEditGCPProject from "../../components/modals/AdminEditGCPProject"
import { FaBomb } from "react-icons/fa"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"

const AdminGCPProjects = () => {
  const [gcpProjects, setGCPProjects] = useState<AdminGCPProjectSchema[]>()
  const { AdminAuthedApi } = useAuthedApi()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const [showNukeModal, setShowNukeModal] = useState(false)
  const [gcpProjectToNuke, setGCPProjectToNuke] =
    useState<AdminGCPProjectSchema>()
  const [nukeErrMsg, setNukeErrMsg] = useState("")
  const nukeErrRef = useRef<HTMLInputElement>(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [gcpProjectToDelete, setGCPProjectToDelete] =
    useState<AdminGCPProjectSchema>()
  const [deleteErrMsg, setDeleteErrMsg] = useState("")
  const deleteErrRef = useRef<HTMLInputElement>(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [gcpProjectToEdit, setGCPProjectToEdit] =
    useState<AdminGCPProjectSchema>()
  const [editErrMsg, setEditErrMsg] = useState("")
  const editErrRef = useRef<HTMLInputElement>(null)
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(
    window.innerWidth < 640
  )

  const { toastList, setToastList } = useToasts()

  const getGCPProjects = async () => {
    try {
      const response = await AdminAuthedApi.adminReadGcpProjects()
      if (!response.data) {
        setErrMsg("Get GCP Projects failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setGCPProjects(response.data)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get GCP Projects failed with unknown error")
        }
      } else {
        setErrMsg("Get GCP Projects failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  const nukeGCPProject = async (
    gcpProject: AdminGCPProjectSchema | undefined
  ) => {
    if (!gcpProject) {
      setNukeErrMsg(`No GCP Project to nuke`)
      return
    }
    if (!gcpProjects) {
      setNukeErrMsg(`No GCP Projects`)
      return
    }
    console.log(gcpProject)
    try {
      const response = await AdminAuthedApi.adminNukeGcpProjects(gcpProject.id)
      if (!response.data) {
        setNukeErrMsg(
          `Nuking GCP Project ${gcpProject.id} failed with unknown error`
        )
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success || !response.data.project) {
        setNukeErrMsg(`No GCP Projects were nuked`)
        return
      }

      setToastList(
        toastList.concat({
          type: "success",
          children: `Nuking of GCP Project ${gcpProject.id} was successfully initialized!`,
        })
      )

      const index = gcpProjects.indexOf(gcpProject)
      let clone = gcpProjects
      clone.splice(index, 1)
      clone.push(response.data.project)
      setGCPProjects(clone)

      setShowNukeModal(false)
      setGCPProjectToNuke(undefined)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setNukeErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setNukeErrMsg("Data validation error")
        } else {
          setNukeErrMsg(
            `Nuking GCP Project ${gcpProject.id} failed with unknown error`
          )
        }
      } else {
        setNukeErrMsg(
          `Nuking GCP Project ${gcpProject.id} failed with unknown error`
        )
      }
      nukeErrRef.current?.focus()
    }
  }

  const deleteGCPProject = async (
    gcpProject: AdminGCPProjectSchema | undefined
  ) => {
    if (!gcpProject) {
      setDeleteErrMsg(`No GCP Project to delete`)
      return
    }
    if (!gcpProjects) {
      setDeleteErrMsg(`No GCP Projects`)
      return
    }
    console.log(gcpProject)
    try {
      const response = await AdminAuthedApi.adminDeleteGcpProject(gcpProject.id)
      if (!response.data) {
        setDeleteErrMsg(
          `Delete GCP Project ${gcpProject.id} failed with unknown error`
        )
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success) {
        setDeleteErrMsg(`No GCP Projects were deleted`)
        return
      }

      setToastList(
        toastList.concat({
          type: "success",
          children: `GCP Project ${gcpProject.id} was deleted successfully!`,
        })
      )

      const index = gcpProjects.indexOf(gcpProject)
      let clone = gcpProjects
      clone.splice(index, 1)
      setGCPProjects(clone)

      setShowDeleteModal(false)
      setGCPProjectToDelete(undefined)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setDeleteErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setDeleteErrMsg("Data validation error")
        } else {
          setDeleteErrMsg(
            `Delete GCP Project ${gcpProject.id} failed with unknown error`
          )
        }
      } else {
        setDeleteErrMsg(
          `Delete GCP Project ${gcpProject.id} failed with unknown error`
        )
      }
      deleteErrRef.current?.focus()
    }
  }

  const editGCPProject = async (
    gcpProject: AdminGCPProjectSchema | undefined
  ) => {
    if (!gcpProject) {
      setDeleteErrMsg(`No GCP Project to edit`)
      return
    }
    if (!gcpProjects) {
      setDeleteErrMsg(`No GCP Projects`)
      return
    }
    console.log(gcpProject)
    try {
      const response = await AdminAuthedApi.adminEditGcpProjects(gcpProject)
      if (!response.data) {
        setEditErrMsg(
          `Editing GCP project ${gcpProject.id} failed with unknown error`
        )
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success || !response.data.project) {
        setEditErrMsg(`No GCP projects were modified`)
        return
      }

      setToastList(
        toastList.concat({
          type: "success",
          children: `GCP Project ${gcpProject.id} was modified successfully!`,
        })
      )

      const index = gcpProjects.indexOf(gcpProject)
      let clone = gcpProjects
      clone.splice(index, 1)
      clone.push(gcpProject)
      setGCPProjects(clone)

      setShowEditModal(false)
      setGCPProjectToEdit(undefined)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setEditErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setEditErrMsg("Data validation error")
        } else {
          setEditErrMsg(
            `Edit GCP Project ${gcpProject.id} failed with unknown error`
          )
        }
      } else {
        setEditErrMsg(
          `Edit GCP Project ${gcpProject.id} failed with unknown error`
        )
      }
      editErrRef.current?.focus()
    }
  }

  useEffect(() => {
    getGCPProjects()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo<ColumnDef<AdminGCPProjectSchema>[]>(
    () => [
      {
        header: "PROJECT ID",
        footer: (props) => props.column.id,
        accessorKey: "id",
        cell: (props) => (
          <span className="font-medium text-gray-900 dark:text-white">
            {props.row.original.id}
          </span>
        ),
      },
      {
        header: "STATE",
        footer: (props) => props.column.id,
        accessorKey: "state",
        cell: (props) => props.getValue(),
      },
      {
        id: "actions",
        footer: (props) => props.column.id,
        cell: (props) => (
          <div className="flex flex-row gap-1">
            <Tooltip content="Edit GCP Project">
              <button
                onClick={() => {
                  setShowEditModal(true)
                  setGCPProjectToEdit(props.row.original)
                }}
                className="font-medium text-center text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
              >
                <RiEdit2Fill size={20} />
              </button>
            </Tooltip>
            <Tooltip content="Nuke GCP Project">
              <button
                onClick={() => {
                  setShowNukeModal(true)
                  setGCPProjectToNuke(props.row.original)
                }}
                className="font-medium text-center text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
              >
                <FaBomb size={20} />
              </button>
            </Tooltip>
            <Tooltip content="Delete GCP Project">
              <button
                onClick={() => {
                  setShowDeleteModal(true)
                  setGCPProjectToDelete(props.row.original)
                }}
                className="font-medium text-center text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
              >
                <MdDelete size={20} />
              </button>
            </Tooltip>
          </div>
        ),
      },
    ],
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [gcpProjects]
  )

  return (
    <>
      <div className="fixed top-0 inset-x-0 z-40 flex justify-between items-center">
        <NavbarPrivate />
      </div>

      <AdminSidebar
        onCollapseChange={(collapsed) => {
          setSidebarCollapsed(collapsed)
        }}
      />
      <main
        className={
          sidebarCollapsed
            ? "pt-20 pl-0 transition-all ease-in-out duration-300"
            : "pt-20 sm:pl-64 lg:pl-96 transition-all ease-in-out duration-300"
        }
      >
        <div className="container mx-auto">
          <div className="p-5">
            <section id="gcp_projects">
              <h1 className="text-left text-2xl md:text-4xl font-bold text-gray-800 dark:text-white mb-5 mx-3 md:mx-0">
                GCP Projects
              </h1>
              <div className={!errMsg ? "sr-only" : ""}>
                <Alert
                  color="failure"
                  icon={ExclamationTriangleIcon}
                  onDismiss={function onDismiss() {
                    setErrMsg("")
                  }}
                >
                  <span ref={errRef}>{errMsg}</span>
                </Alert>
              </div>
              <div className="mb-10">
                {gcpProjects?.length ? (
                  <AdminTable columns={columns} data={gcpProjects} />
                ) : (
                  <Alert color="warning" icon={ExclamationTriangleIcon}>
                    <span>No GCP Projects to display</span>
                  </Alert>
                )}
              </div>
            </section>
          </div>
        </div>
      </main>
      <Modal
        size="md"
        dismissible={true}
        popup={true}
        show={showNukeModal}
        onClose={() => {
          setShowNukeModal(false)
          setGCPProjectToNuke(undefined)
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to nuke the GCP Project{" "}
              {gcpProjectToNuke?.id}?
              <Alert color="warning" icon={ExclamationTriangleIcon}>
                This will mark it as DIRTY in the DB and start the operations to
                remove all the resources and then set it as AVAILABLE
              </Alert>
            </h3>
            <div className={!nukeErrMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setNukeErrMsg("")
                }}
              >
                <span ref={nukeErrRef}>{nukeErrMsg}</span>
              </Alert>
            </div>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={() => nukeGCPProject(gcpProjectToNuke)}
              >
                Yes, I'm sure
              </Button>
              <Button color="gray" onClick={() => setShowNukeModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        dismissible={true}
        popup={true}
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false)
          setGCPProjectToDelete(undefined)
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete the GCP Project{" "}
              {gcpProjectToDelete?.id}?
              <Alert color="warning" icon={ExclamationTriangleIcon}>
                This will not close or clean up the GCP Project just remove it
                from the DB
              </Alert>
            </h3>
            <div className={!deleteErrMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setDeleteErrMsg("")
                }}
              >
                <span ref={deleteErrRef}>{deleteErrMsg}</span>
              </Alert>
            </div>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={() => deleteGCPProject(gcpProjectToDelete)}
              >
                Yes, I'm sure
              </Button>
              <Button color="gray" onClick={() => setShowDeleteModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {gcpProjectToEdit ? (
        <AdminEditGCPProject
          gcpProjectToEdit={gcpProjectToEdit}
          setGCPProjectToEdit={setGCPProjectToEdit}
          editGCPProject={editGCPProject}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          editErrMsg={editErrMsg}
          setEditErrMsg={setEditErrMsg}
          editErrRef={editErrRef}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default AdminGCPProjects
