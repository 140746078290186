import { Alert, Button, Modal, Tooltip } from "flowbite-react"
import { useEffect, useMemo, useRef, useState } from "react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import AdminSidebar from "../../components/sidebar/AdminSidebar"
import { RiEdit2Fill } from "react-icons/ri"
import { ColumnDef } from "@tanstack/react-table"
import { AdminAWSAccountSchema } from "../../api"
import useAuthedApi from "../../hooks/useAuthedApi"
import useToasts from "../../hooks/useToasts"
import { isAxiosError } from "axios"
import AdminTable from "../../components/tables/AdminTable"
import { MdDelete } from "react-icons/md"
import { HiOutlineExclamationCircle } from "react-icons/hi"
import AdminEditAWSAccount from "../../components/modals/AdminEditAWSAccount"
import { FaBomb } from "react-icons/fa"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"

const AdminAWSAccounts = () => {
  const [awsAccounts, setAWSAccounts] = useState<AdminAWSAccountSchema[]>()
  const { AdminAuthedApi } = useAuthedApi()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const [showNukeModal, setShowNukeModal] = useState(false)
  const [awsAccountToNuke, setAWSAccountToNuke] =
    useState<AdminAWSAccountSchema>()
  const [nukeErrMsg, setNukeErrMsg] = useState("")
  const nukeErrRef = useRef<HTMLInputElement>(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [awsAccountToDelete, setAWSAccountToDelete] =
    useState<AdminAWSAccountSchema>()
  const [deleteErrMsg, setDeleteErrMsg] = useState("")
  const deleteErrRef = useRef<HTMLInputElement>(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [awsAccountToEdit, setAWSAccountToEdit] =
    useState<AdminAWSAccountSchema>()
  const [editErrMsg, setEditErrMsg] = useState("")
  const editErrRef = useRef<HTMLInputElement>(null)
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(
    window.innerWidth < 640
  )

  const { toastList, setToastList } = useToasts()

  const getAWSAccounts = async () => {
    try {
      const response = await AdminAuthedApi.adminReadAwsAccounts()
      if (!response.data) {
        setErrMsg("Get AWS accounts failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setAWSAccounts(response.data)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get AWS accounts failed with unknown error")
        }
      } else {
        setErrMsg("Get AWS accounts failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  const nukeAWSAccount = async (
    awsAccount: AdminAWSAccountSchema | undefined
  ) => {
    if (!awsAccount) {
      setNukeErrMsg(`No AWS Account to nuke`)
      return
    }
    if (!awsAccounts) {
      setNukeErrMsg(`No AWS Accounts`)
      return
    }
    console.log(awsAccount)
    try {
      const response = await AdminAuthedApi.adminNukeAwsAccounts(awsAccount.id)
      if (!response.data) {
        setNukeErrMsg(
          `Nuking AWS Account ${awsAccount.id} failed with unknown error`
        )
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success || !response.data.account) {
        setNukeErrMsg(`No AWS accounts were nuked`)
        return
      }

      setToastList(
        toastList.concat({
          type: "success",
          children: `Nuking of AWS account ${awsAccount.id} was successfully initialized!`,
        })
      )

      const index = awsAccounts.indexOf(awsAccount)
      let clone = awsAccounts
      clone.splice(index, 1)
      clone.push(response.data.account)
      setAWSAccounts(clone)

      setShowNukeModal(false)
      setAWSAccountToNuke(undefined)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setNukeErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setNukeErrMsg("Data validation error")
        } else {
          setNukeErrMsg(
            `Nuking AWS account ${awsAccount.id} failed with unknown error`
          )
        }
      } else {
        setNukeErrMsg(
          `Nuking AWS account ${awsAccount.id} failed with unknown error`
        )
      }
      nukeErrRef.current?.focus()
    }
  }

  const deleteAWSAccount = async (
    awsAccount: AdminAWSAccountSchema | undefined
  ) => {
    if (!awsAccount) {
      setDeleteErrMsg(`No AWS Account to delete`)
      return
    }
    if (!awsAccounts) {
      setDeleteErrMsg(`No AWS Accounts`)
      return
    }
    console.log(awsAccount)
    try {
      const response = await AdminAuthedApi.adminDeleteAwsAccount(awsAccount.id)
      if (!response.data) {
        setDeleteErrMsg(
          `Delete AWS Account ${awsAccount.id} failed with unknown error`
        )
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success) {
        setDeleteErrMsg(`No AWS accounts were deleted`)
        return
      }

      setToastList(
        toastList.concat({
          type: "success",
          children: `AWS account ${awsAccount.id} was deleted successfully!`,
        })
      )

      const index = awsAccounts.indexOf(awsAccount)
      let clone = awsAccounts
      clone.splice(index, 1)
      setAWSAccounts(clone)

      setShowDeleteModal(false)
      setAWSAccountToDelete(undefined)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setDeleteErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setDeleteErrMsg("Data validation error")
        } else {
          setDeleteErrMsg(
            `Delete AWS account ${awsAccount.id} failed with unknown error`
          )
        }
      } else {
        setDeleteErrMsg(
          `Delete AWS account ${awsAccount.id} failed with unknown error`
        )
      }
      deleteErrRef.current?.focus()
    }
  }

  const editAWSAccount = async (
    awsAccount: AdminAWSAccountSchema | undefined
  ) => {
    if (!awsAccount) {
      setDeleteErrMsg(`No AWS Account to edit`)
      return
    }
    if (!awsAccounts) {
      setDeleteErrMsg(`No AWS Accounts`)
      return
    }
    console.log(awsAccount)
    try {
      const response = await AdminAuthedApi.adminEditAwsAccounts(awsAccount)
      if (!response.data) {
        setEditErrMsg(
          `Editing AWS account ${awsAccount.id} failed with unknown error`
        )
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success || !response.data.account) {
        setEditErrMsg(`No AWS accounts were modified`)
        return
      }

      setToastList(
        toastList.concat({
          type: "success",
          children: `AWS Account ${awsAccount.id} was modified successfully!`,
        })
      )

      const index = awsAccounts.indexOf(awsAccount)
      let clone = awsAccounts
      clone.splice(index, 1)
      clone.push(awsAccount)
      setAWSAccounts(clone)

      setShowEditModal(false)
      setAWSAccountToEdit(undefined)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setEditErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setEditErrMsg("Data validation error")
        } else {
          setEditErrMsg(
            `Edit AWS Account ${awsAccount.id} failed with unknown error`
          )
        }
      } else {
        setEditErrMsg(
          `Edit AWS Account ${awsAccount.id} failed with unknown error`
        )
      }
      editErrRef.current?.focus()
    }
  }

  useEffect(() => {
    getAWSAccounts()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo<ColumnDef<AdminAWSAccountSchema>[]>(
    () => [
      {
        header: "ACCOUNT ID",
        footer: (props) => props.column.id,
        accessorKey: "id",
        cell: (props) => (
          <span className="font-medium text-gray-900 dark:text-white">
            {props.row.original.id}
          </span>
        ),
      },
      {
        header: "STATE",
        footer: (props) => props.column.id,
        accessorKey: "state",
        cell: (props) => props.getValue(),
      },
      {
        id: "actions",
        footer: (props) => props.column.id,
        cell: (props) => (
          <div className="flex flex-row gap-1">
            <Tooltip content="Edit AWS Account">
              <button
                onClick={() => {
                  setShowEditModal(true)
                  setAWSAccountToEdit(props.row.original)
                }}
                className="font-medium text-center text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
              >
                <RiEdit2Fill size={20} />
              </button>
            </Tooltip>
            <Tooltip content="Nuke AWS Account">
              <button
                onClick={() => {
                  setShowNukeModal(true)
                  setAWSAccountToNuke(props.row.original)
                }}
                className="font-medium text-center text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
              >
                <FaBomb size={20} />
              </button>
            </Tooltip>
            <Tooltip content="Delete AWS Account">
              <button
                onClick={() => {
                  setShowDeleteModal(true)
                  setAWSAccountToDelete(props.row.original)
                }}
                className="font-medium text-center text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
              >
                <MdDelete size={20} />
              </button>
            </Tooltip>
          </div>
        ),
      },
    ],
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [awsAccounts]
  )

  return (
    <>
      <div className="fixed top-0 inset-x-0 z-40 flex justify-between items-center">
        <NavbarPrivate />
      </div>

      <AdminSidebar
        onCollapseChange={(collapsed) => {
          setSidebarCollapsed(collapsed)
        }}
      />
      <main
        className={
          sidebarCollapsed
            ? "pt-20 pl-0 transition-all ease-in-out duration-300"
            : "pt-20 sm:pl-64 lg:pl-96 transition-all ease-in-out duration-300"
        }
      >
        <div className="container mx-auto">
          <div className="p-5">
            <section id="aws_accounts">
              <h1 className="text-left text-2xl md:text-4xl font-bold text-gray-800 dark:text-white mb-5 mx-3 md:mx-0">
                AWS accounts
              </h1>
              <div className={!errMsg ? "sr-only" : ""}>
                <Alert
                  color="failure"
                  icon={ExclamationTriangleIcon}
                  onDismiss={function onDismiss() {
                    setErrMsg("")
                  }}
                >
                  <span ref={errRef}>{errMsg}</span>
                </Alert>
              </div>
              <div className="mb-10">
                {awsAccounts?.length ? (
                  <AdminTable columns={columns} data={awsAccounts} />
                ) : (
                  <Alert color="warning" icon={ExclamationTriangleIcon}>
                    <span>No AWS accounts to display</span>
                  </Alert>
                )}
              </div>
            </section>
          </div>
        </div>
      </main>
      <Modal
        size="md"
        dismissible={true}
        popup={true}
        show={showNukeModal}
        onClose={() => {
          setShowNukeModal(false)
          setAWSAccountToNuke(undefined)
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to nuke the AWS account{" "}
              {awsAccountToNuke?.id}?
              <Alert color="warning" icon={ExclamationTriangleIcon}>
                This will mark it as DIRTY in the DB and start the operations to
                remove all the resources and then set it as AVAILABLE
              </Alert>
            </h3>
            <div className={!nukeErrMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setNukeErrMsg("")
                }}
              >
                <span ref={nukeErrRef}>{nukeErrMsg}</span>
              </Alert>
            </div>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={() => nukeAWSAccount(awsAccountToNuke)}
              >
                Yes, I'm sure
              </Button>
              <Button color="gray" onClick={() => setShowNukeModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        dismissible={true}
        popup={true}
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false)
          setAWSAccountToDelete(undefined)
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete the AWS account{" "}
              {awsAccountToDelete?.id}?
              <Alert color="warning" icon={ExclamationTriangleIcon}>
                This will not close or clean up the AWS account just remove it
                from the DB
              </Alert>
            </h3>
            <div className={!deleteErrMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setDeleteErrMsg("")
                }}
              >
                <span ref={deleteErrRef}>{deleteErrMsg}</span>
              </Alert>
            </div>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={() => deleteAWSAccount(awsAccountToDelete)}
              >
                Yes, I'm sure
              </Button>
              <Button color="gray" onClick={() => setShowDeleteModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {awsAccountToEdit ? (
        <AdminEditAWSAccount
          awsAccountToEdit={awsAccountToEdit}
          setAWSAccountToEdit={setAWSAccountToEdit}
          editAWSAccount={editAWSAccount}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          editErrMsg={editErrMsg}
          setEditErrMsg={setEditErrMsg}
          editErrRef={editErrRef}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default AdminAWSAccounts
