import useAuthedApi from "./useAuthedApi"
import useAuth from "./useAuth"

const useSignOut = () => {
  const { setAuth } = useAuth()
  const api = useAuthedApi()

  const signout = async () => {
    setAuth({})
    try {
      await api.AuthAuthedApi.authSignout()
      return true
    } catch (err) {
      return false
    }
  }

  return signout
}

export default useSignOut
