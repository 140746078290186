import {
  Accordion,
  Alert,
  Button,
  Checkbox,
  Label,
  Modal,
  Tabs,
  TextInput,
  Textarea,
} from "flowbite-react"
import { useEffect, useRef, useState } from "react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import AdminSidebar from "../../components/sidebar/AdminSidebar"
import {
  AdminExamFlagSchema,
  AdminExamSchema,
  EditExamRequest,
} from "../../api"
import useAuthedApi from "../../hooks/useAuthedApi"
import useToasts from "../../hooks/useToasts"
import { useNavigate, useParams } from "react-router-dom"
import { isAxiosError } from "axios"
import { MdTitle } from "react-icons/md"
import { HiOutlineExclamationCircle } from "react-icons/hi"
import { FaHashtag } from "react-icons/fa"
import { BsCursorText } from "react-icons/bs"
import {
  BiBarcodeReader,
  BiDollarCircle,
  BiSolidDashboard,
  BiStopwatch,
} from "react-icons/bi"
import { VscJson } from "react-icons/vsc"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"

const AdminExam = () => {
  const [exam, setExam] = useState<AdminExamSchema>()
  const { AdminAuthedApi } = useAuthedApi()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const { toastList, setToastList } = useToasts()
  const { slug } = useParams()
  const navigate = useNavigate()
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [hidden, setHidden] = useState(false)
  // const [prereleased, setPrereleased] = useState(false)
  // const [promotion, setPromotion] = useState<number | undefined>()
  const [promotionCode, setPromotionCode] = useState<string | undefined>()
  const [price, setPrice] = useState(0)
  const [durationHours, setDurationHours] = useState(0)
  const [examFlags, setExamFlags] = useState<AdminExamFlagSchema[]>([])
  const [examFlagsJSON, setExamFlagsJSON] = useState("")
  const [showEditModal, setShowEditModal] = useState(false)
  const [editErrMsg, setEditErrMsg] = useState("")
  const editErrRef = useRef<HTMLInputElement>(null)
  const [showEditExamFlagsModal, setShowEditExamFlagsModal] = useState(false)
  const [editExamFlagsErrMsg, setEditExamFlagsErrMsg] = useState("")
  const editExamFlagsErrRef = useRef<HTMLInputElement>(null)
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(
    window.innerWidth < 640
  )

  const getExam = async (slug: string) => {
    try {
      const response = await AdminAuthedApi.adminReadExam(slug)
      if (!response.data) {
        setErrMsg("Get exam failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setExam(response.data)
      setTitle(response.data.title)
      setDescription(response.data.description)
      setHidden(response.data.hidden)
      // setPrereleased(response.data.prereleased)
      // setPromotion(
      //   response.data.promotion == null ? undefined : response.data.promotion
      // )
      setPromotionCode(
        response.data.promotion_code == null
          ? undefined
          : response.data.promotion_code
      )
      setPrice(response.data.price)
      setDurationHours(response.data.duration_hours)
      setExamFlags(response.data.examflags)
      setExamFlagsJSON(JSON.stringify(response.data.examflags, null, 2))
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get exam failed with unknown error")
        }
      } else {
        setErrMsg("Get exam failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  useEffect(() => {
    if (!slug) {
      navigate("/admin/exams")
      return
    }
    getExam(slug)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const editExam = async (exam: AdminExamSchema | undefined) => {
    if (!exam) {
      setEditErrMsg(`No exam to edit`)
      return
    }

    try {
      let payload: EditExamRequest = {
        title: title,
        description: description,
        hidden: hidden,
        duration_hours: durationHours,
        price: price,
        // prereleased: prereleased,
        // promotion: promotion,
        promotion_code: promotionCode,
        platform: exam.platform, //TODO: make it possible to edit the platform
        course: exam.course, //TODO: make it possible to edit the course
      }
      const response = await AdminAuthedApi.adminEditExam(exam.slug, payload)
      if (!response.data) {
        setEditErrMsg(`Edit exam ${exam.slug} failed with unknown error`)
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success) {
        setEditErrMsg(`No exams were edited`)
        return
      }

      setToastList(
        toastList.concat({
          type: "success",
          children: `Exam ${exam.title} was edited successfully!`,
        })
      )

      setExam(response.data.exam)

      setShowEditModal(false)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setEditErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setEditErrMsg("Data validation error")
        } else {
          setEditErrMsg(`Edit exam ${exam.slug} failed with unknown error`)
        }
      } else {
        setEditErrMsg(`Edit exam ${exam.slug} failed with unknown error`)
      }
      editErrRef.current?.focus()
    }
  }

  const editExamFlags = async (json_string: string | undefined) => {
    if (!exam) {
      setEditErrMsg(`No exam to edit`)
      return
    }

    if (!json_string) {
      setEditExamFlagsErrMsg(`No examflags json to edit`)
      return
    }

    try {
      let payload: AdminExamFlagSchema[] = JSON.parse(examFlagsJSON)
      const response = await AdminAuthedApi.adminEditExamflagsJson(
        exam.slug,
        payload
      )
      if (!response.data) {
        setEditErrMsg(
          `Edit exam ${exam.slug} examflags failed with unknown error`
        )
        return
      }
      // console.log(JSON.stringify(response?.data))

      setToastList(
        toastList.concat({
          type: "success",
          children: `Exam ${exam.title} examflags were edited successfully!`,
        })
      )

      setExamFlags(response.data)
      setExamFlagsJSON(JSON.stringify(response.data, null, 2))

      setShowEditExamFlagsModal(false)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setEditExamFlagsErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setEditExamFlagsErrMsg("Data validation error")
        } else {
          setEditExamFlagsErrMsg(
            `Edit exam ${exam.slug} examflags failed with unknown error`
          )
        }
      } else {
        setEditExamFlagsErrMsg(
          `Edit exam ${exam.slug} examflags failed with unknown error`
        )
      }
      editExamFlagsErrRef.current?.focus()
    }
  }

  return (
    <>
      <div className="fixed top-0 inset-x-0 z-40 flex justify-between items-center">
        <NavbarPrivate />
      </div>

      <AdminSidebar
        onCollapseChange={(collapsed) => {
          setSidebarCollapsed(collapsed)
        }}
      />
      <main
        className={
          sidebarCollapsed
            ? "pt-20 pl-0 transition-all ease-in-out duration-300"
            : "pt-20 sm:pl-64 lg:pl-96 transition-all ease-in-out duration-300"
        }
      >
        <div className="container mx-auto">
          <div className="p-5">
            <section id="exam">
              {exam ? (
                <>
                  <h1 className="text-left text-2xl md:text-4xl font-bold text-gray-800 dark:text-white mb-5 mx-3 md:mx-0">
                    {exam.title}
                  </h1>
                  <div className="mb-2 block">
                    <Label htmlFor="id" value="ID" />
                  </div>
                  <TextInput
                    className="mb-2"
                    disabled
                    icon={FaHashtag}
                    readOnly
                    id="id"
                    placeholder="ID"
                    required
                    type="text"
                    value={exam.id}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="slug" value="Slug" />
                  </div>
                  <TextInput
                    className="mb-2"
                    disabled
                    icon={BsCursorText}
                    readOnly
                    id="slug"
                    placeholder="Slug"
                    required
                    type="text"
                    value={exam.slug}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="title" value="Title" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={MdTitle}
                    id="title"
                    placeholder="Title"
                    required
                    type="text"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value)
                    }}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="description" value="Description" />
                  </div>
                  <Textarea
                    className="mb-2"
                    id="description"
                    placeholder="Description"
                    required
                    value={description}
                    rows={5}
                    onChange={(e) => {
                      setDescription(e.target.value)
                    }}
                  />
                  <div className="flex items-center gap-2">
                    <Checkbox
                      id="hidden"
                      checked={hidden}
                      onChange={(e) => {
                        setHidden(e.target.checked)
                      }}
                    />
                    <Label htmlFor="hidden">Hidden</Label>
                  </div>
                  {/* <div className="flex items-center gap-2">
                  <Checkbox
                    id="prereleased"
                    checked={prereleased}
                    onChange={(e) => {
                      setPrereleased(e.target.checked)
                    }}
                  />
                  <Label htmlFor="prereleased">Pre-released</Label>
                </div>
                <div className="mb-2 block">
                  <Label htmlFor="promotion" value="Promotion" />
                </div>
                <TextInput
                  className="mb-2"
                  icon={BiSolidOffer}
                  id="promotion"
                  placeholder="Promotion"
                  required
                  type="text"
                  value={promotion}
                  onChange={(e) => {
                    setPromotion(parseInt(e.target.value))
                  }}
                /> */}
                  <div className="mb-2 block">
                    <Label htmlFor="promotion_code" value="Promotion Code" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={BiBarcodeReader}
                    id="promotion_code"
                    placeholder="Promotion Code"
                    required
                    type="text"
                    value={promotionCode}
                    onChange={(e) => {
                      setPromotionCode(e.target.value)
                    }}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="price" value="Price" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={BiDollarCircle}
                    id="price"
                    placeholder="Price"
                    required
                    type="text"
                    value={price}
                    onChange={(e) => {
                      setPrice(parseInt(e.target.value))
                    }}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="duration_hours" value="Duration Hours" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={BiStopwatch}
                    id="duration_hours"
                    placeholder="Duration Hours"
                    required
                    type="text"
                    value={durationHours}
                    onChange={(e) => {
                      setPrice(parseInt(e.target.value))
                    }}
                  />
                  <div className="flex flex-row-reverse">
                    <Button
                      color="warning"
                      onClick={() => {
                        setShowEditModal(true)
                      }}
                    >
                      Edit lab
                    </Button>
                  </div>
                  <Modal
                    size="md"
                    dismissible={true}
                    popup={true}
                    show={showEditModal}
                    onClose={() => {
                      setShowEditModal(false)
                    }}
                  >
                    <Modal.Header />
                    <Modal.Body>
                      <div className="text-center">
                        <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                          Are you sure you want to edit the exam {exam?.slug}?
                        </h3>
                        <div className={!editErrMsg ? "sr-only" : ""}>
                          <Alert
                            color="failure"
                            icon={ExclamationTriangleIcon}
                            onDismiss={function onDismiss() {
                              setEditErrMsg("")
                            }}
                          >
                            <span ref={editErrRef}>{editErrMsg}</span>
                          </Alert>
                        </div>
                        <div className="flex justify-center gap-4">
                          <Button
                            color="failure"
                            onClick={() => editExam(exam)}
                          >
                            Yes, I'm sure
                          </Button>
                          <Button
                            color="gray"
                            onClick={() => setShowEditModal(false)}
                          >
                            No, cancel
                          </Button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>

                  <h3 className="text-left text-lg md:text-xl font-bold text-gray-800 dark:text-white mt-5 mb-5 mx-3 md:mx-0">
                    Exam Flags
                  </h3>
                  <Tabs aria-label="Flag tabs">
                    <Tabs.Item active icon={BiSolidDashboard} title="UI">
                      <>
                        <Accordion collapseAll>
                          {examFlags.map((examFlag) => (
                            <Accordion.Panel key={examFlag.id}>
                              <Accordion.Title>
                                {examFlag.title}
                              </Accordion.Title>
                              <Accordion.Content>
                                <div className="flex text-gray-800 dark:text-white text-sm">
                                  <>ID: </>
                                  <>{examFlag.id}</>
                                </div>
                                <div className="flex text-gray-800 dark:text-white text-sm">
                                  <>Index: </>
                                  <>{examFlag.index}</>
                                </div>
                                <div className="flex text-gray-800 dark:text-white text-sm">
                                  <>Title: </>
                                  <>{examFlag.title}</>
                                </div>
                                <div className="flex text-gray-800 dark:text-white text-sm">
                                  <>Description: </>
                                  <>{examFlag.description}</>
                                </div>
                                <div className="flex text-gray-800 dark:text-white text-sm">
                                  <>Value: </>
                                  <>{examFlag.value}</>
                                </div>
                              </Accordion.Content>
                            </Accordion.Panel>
                          ))}
                        </Accordion>
                      </>
                    </Tabs.Item>
                    <Tabs.Item icon={VscJson} title="JSON">
                      <div className="mb-2 block">
                        <Label htmlFor="examFlagsJSON" value="Flags JSON" />
                      </div>
                      <Textarea
                        className="mb-2"
                        id="examFlagsJSON"
                        placeholder="[]"
                        required
                        value={examFlagsJSON}
                        rows={15}
                        onChange={(e) => {
                          setExamFlagsJSON(e.target.value)
                        }}
                      />
                      <div className="flex flex-row-reverse">
                        <Button
                          color="warning"
                          onClick={() => {
                            setShowEditExamFlagsModal(true)
                          }}
                        >
                          Edit exam flags
                        </Button>
                      </div>
                      <Modal
                        size="md"
                        dismissible={true}
                        popup={true}
                        show={showEditExamFlagsModal}
                        onClose={() => {
                          setShowEditExamFlagsModal(false)
                        }}
                      >
                        <Modal.Header />
                        <Modal.Body>
                          <div className="text-center">
                            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                              Are you sure you want to edit the exam flags of
                              exam {exam?.slug}?
                            </h3>
                            <div
                              className={!editExamFlagsErrMsg ? "sr-only" : ""}
                            >
                              <Alert
                                color="failure"
                                icon={ExclamationTriangleIcon}
                                onDismiss={function onDismiss() {
                                  setEditExamFlagsErrMsg("")
                                }}
                              >
                                <span ref={editExamFlagsErrRef}>
                                  {editExamFlagsErrMsg}
                                </span>
                              </Alert>
                            </div>
                            <div className="flex justify-center gap-4">
                              <Button
                                color="failure"
                                onClick={() => editExamFlags(examFlagsJSON)}
                              >
                                Yes, I'm sure
                              </Button>
                              <Button
                                color="gray"
                                onClick={() => setShowEditExamFlagsModal(false)}
                              >
                                No, cancel
                              </Button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </Tabs.Item>
                  </Tabs>
                </>
              ) : (
                <Alert color="warning" icon={ExclamationTriangleIcon}>
                  <span>No exam to display</span>
                </Alert>
              )}

              <div className={!errMsg ? "sr-only" : ""}>
                <Alert
                  color="failure"
                  icon={ExclamationTriangleIcon}
                  onDismiss={function onDismiss() {
                    setErrMsg("")
                  }}
                >
                  <span ref={errRef}>{errMsg}</span>
                </Alert>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  )
}

export default AdminExam
