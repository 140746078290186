import Footer from "../../components/Footer"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"
import { PublicCourseSchema } from "../../api"
import useApi from "../../hooks/useApi"
import { useState, useEffect, useRef } from "react"
import { isAxiosError } from "axios"
import { Alert, Button } from "flowbite-react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import useAuth from "../../hooks/useAuth"
import NavbarPublic from "../../components/navbar/NavbarPublic"
import ARTE_CAN from "../../assets/images/ARTE_CAN.png"
import ARTA_CAN from "../../assets/images/ARTA_CAN.png"
import GRTE_CAN from "../../assets/images/GRTE_CAN.png"
import GRTA_CAN from "../../assets/images/GRTA_CAN.png"
import Loading from "../../components/Loading"
import { Link } from "react-router-dom"

const Courses = () => {
  const [courses, setCourses] = useState<PublicCourseSchema[]>()
  const [loading, setLoading] = useState(true)
  const { CoursesApi } = useApi()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const { auth } = useAuth()

  const getCourses = async () => {
    try {
      const response = await CoursesApi.coursesReadCourses()
      if (!response.data) {
        setErrMsg("Get courses failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setCourses(response.data)
      setLoading(false)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get courses failed with unknown error")
        }
      } else {
        setErrMsg("Get courses failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  useEffect(() => {
    getCourses()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className="min-h-screen flex flex-col justify-between">
      {auth ? <NavbarPrivate /> : <NavbarPublic />}
      <div className="mb-auto">
        <div className="container mx-auto">
          <section id="courses">
            <h1 className="text-center text-2xl md:text-4xl font-bold text-gray-800 dark:text-white mt-8 mb-5 md:mb-10 mx-3 md:mx-0">
              COURSES
            </h1>
            <div className={!errMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setErrMsg("")
                }}
              >
                <span ref={errRef}>{errMsg}</span>
              </Alert>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <>
                {courses?.length ? (
                  <div className="grid grid-cols-1 justify-items-center mx-3 lg:mx-0 gap-12 mb-10">
                    {courses.map((course) => (
                      <div
                        key={course.slug}
                        className={
                          "mb-16 mx-3 md:mx-24 pt-10 border-dashed border-4 rounded-3xl " +
                          {
                            arte: "border-amber-500",
                            arta: "border-amber-500",
                            grte: "border-green-600",
                            grta: "border-green-600",
                          }[course.slug]
                        }
                      >
                        <div className="md:flex justify-center items-center">
                          <div>
                            <img
                              src={
                                {
                                  arte: ARTE_CAN,
                                  arta: ARTA_CAN,
                                  grte: GRTE_CAN,
                                  grta: GRTA_CAN,
                                }[course.slug]
                              }
                              className="md:mr-3 md:h-56"
                              alt="HackTricks Training Logo"
                            />
                          </div>
                          <div className="">
                            <h1 className="text-3xl md:text-5xl font-bold text-center dark:text-white">
                              HACKTRICKS {course.slug.toUpperCase()}
                            </h1>
                            <h2 className="text-2xl md:text-3xl font-medium text-center dark:text-white">
                              {course
                                ? course?.title.toUpperCase()
                                : "No course data"}
                            </h2>
                          </div>
                        </div>
                        <div className="px-10 md:px-12 mt-5 md:mt-12 dark:text-white">
                          <h3 className="text-xl md:text-2xl font-bold text-center dark:text-white">
                            {
                              {
                                arte: "IN-DEPTH AWS SECURITY TRAINING",
                                arta: "AWS SECURITY TRAINING",
                                grte: "IN-DEPTH GCP SECURITY TRAINING",
                                grta: "GCP SECURITY TRAINING",
                              }[course.slug]
                            }
                          </h3>
                          <p className="text-justify mt-3 md:mt-5">
                            {course ? course?.description : "No course data"}
                          </p>
                        </div>
                        <div className="mx-3 md:mx-16 mt-10 md:mt-10 grid grid-cols-1 sm:grid-cols-2 justify-items-center dark:text-white">
                          <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                            <div className="flex-none">
                              <h1
                                className={
                                  "text-5xl font-bold " +
                                  {
                                    arte: "text-amber-500",
                                    arta: "text-amber-500",
                                    grte: "text-green-600",
                                    grta: "text-green-600",
                                  }[course.slug]
                                }
                              >
                                {
                                  {
                                    arte: "20",
                                    arta: "8",
                                    grte: "20",
                                    grta: "8",
                                  }[course.slug]
                                }
                                +
                              </h1>
                            </div>
                            <div className="grow ml-3">
                              <h3 className="text-xl font-bold">
                                HOURS OF VIDEO LESSONS
                              </h3>
                            </div>
                          </div>
                          <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                            <div className="flex-none">
                              <h1
                                className={
                                  "text-5xl font-bold " +
                                  {
                                    arte: "text-amber-500",
                                    arta: "text-amber-500",
                                    grte: "text-green-600",
                                    grta: "text-green-600",
                                  }[course.slug]
                                }
                              >
                                {
                                  {
                                    arte: "70",
                                    arta: "25",
                                    grte: "70",
                                    grta: "25",
                                  }[course.slug]
                                }
                                +
                              </h1>
                            </div>
                            <div className="grow ml-3">
                              <h3 className="text-xl font-bold">
                                ESTIMATED HOURS COMPLETION TIME
                              </h3>
                            </div>
                          </div>
                          <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                            <div className="flex-none">
                              <h1
                                className={
                                  "text-5xl font-bold " +
                                  {
                                    arte: "text-amber-500",
                                    arta: "text-amber-500",
                                    grte: "text-green-600",
                                    grta: "text-green-600",
                                  }[course.slug]
                                }
                              >
                                {
                                  {
                                    arte: "50",
                                    arta: "20",
                                    grte: "50",
                                    grta: "20",
                                  }[course.slug]
                                }
                                +
                              </h1>
                            </div>
                            <div className="grow ml-3">
                              <h3 className="text-xl font-bold">
                                HANDS-ON LABS TO TRAIN
                              </h3>
                            </div>
                          </div>
                          <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                            <div className="flex-none">
                              <h1
                                className={
                                  "text-5xl font-bold " +
                                  {
                                    arte: "text-amber-500",
                                    arta: "text-amber-500",
                                    grte: "text-green-600",
                                    grta: "text-green-600",
                                  }[course.slug]
                                }
                              >
                                1
                              </h1>
                            </div>
                            <div className="grow ml-3">
                              <h3 className="text-xl font-bold">
                                {
                                  {
                                    arte: "EXAM TO PROVE YOUR KNOWLEDGE",
                                    arta: "CERTIFICATE OF COMPLETION",
                                    grte: "EXAM TO PROVE YOUR KNOWLEDGE",
                                    grta: "CERTIFICATE OF COMPLETION",
                                  }[course.slug]
                                }
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-1 justify-items-center">
                          <div className="grow-0 my-12 ">
                            <Button
                              as={Link}
                              size="xl"
                              outline={true}
                              to={`/courses/${course.slug}`}
                              className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                            >
                              VIEW DETAILS
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No courses to display</p>
                )}
              </>
            )}
          </section>
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Courses
