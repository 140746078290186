import React, { useState, useRef, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { isAxiosError } from "axios"
import useApi from "../../hooks/useApi"
import {
  Alert,
  Button,
  Checkbox,
  Label,
  TextInput,
  Tooltip,
} from "flowbite-react"
import { ExclamationCircleIcon } from "@heroicons/react/24/solid"
import { HiExternalLink, HiInformationCircle } from "react-icons/hi"
import useToasts from "../../hooks/useToasts"
import { EmailSubscriptionEnum } from "../../api"
import HT_TRAINING_logo from "../../assets/images/HT_TRAINING_logo.svg"
import useRecaptcha from "../../hooks/useRecaptcha"
import ReCAPTCHA from "react-google-recaptcha"
import useTheme from "../../hooks/useTheme"

const USERNAME_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,64}$/
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/

const Signup = () => {
  const { toastList, setToastList } = useToasts()
  const { AuthApi } = useApi()
  const navigate = useNavigate()

  const usernameRef = useRef<HTMLInputElement>(null)
  const errRef = useRef<HTMLInputElement>(null)

  const [username, setUsername] = useState("")
  const [validUsername, setValidUsername] = useState(false)
  const [usernameFocus, setUsernameFocus] = useState(false)

  const [email, setEmail] = useState("")
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [firstName, setFirstName] = useState("")
  const [validFirstName, setValidFirstName] = useState(false)

  const [lastName, setLastName] = useState("")
  const [validLastName, setValidLastName] = useState(false)

  const [company, setCompany] = useState<string | undefined>(undefined)

  const [pwd, setPwd] = useState("")
  const [validPwd, setValidPwd] = useState(false)
  const [pwdFocus, setPwdFocus] = useState(false)

  const [matchPwd, setMatchPwd] = useState("")
  const [validMatchPwd, setValidMatchPwd] = useState(false)
  const [matchPwdFocus, setMatchPwdFocus] = useState(false)

  const [terms, setTerms] = useState(false)
  const [validTerms, setValidTerms] = useState(false)

  const [emailSubscriptions, setEmailSubscriptions] = useState<
    EmailSubscriptionEnum[]
  >([EmailSubscriptionEnum.Required])
  const [validEmailSubscriptions, setValidEmailSubscriptions] = useState(false)

  const [errMsg, setErrMsg] = useState("")

  const [loadingSignup, setLoadingSignup] = useState(false)

  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha()
  const [validCapchaToken, setValidCapchaToken] = useState(false)
  const { theme } = useTheme()

  useEffect(() => {
    usernameRef.current?.focus()
  }, [])

  useEffect(() => {
    setValidUsername(USERNAME_REGEX.test(username))
  }, [username])

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email))
  }, [email])

  useEffect(() => {
    setValidFirstName(firstName !== "")
  }, [firstName])

  useEffect(() => {
    setValidLastName(lastName !== "")
  }, [lastName])

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd))
    setValidMatchPwd(pwd === matchPwd && pwd !== "")
  }, [pwd, matchPwd])

  useEffect(() => {
    setValidTerms(terms)
  }, [terms])

  useEffect(() => {
    setValidEmailSubscriptions(
      emailSubscriptions.includes(EmailSubscriptionEnum.Required)
    )
  }, [emailSubscriptions])

  useEffect(() => {
    setValidCapchaToken(capchaToken !== null && capchaToken !== "")
  }, [capchaToken])

  useEffect(() => {
    setErrMsg("")
  }, [username, email, firstName, lastName, pwd, matchPwd, terms, capchaToken])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoadingSignup(true)
    // if button enabled with JS hack
    const v1 = USERNAME_REGEX.test(username)
    const v2 = EMAIL_REGEX.test(email)
    const v3 = firstName !== ""
    const v4 = lastName !== ""
    const v5 = PWD_REGEX.test(pwd)
    const v6 = pwd === matchPwd
    const v7 = terms
    const v8 = emailSubscriptions.includes(EmailSubscriptionEnum.Required)
    if (!v1 || !v2 || !v3 || !v4 || !v5 || !v6 || !v7 || !v8) {
      setErrMsg("Invalid data")

      setLoadingSignup(false)
      return
    }
    if (!validCapchaToken) {
      setErrMsg("You must complete the Captcha")
      setLoadingSignup(false)
      return
    }

    try {
      const response = await AuthApi.authSignup(
        {
          username: username,
          email: email,
          first_name: firstName,
          last_name: lastName,
          company: company,
          password: pwd,
          match_password: matchPwd,
          terms: terms,
          email_subscriptions: emailSubscriptions,
          captcha_token: capchaToken,
        },
        {
          withCredentials: true,
        }
      )
      if (!response.data.success) {
        setErrMsg("Sign up failed with unknown error")

        setLoadingSignup(false)
        return
      }
      // console.log(JSON.stringify(response?.data))
      setToastList(
        toastList.concat({
          type: "success",
          link: { to: "/signin", text: "Sign in" },
          children: "Account created successfully!",
        })
      )

      setUsername("")
      setEmail("")
      setFirstName("")
      setLastName("")
      setCompany(undefined)
      setPwd("")
      setMatchPwd("")
      setTerms(false)
      setEmailSubscriptions([EmailSubscriptionEnum.Required])
      recaptchaRef.current?.reset()

      navigate("/signin")
    } catch (err) {
      if (isAxiosError(err)) {
        console.log(err)
        if (!err.response?.status) {
          setErrMsg("No server response")
        } else if (err.response?.status === 400) {
          setErrMsg(err.response.data.detail)
        } else if (err.response?.status === 409) {
          setErrMsg("Username or email already taken")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Sign up failed with unknown error")
        }
      } else {
        setErrMsg("Sign up failed with unknown error")
      }
      errRef.current?.focus()
    }

    setLoadingSignup(false)
  }

  return (
    <section>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-48 w-auto"
              src={HT_TRAINING_logo}
              alt="HackTricks Training"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
              Sign up for a new account
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600 dark:text-white">
              Or{" "}
              <Link
                to="/signin"
                className="font-medium text-red-logo hover:text-red-900"
              >
                sign in if you already have one!
              </Link>
            </p>
          </div>
          <div className={!errMsg ? "sr-only" : ""}>
            <Alert
              color="failure"
              icon={ExclamationCircleIcon}
              onDismiss={function onDismiss() {
                setErrMsg("")
              }}
            >
              <span ref={errRef}>{errMsg}</span>
            </Alert>
          </div>
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="username" value="Username" />
              </div>
              <TextInput
                id="username"
                type="text"
                placeholder="Username"
                autoComplete="username"
                required={true}
                onChange={(e) => {
                  setUsername(e.target.value)
                }}
                onFocus={() => setUsernameFocus(true)}
                onBlur={() => setUsernameFocus(false)}
                value={username}
                ref={usernameRef}
                aria-invalid={validUsername ? "false" : "true"}
                color={validUsername ? "success" : !username ? "" : "failure"}
                helperText={
                  <>
                    <span
                      className={
                        usernameFocus && username && !validUsername
                          ? ""
                          : "sr-only"
                      }
                    >
                      <ExclamationCircleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                      Invalid username:
                      <br />
                      Must have 4 to 64 characters.
                      <br />
                      Must begin with a letter.
                      <br />
                      Can contain letters, numbers, underscores and hyphens.
                    </span>
                  </>
                }
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email" value="Email" />
              </div>
              <TextInput
                id="email"
                type="email"
                placeholder="Email"
                autoComplete="email"
                required={true}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
                value={email}
                aria-invalid={validEmail ? "false" : "true"}
                color={validEmail ? "success" : !email ? "" : "failure"}
                helperText={
                  <>
                    <span
                      className={
                        emailFocus && email && !validEmail ? "" : "sr-only"
                      }
                    >
                      <ExclamationCircleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                      Invalid email:
                      <br />
                      Must be a valid email
                    </span>
                  </>
                }
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="firstname" value="First name" />
              </div>
              <TextInput
                id="firstname"
                type="text"
                placeholder="First name"
                autoComplete="off"
                required={true}
                onChange={(e) => {
                  setFirstName(e.target.value)
                }}
                value={firstName}
                aria-invalid={validFirstName ? "false" : "true"}
                color={validFirstName ? "success" : !firstName ? "" : "failure"}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="lastname" value="Last name" />
              </div>
              <TextInput
                id="lastname"
                type="text"
                placeholder="Last name"
                autoComplete="off"
                required={true}
                onChange={(e) => {
                  setLastName(e.target.value)
                }}
                value={lastName}
                aria-invalid={validLastName ? "false" : "true"}
                color={validLastName ? "success" : !lastName ? "" : "failure"}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="password" value="Password" />
              </div>
              <TextInput
                id="password"
                type="password"
                placeholder="Password"
                autoComplete="password"
                required={true}
                onChange={(e) => {
                  setPwd(e.target.value)
                }}
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
                value={pwd}
                aria-invalid={validPwd ? "false" : "true"}
                color={validPwd ? "success" : !pwd ? "" : "failure"}
                helperText={
                  <>
                    <span
                      className={pwdFocus && pwd && !validPwd ? "" : "sr-only"}
                    >
                      <ExclamationCircleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                      Invalid password:
                      <br />
                      8 to 32 characters.
                      <br />
                      Must include uppercase and lowercase letters, a number and
                      a special character.
                      <br />
                      Allowed special characters:{" "}
                      <span aria-label="exclamation mark">!</span>{" "}
                      <span aria-label="at symbol">@</span>{" "}
                      <span aria-label="hashtag">#</span>{" "}
                      <span aria-label="dollar sign">$</span>{" "}
                      <span aria-label="percent">%</span>
                    </span>
                  </>
                }
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="confirm_pwd" value="Confirm password" />
              </div>
              <TextInput
                id="confirm_pwd"
                type="password"
                placeholder="Confirm password"
                autoComplete="password"
                required={true}
                onChange={(e) => {
                  setMatchPwd(e.target.value)
                }}
                onFocus={() => setMatchPwdFocus(true)}
                onBlur={() => setMatchPwdFocus(false)}
                value={matchPwd}
                aria-invalid={validMatchPwd ? "false" : "true"}
                color={validMatchPwd ? "success" : !matchPwd ? "" : "failure"}
                helperText={
                  <>
                    <span
                      className={
                        matchPwdFocus && matchPwd && !validMatchPwd
                          ? ""
                          : "sr-only"
                      }
                    >
                      <ExclamationCircleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                      Invalid password confirmation:
                      <br />
                      Must match the password input field.
                    </span>
                  </>
                }
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="company" value="Company (Optional)" />
              </div>
              <TextInput
                id="company"
                type="text"
                placeholder="Company"
                autoComplete="off"
                required={false}
                value={company}
                color=""
                onChange={(e) => {
                  setCompany(e.target.value)
                }}
              />
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="terms"
                required={true}
                onChange={(e) => {
                  setTerms(e.target.checked)
                }}
                checked={terms}
                aria-invalid={validTerms ? "false" : "true"}
                color={validTerms ? "success" : "failure"}
                theme={{
                  root: {
                    base: "h-4 w-4 rounded border border-neutral-300 bg-neutral-100 focus:ring-2 focus:ring-red-logo dark:border-neutral-600 dark:bg-neutral-700 dark:ring-offset-neutral-800 dark:focus:ring-red-logo",
                  },
                }}
              />
              <Label color={validTerms ? "success" : "failure"} htmlFor="terms">
                <div className="flex ">
                  {`I accept the Terms & Conditions ${
                    validTerms ? "" : " - Must be accepted"
                  }`}
                  <a
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                    href="/terms"
                    target="_blank"
                  >
                    <HiExternalLink className="text-lg ml-2" />
                  </a>
                </div>
              </Label>
            </div>

            <div className="flex items-center gap-2">
              <Checkbox
                id="comercial_emails"
                onChange={(e) => {
                  if (e.target.checked) {
                    setEmailSubscriptions(
                      emailSubscriptions.concat(
                        EmailSubscriptionEnum.Commercial
                      )
                    )
                  } else {
                    setEmailSubscriptions(
                      emailSubscriptions.filter(
                        (i) => i !== EmailSubscriptionEnum.Commercial
                      )
                    )
                  }
                }}
                checked={emailSubscriptions.includes(
                  EmailSubscriptionEnum.Commercial
                )}
                theme={{
                  root: {
                    base: "h-4 w-4 rounded border border-neutral-300 bg-neutral-100 focus:ring-2 focus:ring-red-logo dark:border-neutral-600 dark:bg-neutral-700 dark:ring-offset-neutral-800 dark:focus:ring-red-logo",
                  },
                }}
              />
              <Label htmlFor="comercial_emails">
                <div className="flex ">
                  {"I wish to receive emails with announcements and updates"}
                  <Tooltip
                    content={
                      <p className="max-w-xs">
                        Receive new course release alerts and other important
                        platform updates
                      </p>
                    }
                    placement="right"
                    className="bg-neutral-900 dark:bg-neutral-700"
                    theme={{
                      arrow: {
                        base: "absolute z-10 h-2 w-2 rotate-45 bg-neutral-900 dark:bg-neutral-700",
                      },
                    }}
                  >
                    <HiInformationCircle className="text-lg ml-2 font-medium text-indigo-600 hover:text-indigo-500" />
                  </Tooltip>
                </div>
              </Label>
            </div>
            <div className="text-center">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={"6LeLETEqAAAAAF0NOrJvSJX0TInOG4_DRqwpGtsD"}
                onChange={handleRecaptcha}
                theme={theme || "light"}
                style={{ display: "inline-block" }}
              />
            </div>

            <div className="flex flex-col items-center">
              <Button
                isProcessing={loadingSignup}
                disabled={
                  !validCapchaToken ||
                  !validUsername ||
                  !validFirstName ||
                  !validLastName ||
                  !validPwd ||
                  !validMatchPwd ||
                  !validTerms ||
                  !validEmailSubscriptions ||
                  loadingSignup
                    ? true
                    : false
                }
                className="w-full md:w-auto px-20 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Signup
