import { Alert, Button, Checkbox, Label, TextInput } from "flowbite-react"
import { FC, PropsWithChildren, useEffect, useState, MouseEvent } from "react"
import { EmailSubscriptionEnum, AdminUserSchema } from "../../api"
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import { RxCross2 } from "react-icons/rx"

export type ModalProps = PropsWithChildren<{
  userToEdit: AdminUserSchema
  setUserToEdit: (user: AdminUserSchema | undefined) => void
  showEditModal: boolean
  setShowEditModal: (show: boolean) => void
  editUser: (user: AdminUserSchema | undefined) => void
  editErrMsg: string
  setEditErrMsg: (msg: string) => void
  editErrRef: React.RefObject<HTMLInputElement>
}>

const USERNAME_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,64}$/
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/

const AdminEditUser: FC<ModalProps> = ({
  userToEdit,
  setUserToEdit,
  showEditModal,
  setShowEditModal,
  editUser,
  editErrMsg,
  setEditErrMsg,
  editErrRef,
}) => {
  const [username, setUsername] = useState(userToEdit?.username)
  const [validUsername, setValidUsername] = useState(false)
  const [usernameFocus, setUsernameFocus] = useState(false)

  const [email, setEmail] = useState(userToEdit?.email)
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [firstName, setFirstName] = useState(userToEdit?.first_name)
  const [validFirstName, setValidFirstName] = useState(false)

  const [lastName, setLastName] = useState(userToEdit?.last_name)
  const [validLastName, setValidLastName] = useState(false)

  const [company, setCompany] = useState(userToEdit?.company || undefined)

  const [isActive, setIsActive] = useState(userToEdit?.is_active)
  const [validIsActive, setValidIsActive] = useState(false)

  const [isTermsAccepted, setIsTermsAccepted] = useState(
    userToEdit?.is_terms_accepted
  )
  const [validIsTermsAccepted, setValidIsTermsAccepted] = useState(false)

  const [emailSubscriptions, setEmailSubscriptions] = useState<
    EmailSubscriptionEnum[]
  >(userToEdit?.email_subscriptions)
  const [validEmailSubscriptions, setValidEmailSubscriptions] = useState(false)

  useEffect(() => {
    setValidUsername(USERNAME_REGEX.test(username))
  }, [username])

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email))
  }, [email])

  useEffect(() => {
    setValidFirstName(firstName !== "")
  }, [firstName])

  useEffect(() => {
    setValidLastName(lastName !== "")
  }, [lastName])

  useEffect(() => {
    setValidIsActive(isActive !== undefined)
  }, [isActive])

  useEffect(() => {
    setValidIsTermsAccepted(isTermsAccepted !== undefined)
  }, [isTermsAccepted])

  useEffect(() => {
    setValidEmailSubscriptions(
      emailSubscriptions.includes(EmailSubscriptionEnum.Required)
    )
  }, [emailSubscriptions])

  useEffect(() => {
    setEditErrMsg("")

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    username,
    email,
    firstName,
    lastName,
    company,
    isActive,
    isTermsAccepted,
    emailSubscriptions,
  ])

  return (
    <>
      {showEditModal ? (
        <>
          <div
            onClick={(e: MouseEvent<HTMLDivElement>) => {
              if (e.target === e.currentTarget) {
                setShowEditModal(false)
                setUserToEdit(undefined)
              }
            }}
            className="fixed top-0 right-0 left-0 z-50 h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80"
          >
            <div className="relative h-full w-full p-4 md:h-auto max-w-5xl">
              {/*content*/}
              <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
                {/*header*/}
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                    Edit user {userToEdit.username}
                  </h3>

                  <button
                    aria-label="Close"
                    className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                    type="button"
                    onClick={() => {
                      setShowEditModal(false)
                      setUserToEdit(undefined)
                    }}
                  >
                    <RxCross2 size="1.5em" />
                  </button>
                </div>
                {/*body*/}

                <div className="p-3 md:p-5">
                  <div className={!editErrMsg ? "sr-only" : ""}>
                    <Alert
                      color="failure"
                      icon={ExclamationTriangleIcon}
                      onDismiss={function onDismiss() {
                        setEditErrMsg("")
                      }}
                    >
                      <span ref={editErrRef}>{editErrMsg}</span>
                    </Alert>
                  </div>
                  <form
                    className="flex flex-col gap-4"
                    onSubmit={(e: React.FormEvent) => {
                      e.preventDefault()
                      editUser({
                        ...userToEdit,
                        username: username,
                        email: email,
                        first_name: firstName,
                        last_name: lastName,
                        company: company,
                        is_active: isActive,
                        is_terms_accepted: isTermsAccepted,
                        email_subscriptions: emailSubscriptions,
                      })
                    }}
                  >
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="username" value="Username" />
                      </div>
                      <TextInput
                        id="username"
                        type="text"
                        placeholder="Username"
                        autoComplete="username"
                        required={true}
                        onChange={(e) => {
                          setUsername(e.target.value)
                        }}
                        onFocus={() => setUsernameFocus(true)}
                        onBlur={() => setUsernameFocus(false)}
                        value={username}
                        aria-invalid={validUsername ? "false" : "true"}
                        color={
                          validUsername ? "success" : !username ? "" : "failure"
                        }
                        helperText={
                          <>
                            <span
                              className={
                                usernameFocus && username && !validUsername
                                  ? ""
                                  : "sr-only"
                              }
                            >
                              <ExclamationCircleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                              Invalid username:
                              <br />
                              Must have 4 to 64 characters.
                              <br />
                              Must begin with a letter.
                              <br />
                              Can contain letters, numbers, underscores and
                              hyphens.
                            </span>
                          </>
                        }
                      />
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="email" value="Email" />
                      </div>
                      <TextInput
                        id="email"
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                        required={true}
                        onChange={(e) => {
                          setEmail(e.target.value)
                        }}
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                        value={email}
                        aria-invalid={validEmail ? "false" : "true"}
                        color={validEmail ? "success" : !email ? "" : "failure"}
                        helperText={
                          <>
                            <span
                              className={
                                emailFocus && email && !validEmail
                                  ? ""
                                  : "sr-only"
                              }
                            >
                              <ExclamationCircleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                              Invalid email:
                              <br />
                              Must be a valid email
                            </span>
                          </>
                        }
                      />
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="firstname" value="First name" />
                      </div>
                      <TextInput
                        id="firstname"
                        type="text"
                        placeholder="First name"
                        autoComplete="off"
                        required={true}
                        onChange={(e) => {
                          setFirstName(e.target.value)
                        }}
                        value={firstName}
                        aria-invalid={validFirstName ? "false" : "true"}
                        color={
                          validFirstName
                            ? "success"
                            : !firstName
                            ? ""
                            : "failure"
                        }
                      />
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="lastname" value="Last name" />
                      </div>
                      <TextInput
                        id="lastname"
                        type="text"
                        placeholder="Last name"
                        autoComplete="off"
                        required={true}
                        onChange={(e) => {
                          setLastName(e.target.value)
                        }}
                        value={lastName}
                        aria-invalid={validLastName ? "false" : "true"}
                        color={
                          validLastName ? "success" : !lastName ? "" : "failure"
                        }
                      />
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="company" value="Company (Optional)" />
                      </div>
                      <TextInput
                        id="company"
                        type="text"
                        placeholder="Company"
                        autoComplete="off"
                        required={false}
                        onChange={(e) => {
                          setCompany(e.target.value)
                        }}
                        value={company || ""}
                        color=""
                      />
                    </div>
                    <div className="flex items-center gap-2">
                      <Checkbox
                        id="is_active"
                        onChange={(e) => {
                          setIsActive(e.target.checked)
                        }}
                        checked={isActive}
                      />
                      <Label htmlFor="is_active">User active</Label>
                    </div>

                    <div className="flex items-center gap-2">
                      <Checkbox
                        id="is_terms_accepted"
                        onChange={(e) => {
                          setIsTermsAccepted(e.target.checked)
                        }}
                        checked={isTermsAccepted}
                      />
                      <Label htmlFor="is_terms_accepted">Terms accepted</Label>
                    </div>

                    <div className="flex items-center gap-2">
                      <Checkbox
                        id="comercial_emails"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setEmailSubscriptions(
                              emailSubscriptions.concat(
                                EmailSubscriptionEnum.Commercial
                              )
                            )
                          } else {
                            setEmailSubscriptions(
                              emailSubscriptions.filter(
                                (i) => i !== EmailSubscriptionEnum.Commercial
                              )
                            )
                          }
                        }}
                        checked={emailSubscriptions.includes(
                          EmailSubscriptionEnum.Commercial
                        )}
                      />
                      <Label htmlFor="comercial_emails">
                        Commercial email consent
                      </Label>
                    </div>

                    <div className="flex justify-center gap-4">
                      <Button
                        disabled={
                          !validUsername ||
                          !validFirstName ||
                          !validLastName ||
                          !validIsActive ||
                          !validIsTermsAccepted ||
                          !validEmailSubscriptions
                            ? true
                            : false
                        }
                        type="submit"
                      >
                        Submit changes
                      </Button>
                      <Button
                        color="gray"
                        onClick={() => setShowEditModal(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </div>

                {/*footer*/}
                {/* <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b space-x-2 dark:border-gray-600">

                </div> */}
              </div>
            </div>
          </div>
          {/* <div
            className="opacity-50 fixed inset-0 z-40 bg-black"
          ></div> */}
        </>
      ) : null}
    </>
  )
}

export default AdminEditUser
