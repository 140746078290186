import { useRef, useState, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { isAxiosError } from "axios"
import useAuthedApi from "../../hooks/useAuthedApi"
import { Alert, Button, Label, TextInput } from "flowbite-react"
import { ExclamationCircleIcon } from "@heroicons/react/24/solid"
import useToasts from "../../hooks/useToasts"
import HT_TRAINING_logo_letters_horiz_big from "../../assets/images/HT_TRAINING_logo_letters_horiz_big.svg"

const TOKEN_REGEX = /^([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_\-+/=]*)$/

const ActivateVoucher = () => {
  const { toastList, setToastList } = useToasts()
  const { VouchersAuthedApi } = useAuthedApi()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const errRef = useRef<HTMLInputElement>(null)
  const [token, setToken] = useState("")
  const [validToken, setValidToken] = useState(false)

  const [errMsg, setErrMsg] = useState("")

  useEffect(() => {
    const t = searchParams.get("token")
    if (t !== null) {
      setToken(t)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setErrMsg("")
  }, [token])

  useEffect(() => {
    setValidToken(TOKEN_REGEX.test(token))
  }, [token])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    // if button enabled with JS hack
    if (!TOKEN_REGEX.test(token)) {
      setErrMsg("Invalid data")
      return
    }

    try {
      const response = await VouchersAuthedApi.vouchersActivateVoucher({
        token: token,
      })
      if (!response.data.success) {
        setErrMsg("Voucher activation failed with unknown error")
        return
      }
      //   console.log(JSON.stringify(response?.data))

      setToastList(
        toastList.concat({
          type: "success",
          children: "Voucher activated successfully!",
        })
      )
      navigate("/dashboard")

      setToken("")
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else if (err.response?.status === 404) {
          setErrMsg(err.response?.data.detail)
        } else if (err.response?.status === 403) {
          setErrMsg(
            "Error activating voucher ensure you are signed in and your account is activated"
          )
        } else if (err.response?.status === 400) {
          setErrMsg(err.response?.data.detail)
        } else {
          setErrMsg("Voucher activation failed with unknown error")
        }
      } else {
        setErrMsg("Voucher activation failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  return (
    <section>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={HT_TRAINING_logo_letters_horiz_big}
              alt="HackTricks Training"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
              Activate your voucher
            </h2>
            {/* TODO should add some info about what the voucher is for */}
          </div>
          <div className={!errMsg ? "sr-only" : ""}>
            <Alert
              color="failure"
              icon={ExclamationCircleIcon}
              onDismiss={function onDismiss() {
                setErrMsg("")
              }}>
              <span ref={errRef}>{errMsg}</span>
            </Alert>
          </div>
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="token" value="Token" />
              </div>
              <TextInput
                id="token"
                type="text"
                placeholder="Token"
                autoComplete="off"
                required={true}
                disabled={true}
                value={token}
                aria-invalid={validToken ? "false" : "true"}
                color={validToken ? "success" : !token ? "" : "failure"}
              />
            </div>

            <div className="text-sm">
              <p className="font-medium text-gray-500">
                Thank you for your purchase!
              </p>
            </div>
            <Button disabled={!validToken ? true : false} type="submit">
              Submit
            </Button>
          </form>
        </div>
      </div>
    </section>
  )
}

export default ActivateVoucher
