import { Alert, Button, Modal, Tooltip } from "flowbite-react"
import { useEffect, useMemo, useRef, useState } from "react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import AdminSidebar from "../../components/sidebar/AdminSidebar"
import { ColumnDef } from "@tanstack/react-table"
import { AdminCertificateSchema } from "../../api"
import useAuthedApi from "../../hooks/useAuthedApi"
import useToasts from "../../hooks/useToasts"
// import { useNavigate } from "react-router-dom"
import { isAxiosError } from "axios"
import AdminTable from "../../components/tables/AdminTable"
import { MdDelete } from "react-icons/md"
import { HiOutlineExclamationCircle } from "react-icons/hi"
import { RiEdit2Fill } from "react-icons/ri"
import AdminEditCertificate from "../../components/modals/AdminEditCertificate"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"

const AdminCertificates = () => {
  const [certificates, setCertificates] = useState<AdminCertificateSchema[]>()
  const { AdminAuthedApi } = useAuthedApi()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [certificateToDelete, setCertificateToDelete] =
    useState<AdminCertificateSchema>()
  const [deleteErrMsg, setDeleteErrMsg] = useState("")
  const deleteErrRef = useRef<HTMLInputElement>(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [certificateToEdit, setCertificateToEdit] =
    useState<AdminCertificateSchema>()
  const [editErrMsg, setEditErrMsg] = useState("")
  const editErrRef = useRef<HTMLInputElement>(null)
  const { toastList, setToastList } = useToasts()
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(
    window.innerWidth < 640
  )
  //   const navigate = useNavigate()

  const getCertificates = async () => {
    try {
      const response = await AdminAuthedApi.adminReadCertificates()
      if (!response.data) {
        setErrMsg("Get certificates failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setCertificates(response.data)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get certificates failed with unknown error")
        }
      } else {
        setErrMsg("Get certificates failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  const deleteCertificate = async (
    certificate: AdminCertificateSchema | undefined
  ) => {
    if (!certificate) {
      setDeleteErrMsg(`No certificate to delete`)
      return
    }
    if (!certificates) {
      setDeleteErrMsg(`No certificates`)
      return
    }
    try {
      const response = await AdminAuthedApi.adminDeleteCertificate(
        certificate.id
      )
      if (!response.data) {
        setDeleteErrMsg(
          `Delete certificate ${certificate.id} failed with unknown error`
        )
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success) {
        setDeleteErrMsg(`No certificates were deleted`)
        return
      }

      setToastList(
        toastList.concat({
          type: "success",
          children: `Certificate ${certificate.id} was deleted successfully!`,
        })
      )

      const index = certificates.indexOf(certificate)
      let clone = certificates
      clone.splice(index, 1)
      setCertificates(clone)

      setShowDeleteModal(false)
      setCertificateToDelete(undefined)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setDeleteErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setDeleteErrMsg("Data validation error")
        } else {
          setDeleteErrMsg(
            `Delete certificate ${certificate.id} failed with unknown error`
          )
        }
      } else {
        setDeleteErrMsg(
          `Delete certificate ${certificate.id} failed with unknown error`
        )
      }
      deleteErrRef.current?.focus()
    }
  }

  const editCertificate = async (
    certificate: AdminCertificateSchema | undefined
  ) => {
    if (!certificate) {
      setDeleteErrMsg(`No certificate to edit`)
      return
    }
    if (!certificates) {
      setDeleteErrMsg(`No certificates`)
      return
    }
    console.log(certificate)
    try {
      const response = await AdminAuthedApi.adminEditCertificate({
        ...certificate,
      })
      if (!response.data) {
        setEditErrMsg(
          `Editing certificate ${certificate.id} failed with unknown error`
        )
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success || !response.data.certificate) {
        setEditErrMsg(`No certificates were modified`)
        return
      }

      setToastList(
        toastList.concat({
          type: "success",
          children: `Certificate ${certificate.id} was modified successfully!`,
        })
      )

      const index = certificates.indexOf(certificate)
      let clone = certificates
      clone.splice(index, 1)
      clone.push(response.data.certificate)
      setCertificates(clone)

      setShowEditModal(false)
      setCertificateToEdit(undefined)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setEditErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setEditErrMsg("Data validation error")
        } else {
          setEditErrMsg(
            `Edit certificate ${certificate.id} failed with unknown error`
          )
        }
      } else {
        setEditErrMsg(
          `Edit certificate ${certificate.id} failed with unknown error`
        )
      }
      editErrRef.current?.focus()
    }
  }

  useEffect(() => {
    getCertificates()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo<ColumnDef<AdminCertificateSchema>[]>(
    () => [
      {
        header: "CERTIFICATE ID",
        footer: (props) => props.column.id,
        accessorKey: "id",
        cell: (props) => props.getValue(),
      },
      {
        header: "COURSE",
        footer: (props) => props.column.id,
        accessorKey: "course.slug",
        cell: (props) => props.getValue(),
      },
      {
        header: "USERNAME",
        footer: (props) => props.column.id,
        accessorKey: "user.username",
        cell: (props) => props.getValue(),
      },
      {
        header: "FULL NAME",
        footer: (props) => props.column.id,
        accessorKey: "full_name",
        cell: (props) => props.getValue(),
      },
      {
        header: "AWARDED AT",
        footer: (props) => props.column.id,
        accessorKey: "awarded_at",
        cell: (props) => props.getValue(),
      },
      {
        id: "actions",
        footer: (props) => props.column.id,
        cell: (props) => (
          <div className="flex flex-row gap-1">
            <Tooltip content="Edit certificate">
              <button
                onClick={() => {
                  setShowEditModal(true)
                  setCertificateToEdit(props.row.original)
                }}
                className="font-medium text-center text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
              >
                <RiEdit2Fill size={20} />
              </button>
            </Tooltip>
            <Tooltip content="Delete certificate">
              <button
                onClick={() => {
                  setShowDeleteModal(true)
                  setCertificateToDelete(props.row.original)
                }}
                className="font-medium text-center text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
              >
                <MdDelete size={20} />
              </button>
            </Tooltip>
          </div>
        ),
      },
    ],
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [certificates]
  )

  return (
    <>
      <div className="fixed top-0 inset-x-0 z-40 flex justify-between items-center">
        <NavbarPrivate />
      </div>

      <AdminSidebar
        onCollapseChange={(collapsed) => {
          setSidebarCollapsed(collapsed)
        }}
      />
      <main
        className={
          sidebarCollapsed
            ? "pt-20 pl-0 transition-all ease-in-out duration-300"
            : "pt-20 sm:pl-64 lg:pl-96 transition-all ease-in-out duration-300"
        }
      >
        <div className="container mx-auto">
          <div className="p-5">
            <section id="certificates">
              <h1 className="text-left text-2xl md:text-4xl font-bold text-gray-800 dark:text-white mb-5 mx-3 md:mx-0">
                CERTIFICATES
              </h1>
              <div className={!errMsg ? "sr-only" : ""}>
                <Alert
                  color="failure"
                  icon={ExclamationTriangleIcon}
                  onDismiss={function onDismiss() {
                    setErrMsg("")
                  }}
                >
                  <span ref={errRef}>{errMsg}</span>
                </Alert>
              </div>
              {certificateToEdit ? (
                <AdminEditCertificate
                  certificateToEdit={certificateToEdit}
                  setCertificateToEdit={setCertificateToEdit}
                  editCertificate={editCertificate}
                  showEditModal={showEditModal}
                  setShowEditModal={setShowEditModal}
                  editErrMsg={editErrMsg}
                  setEditErrMsg={setEditErrMsg}
                  editErrRef={editErrRef}
                />
              ) : (
                <></>
              )}
              <div className="mb-10">
                {certificates?.length ? (
                  <AdminTable columns={columns} data={certificates} />
                ) : (
                  <Alert color="warning" icon={ExclamationTriangleIcon}>
                    <span>No certificates to display</span>
                  </Alert>
                )}
              </div>
            </section>
          </div>
        </div>
      </main>
      <Modal
        size="md"
        dismissible={true}
        popup={true}
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false)
          setCertificateToDelete(undefined)
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete the certificate{" "}
              {certificateToDelete?.id}?
            </h3>
            <div className={!deleteErrMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setDeleteErrMsg("")
                }}
              >
                <span ref={deleteErrRef}>{deleteErrMsg}</span>
              </Alert>
            </div>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={() => deleteCertificate(certificateToDelete)}
              >
                Yes, I'm sure
              </Button>
              <Button color="gray" onClick={() => setShowDeleteModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminCertificates
