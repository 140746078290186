import { FC, PropsWithChildren, useEffect, useState } from "react"

export type TimerProps = PropsWithChildren<{
  delta: number
}>

const Timer: FC<TimerProps> = ({ delta }) => {
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    let d = delta
    let _days = Math.floor(d / 86400)
    d -= _days * 86400
    setDays(_days)

    // calculate (and subtract) whole hours
    let _hours = Math.floor(d / 3600) % 24
    d -= _hours * 3600
    setHours(_hours)

    // calculate (and subtract) whole minutes
    let _minutes = Math.floor(d / 60) % 60
    d -= _minutes * 60
    setMinutes(_minutes)

    // what's left is seconds
    let _seconds = d % 60 // in theory the modulus is not required
    setSeconds(_seconds)
  }, [delta])

  return (
    <div className="flex justify-center md:justify-start gap-5">
      <div className="flex flex-col gap-2 relative">
        <div className="h-10 min-w-10 sm:min-w-12 sm:h-12 lg:min-w-14 lg:h-14 flex justify-between items-center bg-neutral-200 dark:bg-neutral-600 rounded-lg">
          <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-neutral-400 dark:bg-neutral-900"></div>
          <span className="lg:text-3xl sm:text-xl text-md font-semibold text-neutral-700 dark:text-white">
            {days.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </span>
          <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-neutral-400 dark:bg-neutral-900"></div>
        </div>
        <span className="text-neutral-700 dark:text-white text-[7px] md:text-[9px] font-hacktricks text-center uppercase">
          {days === 1 ? "Day" : "Days"}
        </span>
      </div>
      <div className="flex flex-col gap-2 relative">
        <div className="h-10 w-10 sm:w-12 sm:h-12 lg:w-14 lg:h-14 flex justify-between items-center bg-neutral-200 dark:bg-neutral-600 rounded-lg">
          <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-neutral-400 dark:bg-neutral-900"></div>
          <span className="lg:text-3xl sm:text-xl text-md font-semibold text-neutral-700 dark:text-white">
            {hours.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </span>
          <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-neutral-400 dark:bg-neutral-900"></div>
        </div>
        <span className="text-neutral-700 dark:text-white text-[7px] md:text-[9px] font-hacktricks text-center uppercase">
          {hours === 1 ? "Hour" : "Hours"}
        </span>
      </div>
      <div className="flex flex-col gap-2 relative">
        <div className="h-10 w-10 sm:w-12 sm:h-12 lg:w-14 lg:h-14 flex justify-between items-center bg-neutral-200 dark:bg-neutral-600 rounded-lg">
          <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-neutral-400 dark:bg-neutral-900"></div>
          <span className="lg:text-3xl sm:text-xl text-md font-semibold text-neutral-700 dark:text-white">
            {minutes.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </span>
          <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-neutral-400 dark:bg-neutral-900"></div>
        </div>
        <span className="text-neutral-700 dark:text-white text-[7px] md:text-[9px] font-hacktricks text-center uppercase">
          {minutes === 1 ? "Minute" : "Minutes"}
        </span>
      </div>
      <div className="flex flex-col gap-2 relative">
        <div className="h-10 w-10 sm:w-12 sm:h-12 lg:w-14 lg:h-14 flex justify-between items-center bg-neutral-200 dark:bg-neutral-600 rounded-lg">
          <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-neutral-400 dark:bg-neutral-900"></div>
          <span className="lg:text-3xl sm:text-xl text-md font-semibold text-neutral-700 dark:text-white">
            {seconds.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </span>
          <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-neutral-400 dark:bg-neutral-900"></div>
        </div>
        <span className="text-neutral-700 dark:text-white text-[7px] md:text-[9px] font-hacktricks text-center uppercase">
          {seconds === 1 ? "Second" : "Seconds"}
        </span>
      </div>
    </div>
  )
}
export default Timer
