import { FC, useEffect, useRef, useState } from "react"
import { PublicExamFlagSchema } from "../api"
import useToasts from "../hooks/useToasts"
import useAuthedApi from "../hooks/useAuthedApi"
import { isAxiosError } from "axios"
import { Alert, Badge, Button, Spinner, TextInput } from "flowbite-react"
import { AiFillFlag } from "react-icons/ai"
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import rehypeAttrs from "rehype-attr"

export type ExamroomFlagProps = {
  flag: PublicExamFlagSchema
  tf_outputs: any
  disabled: boolean
  onChange: (completed: boolean) => void
}

const ExamroomFlag: FC<ExamroomFlagProps> = ({
  flag,
  tf_outputs,
  disabled,
  onChange,
}) => {
  const { toastList, setToastList } = useToasts()
  const [value, setValue] = useState("")
  const [loading, setLoading] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const { ExamsAuthedApi } = useAuthedApi()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)

  const checkFlagCompleted = async () => {
    try {
      const response = await ExamsAuthedApi.examsCheckHasExamflagMe(flag.id)
      if (!response.data) {
        setErrMsg("Get flag status failed with unknown error")
        return
      }
      setIsCompleted(response.data.completed)
      onChange(response.data.completed)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get flag status failed with unknown error")
        }
      } else {
        setErrMsg("Get flag status failed with unknown error")
      }
      errRef.current?.focus()
    }
    setLoading(false)
  }

  const submitFlag = async () => {
    setErrMsg("")
    setLoadingSubmit(true)
    try {
      const response = await ExamsAuthedApi.examsSubmitExamflagMe(flag.id, {
        value: value,
      })
      if (!response.data) {
        setErrMsg("Incorrect flag")
        setLoadingSubmit(false)
        return
      }
      //   console.log(JSON.stringify(response?.data))
      setToastList(
        toastList.concat({
          type: "success",
          children: "You got the flag!",
        })
      )
      setIsCompleted(true)
      setValue("")
      onChange(true)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 400) {
          setErrMsg(err.response.data.detail)
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Flag submission failed with unknown error")
        }
      } else {
        setErrMsg("Flag submission failed with unknown error")
      }
      errRef.current?.focus()
    }
    setLoadingSubmit(false)
  }

  useEffect(() => {
    checkFlagCompleted()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="mt-5">
      {loading ? (
        <div className="relative top-1/2 -translate-y-1/2">
          <Spinner size="xl" color="failure" />
        </div>
      ) : (
        <>
          <div className="flex">
            <h6 className="text-xl font-bold text-neutral-900 dark:text-white">
              {flag.title}
            </h6>
            {isCompleted ? (
              <Badge
                className="ml-3 px-4 text-sm"
                icon={AiFillFlag}
                color="success"
              >
                Completed
              </Badge>
            ) : (
              <></>
            )}
          </div>
          <Markdown
            className="markdown-body mt-3"
            remarkPlugins={[remarkGfm, rehypeAttrs]}
          >
            {flag.description}
          </Markdown>

          {(flag.tf_outputs as any).outputs.length > 0 &&
          tf_outputs !== undefined ? (
            <Alert className="mt-3" color="info" icon={InformationCircleIcon}>
              {(flag.tf_outputs as any).outputs.map((output: any) => (
                <p className="break-all" key={output.key}>
                  {output.name}: {tf_outputs[output.key].value}
                </p>
              ))}
            </Alert>
          ) : (
            <></>
          )}
          <div className={!errMsg ? "sr-only" : ""}>
            <Alert
              color="failure"
              icon={ExclamationTriangleIcon}
              onDismiss={function onDismiss() {
                setErrMsg("")
              }}
            >
              <span ref={errRef}>{errMsg}</span>
            </Alert>
          </div>
          <div className="flex mt-3">
            <TextInput
              disabled={isCompleted}
              onChange={(e) => {
                setValue(e.target.value)
              }}
              value={value}
              className="grow"
              name="value"
              placeholder="flag{...}"
              theme={{
                field: {
                  input: {
                    base: "block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-neutral-50 border-neutral-300 text-neutral-900 focus:border-red-logo focus:ring-red-logo dark:border-neutral-600 dark:bg-neutral-700 dark:text-white dark:placeholder-neutral-400 dark:focus:border-red-logo dark:focus:ring-red-logo rounded-lg p-2.5 text-sm",
                  },
                },
              }}
            />
            <Button
              type="button"
              isProcessing={loadingSubmit}
              processingSpinner={<Spinner color="failure" />}
              onClick={() => {
                submitFlag()
              }}
              disabled={isCompleted || disabled}
              className="ml-2 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default ExamroomFlag
