import { useContext } from "react"
import AuthContext, { AuthData as AuthDataType } from "../context/AuthProvider"

export type AuthData = AuthDataType

const useAuth = () => {
  return useContext(AuthContext)
}

export default useAuth
