import Footer from "../../components/Footer"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"
import { useState, useEffect, useRef } from "react"
import { Alert, Button, Modal, TextInput } from "flowbite-react"
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import useAuth from "../../hooks/useAuth"
import NavbarPublic from "../../components/navbar/NavbarPublic"
import ARTE_CAN from "../../assets/images/ARTE_CAN.png"
import ARTA_CAN from "../../assets/images/ARTA_CAN.png"
import GRTE_CAN from "../../assets/images/GRTE_CAN.png"
import GRTA_CAN from "../../assets/images/GRTA_CAN.png"
import Loading from "../../components/Loading"
import { Link } from "react-router-dom"
import useAuthedApi from "../../hooks/useAuthedApi"
import ModalCustom from "../../components/modals/ModalCustom"
import { isAxiosError } from "axios"
import { LuPartyPopper } from "react-icons/lu"

const Bundles = () => {
  const [loading, setLoading] = useState(true)
  const { CoursesAuthedApi } = useAuthedApi()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const [errModalMsg, setErrModalMsg] = useState("")
  const errModalRef = useRef<HTMLInputElement>(null)
  const [quantity, setQuantity] = useState<string>("1")
  const [validQuantity, setValidQuantity] = useState(false)
  const [quantityFocus, setQuantityFocus] = useState(false)
  const { auth } = useAuth()
  const [showAuthModal, setShowAuthModal] = useState(false)
  const bundles = ["expert", "apprentice"]

  useEffect(() => {
    setLoading(false)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const q = parseInt(quantity)
    setValidQuantity(q >= 1)
  }, [quantity])

  const buy = async (slug: string, quantity: string) => {
    const q = parseInt(quantity)
    if (q < 1) {
      setErrModalMsg("Invalid data, quantity must be larger than 0")
      return
    }

    if (!auth?.user) {
      setShowAuthModal(true)
      return
    }

    try {
      const response = await CoursesAuthedApi.coursesBuyCourseBundle({
        slugs:
          {
            expert: ["arte", "grte"],
            apprentice: ["arta", "grta"],
          }[slug] || [],
        quantity: parseInt(quantity),
      })
      if (!response.data) {
        setErrModalMsg("Bundle purchase failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      window.location.href = response.data.url
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrModalMsg("No server response")
        } else if (err.response?.status === 400) {
          setErrModalMsg(err.response?.data.detail)
        } else if (err.response?.status === 404) {
          setErrModalMsg("We could not find that bundle")
        } else if (err.response?.status === 422) {
          setErrModalMsg("Data validation error")
        } else {
          setErrModalMsg("Bundle purchase failed with unknown error")
        }
      } else {
        setErrModalMsg("Bundle purchase failed with unknown error")
      }
      errModalRef.current?.focus()
    }
  }

  return (
    <section className="min-h-screen flex flex-col justify-between">
      {auth ? <NavbarPrivate /> : <NavbarPublic />}
      <div className="mb-auto">
        <div className="container mx-auto">
          <section id="courses">
            <h1 className="text-center text-2xl md:text-4xl font-bold text-gray-800 dark:text-white mt-8 mb-5 md:mb-10 mx-3 md:mx-0">
              COURSE BUNDLES
            </h1>
            <div className={!errMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setErrMsg("")
                }}>
                <span ref={errRef}>{errMsg}</span>
              </Alert>
            </div>
            <Modal
              show={showAuthModal}
              size="md"
              popup={true}
              onClose={() => {
                setShowAuthModal(false)
              }}>
              <Modal.Header />
              <Modal.Body>
                <div className="text-center">
                  <ExclamationCircleIcon className="mx-auto mb-4 h-14 w-14 text-red-500 dark:text-red-200" />
                  <h3 className="mb-5 text-lg font-normal text-white">
                    You need to sign in to make a purchase
                  </h3>
                  <div className="flex justify-center gap-4">
                    <Button
                      as={Link}
                      to="/signin"
                      outline={true}
                      className="ml-3 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2">
                      Sign in
                    </Button>
                    <Button
                      as={Link}
                      to="/signup"
                      className="ml-3 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2">
                      Create an account
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            {loading ? (
              <Loading />
            ) : (
              <>
                <div className="grid grid-cols-1 justify-items-center mx-3 lg:mx-0 gap-12 mb-10">
                  {bundles.map((bundle) => (
                    <>
                      <div
                        id={bundle}
                        className="mb-16 mx-3 md:mx-24 pt-10 px-10 border-dashed border-4 rounded-3xl border-red-logo">
                        <div className="md:flex justify-center items-center">
                          <div className="">
                            <h1 className="text-3xl md:text-5xl font-bold text-center dark:text-white">
                              {
                                {
                                  expert: "HACKTRICKS ARTE + GRTE",
                                  apprentice: "HACKTRICKS ARTA + GRTA",
                                }[bundle]
                              }
                            </h1>
                            <h2 className="text-2xl md:text-3xl font-medium text-center dark:text-white">
                              {
                                {
                                  expert: "AWS & GCP RED TEAM EXPERT",
                                  apprentice: "AWS & GCP RED TEAM APPRENTICE",
                                }[bundle]
                              }
                            </h2>
                          </div>
                        </div>
                        <div className="pt-5 md:flex justify-center items-center">
                          <img
                            src={
                              {
                                expert: ARTE_CAN,
                                apprentice: ARTA_CAN,
                              }[bundle]
                            }
                            className="md:mr-3 md:h-56"
                            alt="HackTricks Training Logo"
                          />
                          <img
                            src={
                              {
                                expert: GRTE_CAN,
                                apprentice: GRTA_CAN,
                              }[bundle]
                            }
                            className="md:mr-3 md:h-56"
                            alt="HackTricks Training Logo"
                          />
                        </div>
                        <Alert
                          className="mt-5 w-2/3 mx-auto"
                          color="success"
                          icon={LuPartyPopper}>
                          Take advantage of our{" "}
                          <strong>20% bundle discount </strong>
                        </Alert>
                        <div className="md:flex justify-center items-center gap-5">
                          <div className="grow-0 mt-12">
                            <Button
                              as={Link}
                              size="xl"
                              outline={true}
                              to={
                                {
                                  expert: "/courses/arte",
                                  apprentice: "/courses/arta",
                                }[bundle] || "#"
                              }
                              className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2">
                              VIEW{" "}
                              {{
                                expert: "ARTE",
                                apprentice: "ARTA",
                              }[bundle] || ""}{" "}
                              DETAILS
                            </Button>
                          </div>

                          <div className="grow-0 mt-12 ">
                            <Button
                              as={Link}
                              size="xl"
                              outline={true}
                              to={
                                {
                                  expert: "/courses/grte",
                                  apprentice: "/courses/grta",
                                }[bundle] || "#"
                              }
                              className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2">
                              VIEW{" "}
                              {{
                                expert: "GRTE",
                                apprentice: "GRTA",
                              }[bundle] || ""}{" "}
                              DETAILS
                            </Button>
                          </div>
                        </div>
                        <ModalCustom title={"Get this bundle now"}>
                          <div className="p-6">
                            <div className="space-y-6">
                              <div className={!errModalMsg ? "sr-only" : ""}>
                                <Alert
                                  color="failure"
                                  icon={ExclamationTriangleIcon}
                                  onDismiss={function onDismiss() {
                                    setErrModalMsg("")
                                  }}>
                                  <span ref={errModalRef}>{errModalMsg}</span>
                                </Alert>
                              </div>
                              <div className="text-base leading-relaxed text-neutral-700 dark:text-white">
                                Includes:
                                <ul className="list-disc list-inside">
                                  <li>
                                    1 AWS Red Team{" "}
                                    {{
                                      expert: "Expert",
                                      apprentice: "Apprentice",
                                    }[bundle] || ""}{" "}
                                    voucher
                                  </li>
                                  <li>
                                    1 GCP Red Team{" "}
                                    {{
                                      expert: "Expert",
                                      apprentice: "Apprentice",
                                    }[bundle] || ""}{" "}
                                    voucher
                                  </li>
                                </ul>
                              </div>
                              <div className="text-base leading-relaxed text-neutral-700 dark:text-white text-center">
                                Price:{" "}
                                {({
                                  expert: 1099,
                                  apprentice: 359,
                                }[bundle] || 1) * 2}
                                €
                              </div>

                              <div className="flex flex-col items-center">
                                <TextInput
                                  id="quantity"
                                  type="number"
                                  placeholder="1"
                                  addon="Quantity"
                                  required={true}
                                  onChange={(e) => {
                                    if (
                                      parseInt(e.target.value) > 0 ||
                                      e.target.value === ""
                                    ) {
                                      setQuantity(e.target.value)
                                    }
                                  }}
                                  onFocus={() => setQuantityFocus(true)}
                                  onBlur={() => setQuantityFocus(false)}
                                  value={quantity}
                                  aria-invalid={
                                    validQuantity ? "false" : "true"
                                  }
                                  color={
                                    validQuantity
                                      ? ""
                                      : !quantity
                                      ? ""
                                      : "failure"
                                  }
                                  theme={{ field: { base: "relative w-16" } }}
                                  helperText={
                                    <>
                                      <span
                                        className={
                                          quantityFocus &&
                                          quantity &&
                                          !validQuantity
                                            ? ""
                                            : "sr-only"
                                        }>
                                        <ExclamationCircleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                                        Invalid quantity, must be greater than 0
                                      </span>
                                    </>
                                  }
                                />
                              </div>
                              <div className="text-base leading-relaxed text-neutral-700 dark:text-white text-center ">
                                <Alert
                                  className="mb-2"
                                  color="success"
                                  icon={LuPartyPopper}>
                                  Take advantage of our{" "}
                                  <strong>20% bundle discount </strong>
                                </Alert>
                                <span className="text-red-700 dark:text-red-500 line-through">
                                  Total:{" "}
                                  {({
                                    expert: 1099,
                                    apprentice: 359,
                                  }[bundle] || 1) *
                                    2 *
                                    (quantity ? parseInt(quantity) : 0)}
                                  €
                                </span>
                                <br />
                                Promotion: -20% <br />
                                <span className="text-green-600 dark:text-green-400">
                                  New total:{" "}
                                  {Math.round(
                                    ({
                                      expert: 1099,
                                      apprentice: 359,
                                    }[bundle] || 1) *
                                      2 *
                                      (quantity ? parseInt(quantity) : 0) *
                                      0.8 *
                                      100
                                  ) / 100}
                                  €
                                </span>
                              </div>

                              <div className="flex flex-col items-center">
                                <Button
                                  className="items-center text-neutral-700 dark:text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                                  type="button"
                                  onClick={() => buy(bundle, quantity)}
                                  disabled={!validQuantity}>
                                  Buy now
                                </Button>
                              </div>
                            </div>
                          </div>
                        </ModalCustom>
                      </div>
                    </>
                  ))}
                </div>
              </>
            )}
          </section>
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Bundles
