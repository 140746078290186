import { Toast as FlowbiteToast } from "flowbite-react"
import { FC, PropsWithChildren } from "react"
import { Link } from "react-router-dom"
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid"

export type ToastProps = PropsWithChildren<{
  link?: {
    to: string
    text: string
  }
  type: "success" | "failure" | "info" | "warning"
}>

const colorClasses = {
  success: "bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200",
  failure: "bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200",
  info: "bg-blue-100 text-blue-500 dark:bg-blue-800 dark:text-blue-200",
  warning:
    "bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200",
}

const icon = {
  success: <CheckCircleIcon className="h-5 w-5" />,
  failure: <ExclamationCircleIcon className="h-5 w-5" />,
  info: <InformationCircleIcon className="h-5 w-5" />,
  warning: <ExclamationTriangleIcon className="h-5 w-5" />,
}

export const Toast: FC<ToastProps> = ({ link, type, children }) => {
  return (
    <FlowbiteToast
      theme={{
        root: {
          base: "flex w-full max-w-xs items-center rounded-lg bg-white p-4 text-gray-500 shadow dark:bg-neutral-700 dark:text-gray-400 duration-300",
        },
      }}
    >
      <div
        className={
          "inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg " +
          colorClasses[type]
        }
      >
        {icon[type]}
      </div>
      <div className="ml-3 text-sm font-normal">
        <span>{children}</span>
        <br />
        {link ? (
          <Link
            className="font-medium text-indigo-600 hover:text-indigo-500"
            to={link.to}
          >
            {link.text}
          </Link>
        ) : (
          <></>
        )}
      </div>
      <FlowbiteToast.Toggle
        theme={{
          base: "-mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-lg bg-white p-1.5 text-gray-400 hover:bg-neutral-100 hover:text-gray-900 focus:ring-2 focus:ring-gray-300 dark:bg-neutral-800 dark:text-gray-500 dark:hover:bg-neutral-600 dark:hover:text-white",
        }}
      />
    </FlowbiteToast>
  )
}
