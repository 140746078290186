import { Badge } from "flowbite-react"
import { FC, PropsWithChildren, useEffect, useState } from "react"

export type TimerProps = PropsWithChildren<{
  date: Date
}>

const LessonDeploymentTimer: FC<TimerProps> = ({ date }) => {
  const [now, setNow] = useState(new Date())

  useEffect(() => {
    const interval_1s = setInterval(() => {
      setNow(new Date())
    }, 1000)

    return () => {
      clearInterval(interval_1s)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function timeConversion(duration: number) {
    const portions: string[] = []

    const msInHour = 1000 * 60 * 60
    const hours = Math.trunc(duration / msInHour)
    if (hours > 0) {
      portions.push(hours + "h")
      duration = duration - hours * msInHour
    }

    const msInMinute = 1000 * 60
    const minutes = Math.trunc(duration / msInMinute)
    if (minutes > 0) {
      portions.push(minutes + "m")
      duration = duration - minutes * msInMinute
    }

    const seconds = Math.trunc(duration / 1000)
    if (seconds > 0) {
      portions.push(seconds + "s")
    }

    return portions.join(" ")
  }

  return (
    <Badge
      color="indigo"
      className="mt-3 md:mt-0 md:ml-2 md:my-auto text-center"
    >
      Time remaining <br></br>
      {timeConversion(date.getTime() - now.getTime())}
    </Badge>
  )
}
export default LessonDeploymentTimer
