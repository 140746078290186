import { Button } from "flowbite-react"
import { useState } from "react"
import { RxCross2 } from "react-icons/rx"
import { FC, PropsWithChildren, MouseEvent } from "react"

export type ModalProps = PropsWithChildren<{
  title: string
}>

const ModalCustom: FC<ModalProps> = ({ title, children }) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <div className="mb-12 mt-10">
      <div className="flex flex-col items-center">
        <Button
          className="items-center text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
          type="button"
          size="lg"
          onClick={() => setShowModal(true)}>
          Buy voucher
        </Button>
      </div>
      {showModal ? (
        <>
          <div
            onClick={(e: MouseEvent<HTMLDivElement>) => {
              if (e.target === e.currentTarget) {
                setShowModal(false)
              }
            }}
            className="fixed top-0 right-0 left-0 z-50 h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center flex bg-neutral-900 bg-opacity-50 dark:bg-opacity-80">
            <div className="relative h-full w-full p-4 md:h-auto max-w-lg">
              {/*content*/}
              <div className="relative rounded-lg bg-white shadow dark:bg-neutral-700">
                {/*header*/}
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:border-neutral-600">
                  <h3 className="text-xl font-medium text-neutral-900 dark:text-white">
                    {title}
                  </h3>

                  <button
                    aria-label="Close"
                    className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-neutral-400 hover:bg-neutral-200 hover:text-neutral-900 dark:hover:bg-neutral-600 dark:hover:text-white"
                    type="button"
                    onClick={() => setShowModal(false)}>
                    <RxCross2 size="1.5em" />
                  </button>
                </div>
                {/*body*/}
                {children}
                {/*footer*/}
                {/* <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b space-x-2 dark:border-neutral-600">

                </div> */}
              </div>
            </div>
          </div>
          {/* <div
            className="opacity-50 fixed inset-0 z-40 bg-black"
          ></div> */}
        </>
      ) : null}
    </div>
  )
}

export default ModalCustom
