import {
  Accordion,
  Alert,
  Button,
  Checkbox,
  Label,
  Modal,
  Tabs,
  TextInput,
  Textarea,
} from "flowbite-react"
import { useEffect, useRef, useState } from "react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import AdminSidebar from "../../components/sidebar/AdminSidebar"
import { AdminLabSchema, AdminFlagSchema, EditLabRequest } from "../../api"
import useAuthedApi from "../../hooks/useAuthedApi"
import useToasts from "../../hooks/useToasts"
import { useNavigate, useParams } from "react-router-dom"
import { isAxiosError } from "axios"
import { MdTitle } from "react-icons/md"
import { HiOutlineExclamationCircle } from "react-icons/hi"
import { FaHashtag } from "react-icons/fa"
import { BsCursorText } from "react-icons/bs"
import {
  BiBarcodeReader,
  BiDollarCircle,
  BiSolidDashboard,
  BiSolidOffer,
} from "react-icons/bi"
import { VscJson } from "react-icons/vsc"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"

const AdminLab = () => {
  const [lab, setLab] = useState<AdminLabSchema>()
  const { AdminAuthedApi } = useAuthedApi()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const { toastList, setToastList } = useToasts()
  const { slug } = useParams()
  const navigate = useNavigate()
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [hidden, setHidden] = useState(false)
  const [prereleased, setPrereleased] = useState(false)
  const [promotion, setPromotion] = useState<number | undefined>()
  const [promotionCode, setPromotionCode] = useState<string | undefined>()
  const [price15, setPrice15] = useState(0)
  const [price30, setPrice30] = useState(0)
  const [price90, setPrice90] = useState(0)
  const [flags, setFlags] = useState<AdminFlagSchema[]>([])
  const [flagsJSON, setFlagsJSON] = useState("")
  const [showEditModal, setShowEditModal] = useState(false)
  const [editErrMsg, setEditErrMsg] = useState("")
  const editErrRef = useRef<HTMLInputElement>(null)
  const [showEditFlagsModal, setShowEditFlagsModal] = useState(false)
  const [editFlagsErrMsg, setEditFlagsErrMsg] = useState("")
  const editFlagsErrRef = useRef<HTMLInputElement>(null)
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(
    window.innerWidth < 640
  )

  const getLab = async (slug: string) => {
    try {
      const response = await AdminAuthedApi.adminReadLab(slug)
      if (!response.data) {
        setErrMsg("Get labs failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setLab(response.data)
      setTitle(response.data.title)
      setDescription(response.data.description)
      setHidden(response.data.hidden)
      setPrereleased(response.data.prereleased)
      setPromotion(
        response.data.promotion == null ? undefined : response.data.promotion
      )
      setPromotionCode(
        response.data.promotion_code == null
          ? undefined
          : response.data.promotion_code
      )
      setPrice15(response.data.price15)
      setPrice30(response.data.price30)
      setPrice90(response.data.price90)
      setFlags(response.data.flags)
      setFlagsJSON(JSON.stringify(response.data.flags, null, 2))
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get flags failed with unknown error")
        }
      } else {
        setErrMsg("Get flags failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  useEffect(() => {
    if (!slug) {
      navigate("/admin/labs")
      return
    }
    getLab(slug)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const editLab = async (lab: AdminLabSchema | undefined) => {
    if (!lab) {
      setEditErrMsg(`No lab to edit`)
      return
    }

    try {
      let payload: EditLabRequest = {
        title: title,
        description: description,
        hidden: hidden,
        price15: price15,
        price30: price30,
        price90: price90,
        prereleased: prereleased,
        promotion: promotion,
        promotion_code: promotionCode,
        platform: lab.platform, //TODO: make it possible to change the platform
        course: lab.course, //TODO: make it possible to edit the course
      }
      const response = await AdminAuthedApi.adminEditLab(lab.slug, payload)
      if (!response.data) {
        setEditErrMsg(`Edit lab ${lab.slug} failed with unknown error`)
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success) {
        setEditErrMsg(`No labs were edited`)
        return
      }

      setToastList(
        toastList.concat({
          type: "success",
          children: `Lab ${lab.title} was edited successfully!`,
        })
      )

      setLab(response.data.lab)

      setShowEditModal(false)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setEditErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setEditErrMsg("Data validation error")
        } else {
          setEditErrMsg(`Edit lab ${lab.slug} failed with unknown error`)
        }
      } else {
        setEditErrMsg(`Edit lab ${lab.slug} failed with unknown error`)
      }
      editErrRef.current?.focus()
    }
  }

  const editFlags = async (json_string: string | undefined) => {
    if (!lab) {
      setEditErrMsg(`No lab to edit`)
      return
    }

    if (!json_string) {
      setEditFlagsErrMsg(`No flags json to edit`)
      return
    }

    try {
      let payload: AdminFlagSchema[] = JSON.parse(flagsJSON)
      const response = await AdminAuthedApi.adminEditFlagsJson(
        lab.slug,
        payload
      )
      if (!response.data) {
        setEditErrMsg(`Edit lab ${lab.slug} flags failed with unknown error`)
        return
      }
      // console.log(JSON.stringify(response?.data))

      setToastList(
        toastList.concat({
          type: "success",
          children: `Lab ${lab.title} flags were edited successfully!`,
        })
      )

      setFlags(response.data)
      setFlagsJSON(JSON.stringify(response.data, null, 2))

      setShowEditFlagsModal(false)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setEditFlagsErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setEditFlagsErrMsg("Data validation error")
        } else {
          setEditFlagsErrMsg(
            `Edit lab ${lab.slug} flags failed with unknown error`
          )
        }
      } else {
        setEditFlagsErrMsg(
          `Edit lab ${lab.slug} flags failed with unknown error`
        )
      }
      editFlagsErrRef.current?.focus()
    }
  }

  return (
    <>
      <div className="fixed top-0 inset-x-0 z-40 flex justify-between items-center">
        <NavbarPrivate />
      </div>

      <AdminSidebar
        onCollapseChange={(collapsed) => {
          setSidebarCollapsed(collapsed)
        }}
      />
      <main
        className={
          sidebarCollapsed
            ? "pt-20 pl-0 transition-all ease-in-out duration-300"
            : "pt-20 sm:pl-64 lg:pl-96 transition-all ease-in-out duration-300"
        }
      >
        <div className="container mx-auto">
          <div className="p-5">
            <section id="labs">
              {lab ? (
                <>
                  <h1 className="text-left text-2xl md:text-4xl font-bold text-gray-800 dark:text-white mb-5 mx-3 md:mx-0">
                    {lab.title}
                  </h1>
                  <div className="mb-2 block">
                    <Label htmlFor="id" value="ID" />
                  </div>
                  <TextInput
                    className="mb-2"
                    disabled
                    icon={FaHashtag}
                    readOnly
                    id="id"
                    placeholder="ID"
                    required
                    type="text"
                    value={lab.id}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="slug" value="Slug" />
                  </div>
                  <TextInput
                    className="mb-2"
                    disabled
                    icon={BsCursorText}
                    readOnly
                    id="slug"
                    placeholder="Slug"
                    required
                    type="text"
                    value={lab.slug}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="title" value="Title" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={MdTitle}
                    id="title"
                    placeholder="Title"
                    required
                    type="text"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value)
                    }}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="description" value="Description" />
                  </div>
                  <Textarea
                    className="mb-2"
                    id="description"
                    placeholder="Description"
                    required
                    value={description}
                    rows={5}
                    onChange={(e) => {
                      setDescription(e.target.value)
                    }}
                  />
                  <div className="flex items-center gap-2">
                    <Checkbox
                      id="hidden"
                      checked={hidden}
                      onChange={(e) => {
                        setHidden(e.target.checked)
                      }}
                    />
                    <Label htmlFor="hidden">Hidden</Label>
                  </div>
                  <div className="flex items-center gap-2">
                    <Checkbox
                      id="prereleased"
                      checked={prereleased}
                      onChange={(e) => {
                        setPrereleased(e.target.checked)
                      }}
                    />
                    <Label htmlFor="prereleased">Pre-released</Label>
                  </div>
                  <div className="mb-2 block">
                    <Label htmlFor="promotion" value="Promotion" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={BiSolidOffer}
                    id="promotion"
                    placeholder="Promotion"
                    required
                    type="text"
                    value={promotion}
                    onChange={(e) => {
                      setPromotion(parseInt(e.target.value))
                    }}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="promotion_code" value="Promotion Code" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={BiBarcodeReader}
                    id="promotion_code"
                    placeholder="Promotion Code"
                    required
                    type="text"
                    value={promotionCode}
                    onChange={(e) => {
                      setPromotionCode(e.target.value)
                    }}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="price15" value="Price 15 days" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={BiDollarCircle}
                    id="price15"
                    placeholder="Price 15 days"
                    required
                    type="text"
                    value={price15}
                    onChange={(e) => {
                      setPrice15(parseInt(e.target.value))
                    }}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="price30" value="Price 30 days" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={BiDollarCircle}
                    id="price30"
                    placeholder="Price 30 days"
                    required
                    type="text"
                    value={price30}
                    onChange={(e) => {
                      setPrice30(parseInt(e.target.value))
                    }}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="price90" value="Price 90 days" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={BiDollarCircle}
                    id="price90"
                    placeholder="Price 90 days"
                    required
                    type="text"
                    value={price90}
                    onChange={(e) => {
                      setPrice90(parseInt(e.target.value))
                    }}
                  />
                  <div className="flex flex-row-reverse">
                    <Button
                      color="warning"
                      onClick={() => {
                        setShowEditModal(true)
                      }}
                    >
                      Edit lab
                    </Button>
                  </div>
                  <Modal
                    size="md"
                    dismissible={true}
                    popup={true}
                    show={showEditModal}
                    onClose={() => {
                      setShowEditModal(false)
                    }}
                  >
                    <Modal.Header />
                    <Modal.Body>
                      <div className="text-center">
                        <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                          Are you sure you want to edit the lab {lab?.slug}?
                        </h3>
                        <div className={!editErrMsg ? "sr-only" : ""}>
                          <Alert
                            color="failure"
                            icon={ExclamationTriangleIcon}
                            onDismiss={function onDismiss() {
                              setEditErrMsg("")
                            }}
                          >
                            <span ref={editErrRef}>{editErrMsg}</span>
                          </Alert>
                        </div>
                        <div className="flex justify-center gap-4">
                          <Button color="failure" onClick={() => editLab(lab)}>
                            Yes, I'm sure
                          </Button>
                          <Button
                            color="gray"
                            onClick={() => setShowEditModal(false)}
                          >
                            No, cancel
                          </Button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>

                  <h3 className="text-left text-lg md:text-xl font-bold text-gray-800 dark:text-white mt-5 mb-5 mx-3 md:mx-0">
                    Flags
                  </h3>
                  <Tabs aria-label="Flag tabs">
                    <Tabs.Item active icon={BiSolidDashboard} title="UI">
                      <>
                        <Accordion collapseAll>
                          {flags.map((flag) => (
                            <Accordion.Panel key={flag.id}>
                              <Accordion.Title>{flag.title}</Accordion.Title>
                              <Accordion.Content>
                                <div className="flex text-gray-800 dark:text-white text-sm">
                                  <>ID: </>
                                  <>{flag.id}</>
                                </div>
                                <div className="flex text-gray-800 dark:text-white text-sm">
                                  <>Index: </>
                                  <>{flag.index}</>
                                </div>
                                <div className="flex text-gray-800 dark:text-white text-sm">
                                  <>Title: </>
                                  <>{flag.title}</>
                                </div>
                                <div className="flex text-gray-800 dark:text-white text-sm">
                                  <>Description: </>
                                  <>{flag.description}</>
                                </div>
                                <div className="flex text-gray-800 dark:text-white text-sm">
                                  <>Value: </>
                                  <>{flag.value}</>
                                </div>
                              </Accordion.Content>
                            </Accordion.Panel>
                          ))}
                        </Accordion>
                      </>
                    </Tabs.Item>
                    <Tabs.Item icon={VscJson} title="JSON">
                      <div className="mb-2 block">
                        <Label htmlFor="flagsJSON" value="Flags JSON" />
                      </div>
                      <Textarea
                        className="mb-2"
                        id="flagsJSON"
                        placeholder="[]"
                        required
                        value={flagsJSON}
                        rows={15}
                        onChange={(e) => {
                          setFlagsJSON(e.target.value)
                        }}
                      />
                      <div className="flex flex-row-reverse">
                        <Button
                          color="warning"
                          onClick={() => {
                            setShowEditFlagsModal(true)
                          }}
                        >
                          Edit flags
                        </Button>
                      </div>
                      <Modal
                        size="md"
                        dismissible={true}
                        popup={true}
                        show={showEditFlagsModal}
                        onClose={() => {
                          setShowEditFlagsModal(false)
                        }}
                      >
                        <Modal.Header />
                        <Modal.Body>
                          <div className="text-center">
                            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                              Are you sure you want to edit the flags of lab{" "}
                              {lab?.slug}?
                            </h3>
                            <div className={!editFlagsErrMsg ? "sr-only" : ""}>
                              <Alert
                                color="failure"
                                icon={ExclamationTriangleIcon}
                                onDismiss={function onDismiss() {
                                  setEditFlagsErrMsg("")
                                }}
                              >
                                <span ref={editFlagsErrRef}>
                                  {editFlagsErrMsg}
                                </span>
                              </Alert>
                            </div>
                            <div className="flex justify-center gap-4">
                              <Button
                                color="failure"
                                onClick={() => editFlags(flagsJSON)}
                              >
                                Yes, I'm sure
                              </Button>
                              <Button
                                color="gray"
                                onClick={() => setShowEditFlagsModal(false)}
                              >
                                No, cancel
                              </Button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </Tabs.Item>
                  </Tabs>
                </>
              ) : (
                <Alert color="warning" icon={ExclamationTriangleIcon}>
                  <span>No lab to display</span>
                </Alert>
              )}

              <div className={!errMsg ? "sr-only" : ""}>
                <Alert
                  color="failure"
                  icon={ExclamationTriangleIcon}
                  onDismiss={function onDismiss() {
                    setErrMsg("")
                  }}
                >
                  <span ref={errRef}>{errMsg}</span>
                </Alert>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  )
}

export default AdminLab
