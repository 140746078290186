import { ToastProps } from "../components/Toast"
import { createContext, PropsWithChildren, useState } from "react"

export type ToastListData = ToastProps[]

interface ToastsContextProps {
  toastList: ToastListData
  setToastList: (toastList: ToastListData) => void
}

const ToastsContext = createContext<ToastsContextProps>({
  toastList: [],
  setToastList: () => {},
})

export const ToastsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [toastList, setToastList] = useState<ToastListData>([])
  return (
    <ToastsContext.Provider value={{ toastList, setToastList }}>
      {children}
    </ToastsContext.Provider>
  )
}

export default ToastsContext
