import { Alert, Button, Label, TextInput } from "flowbite-react"
import { FC, PropsWithChildren, useEffect, useState, MouseEvent } from "react"
import { AdminCertificateSchema } from "../../api"
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import { RxCross2 } from "react-icons/rx"

export type ModalProps = PropsWithChildren<{
  certificateToEdit: AdminCertificateSchema
  setCertificateToEdit: (
    certificate: AdminCertificateSchema | undefined
  ) => void
  showEditModal: boolean
  setShowEditModal: (show: boolean) => void
  editCertificate: (certificate: AdminCertificateSchema | undefined) => void
  editErrMsg: string
  setEditErrMsg: (msg: string) => void
  editErrRef: React.RefObject<HTMLInputElement>
}>

const FULLNAME_REGEX = /^[a-zA-Z0-9-_\s]{1,100}$/

const AdminEditCertificate: FC<ModalProps> = ({
  certificateToEdit,
  setCertificateToEdit,
  showEditModal,
  setShowEditModal,
  editCertificate,
  editErrMsg,
  setEditErrMsg,
  editErrRef,
}) => {
  const [fullName, setFullName] = useState(certificateToEdit.full_name)
  const [validFullName, setValidFullName] = useState(false)
  const [fullNameFocus, setFullNameFocus] = useState(false)

  useEffect(() => {
    setValidFullName(FULLNAME_REGEX.test(fullName || ""))
  }, [fullName])

  useEffect(() => {
    setEditErrMsg("")

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullName])

  return (
    <>
      {showEditModal ? (
        <>
          <div
            onClick={(e: MouseEvent<HTMLDivElement>) => {
              if (e.target === e.currentTarget) {
                setShowEditModal(false)
                setCertificateToEdit(undefined)
              }
            }}
            className="fixed top-0 right-0 left-0 z-50 h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80"
          >
            <div className="relative h-full w-full p-4 md:h-auto max-w-5xl">
              {/*content*/}
              <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
                {/*header*/}
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                    Edit certificate {certificateToEdit.id}
                  </h3>

                  <button
                    aria-label="Close"
                    className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                    type="button"
                    onClick={() => {
                      setShowEditModal(false)
                      setCertificateToEdit(undefined)
                    }}
                  >
                    <RxCross2 size="1.5em" />
                  </button>
                </div>
                {/*body*/}

                <div className="p-3 md:p-5">
                  <div className={!editErrMsg ? "sr-only" : ""}>
                    <Alert
                      color="failure"
                      icon={ExclamationTriangleIcon}
                      onDismiss={function onDismiss() {
                        setEditErrMsg("")
                      }}
                    >
                      <span ref={editErrRef}>{editErrMsg}</span>
                    </Alert>
                  </div>
                  <form
                    className="flex flex-col gap-4"
                    onSubmit={(e: React.FormEvent) => {
                      e.preventDefault()
                      editCertificate({
                        ...certificateToEdit,
                        full_name: fullName,
                      })
                    }}
                  >
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="fullName" value="Full Name" />
                      </div>
                      <TextInput
                        id="fullName"
                        type="text"
                        placeholder="FullName"
                        autoComplete="fullName"
                        required={true}
                        onChange={(e) => {
                          setFullName(e.target.value)
                        }}
                        onFocus={() => setFullNameFocus(true)}
                        onBlur={() => setFullNameFocus(false)}
                        value={fullName}
                        aria-invalid={validFullName ? "false" : "true"}
                        color={
                          validFullName ? "success" : !fullName ? "" : "failure"
                        }
                        helperText={
                          <>
                            <span
                              className={
                                fullNameFocus && fullName && !validFullName
                                  ? ""
                                  : "sr-only"
                              }
                            >
                              <ExclamationCircleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                              Invalid full name:
                              <br />
                              Must at least one character
                            </span>
                          </>
                        }
                      />
                    </div>

                    <div className="flex justify-center gap-4">
                      <Button
                        disabled={!validFullName ? true : false}
                        type="submit"
                      >
                        Submit changes
                      </Button>
                      <Button
                        color="gray"
                        onClick={() => setShowEditModal(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </div>

                {/*footer*/}
                {/* <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b space-x-2 dark:border-gray-600">

                </div> */}
              </div>
            </div>
          </div>
          {/* <div
            className="opacity-50 fixed inset-0 z-40 bg-black"
          ></div> */}
        </>
      ) : null}
    </>
  )
}

export default AdminEditCertificate
