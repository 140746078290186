import { Alert, Button, Checkbox, Modal, Tooltip } from "flowbite-react"
import { useEffect, useMemo, useRef, useState } from "react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import AdminSidebar from "../../components/sidebar/AdminSidebar"
import { ColumnDef } from "@tanstack/react-table"
import { AdminCourseSchema } from "../../api"
import useAuthedApi from "../../hooks/useAuthedApi"
import useToasts from "../../hooks/useToasts"
import { useNavigate } from "react-router-dom"
import { isAxiosError } from "axios"
import AdminTable from "../../components/tables/AdminTable"
import { MdDelete } from "react-icons/md"
import { HiOutlineExclamationCircle } from "react-icons/hi"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"

const AdminCourses = () => {
  const [courses, setCourses] = useState<AdminCourseSchema[]>()
  const { AdminAuthedApi } = useAuthedApi()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [courseToDelete, setCourseToDelete] = useState<AdminCourseSchema>()
  const [deleteErrMsg, setDeleteErrMsg] = useState("")
  const deleteErrRef = useRef<HTMLInputElement>(null)
  const { toastList, setToastList } = useToasts()
  const navigate = useNavigate()
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(
    window.innerWidth < 640
  )

  const getCourses = async () => {
    try {
      const response = await AdminAuthedApi.adminReadCourses()
      if (!response.data) {
        setErrMsg("Get courses failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setCourses(response.data)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get courses failed with unknown error")
        }
      } else {
        setErrMsg("Get courses failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  const deleteCourse = async (course: AdminCourseSchema | undefined) => {
    if (!course) {
      setDeleteErrMsg(`No course to delete`)
      return
    }
    if (!courses) {
      setDeleteErrMsg(`No courses`)
      return
    }
    try {
      const response = await AdminAuthedApi.adminDeleteCourse(course.slug)
      if (!response.data) {
        setDeleteErrMsg(
          `Delete course ${course.slug} failed with unknown error`
        )
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success) {
        setDeleteErrMsg(`No courses were deleted`)
        return
      }

      setToastList(
        toastList.concat({
          type: "success",
          children: `Course ${course.title} was deleted successfully!`,
        })
      )

      const index = courses.indexOf(course)
      let clone = courses
      clone.splice(index, 1)
      setCourses(clone)

      setShowDeleteModal(false)
      setCourseToDelete(undefined)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setDeleteErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setDeleteErrMsg("Data validation error")
        } else {
          setDeleteErrMsg(
            `Delete course ${course.slug} failed with unknown error`
          )
        }
      } else {
        setDeleteErrMsg(
          `Delete course ${course.slug} failed with unknown error`
        )
      }
      deleteErrRef.current?.focus()
    }
  }

  useEffect(() => {
    getCourses()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo<ColumnDef<AdminCourseSchema>[]>(
    () => [
      {
        header: "COURSE ID",
        footer: (props) => props.column.id,
        accessorKey: "id",
        cell: (props) => (
          <div
            onClick={() => {
              navigate(`/admin/courses/${props.row.original.slug}`)
            }}
            className="hover:underline hover:cursor-pointer font-medium text-gray-900 dark:text-white"
          >
            {props.row.original.id}
          </div>
        ),
      },
      {
        header: "TITLE",
        footer: (props) => props.column.id,
        accessorKey: "title",
        cell: (props) => props.getValue(),
      },
      {
        header: "IS HIDDEN",
        footer: (props) => props.column.id,
        accessorKey: "hidden",
        cell: (props) => (
          <Checkbox checked={props.getValue() ? true : false} disabled={true} />
        ),
        enableColumnFilter: false,
      },
      {
        id: "actions",
        footer: (props) => props.column.id,
        cell: (props) => (
          <div className="flex flex-row gap-1">
            <Tooltip content="Delete course">
              <button
                onClick={() => {
                  setShowDeleteModal(true)
                  setCourseToDelete(props.row.original)
                }}
                className="font-medium text-center text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
              >
                <MdDelete size={20} />
              </button>
            </Tooltip>
          </div>
        ),
      },
    ],
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [courses]
  )

  return (
    <>
      <div className="fixed top-0 inset-x-0 z-40 flex justify-between items-center">
        <NavbarPrivate />
      </div>

      <AdminSidebar
        onCollapseChange={(collapsed) => {
          setSidebarCollapsed(collapsed)
        }}
      />
      <main
        className={
          sidebarCollapsed
            ? "pt-20 pl-0 transition-all ease-in-out duration-300"
            : "pt-20 sm:pl-64 lg:pl-96 transition-all ease-in-out duration-300"
        }
      >
        <div className="container mx-auto">
          <div className="p-5">
            <section id="courses">
              <h1 className="text-left text-2xl md:text-4xl font-bold text-gray-800 dark:text-white mb-5 mx-3 md:mx-0">
                COURSES
              </h1>
              <div className={!errMsg ? "sr-only" : ""}>
                <Alert
                  color="failure"
                  icon={ExclamationTriangleIcon}
                  onDismiss={function onDismiss() {
                    setErrMsg("")
                  }}
                >
                  <span ref={errRef}>{errMsg}</span>
                </Alert>
              </div>
              <div className="mb-10">
                {courses?.length ? (
                  <AdminTable columns={columns} data={courses} />
                ) : (
                  <Alert color="warning" icon={ExclamationTriangleIcon}>
                    <span>No courses to display</span>
                  </Alert>
                )}
              </div>
            </section>
          </div>
        </div>
      </main>
      <Modal
        size="md"
        dismissible={true}
        popup={true}
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false)
          setCourseToDelete(undefined)
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete the course {courseToDelete?.title}
              ?
            </h3>
            <div className={!deleteErrMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setDeleteErrMsg("")
                }}
              >
                <span ref={deleteErrRef}>{deleteErrMsg}</span>
              </Alert>
            </div>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={() => deleteCourse(courseToDelete)}
              >
                Yes, I'm sure
              </Button>
              <Button color="gray" onClick={() => setShowDeleteModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminCourses
