import Footer from "../../components/Footer"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"
import { PublicCertificateSchema } from "../../api"
import { useState, useEffect, useRef } from "react"
import { isAxiosError } from "axios"
import { useNavigate, useParams } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import NavbarPublic from "../../components/navbar/NavbarPublic"
import { Alert } from "flowbite-react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import useApi from "../../hooks/useApi"

const Certificates = () => {
  const [certificate, setCertificate] = useState<PublicCertificateSchema>()
  const { CertificatesApi } = useApi()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const { id } = useParams()
  const navigate = useNavigate()
  const { auth } = useAuth()

  const getCertificate = async (id: string) => {
    try {
      const response = await CertificatesApi.certificatesReadCertificatesById(
        id
      )
      if (!response.data) {
        setErrMsg("Get certificate failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setCertificate(response.data)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get certificate failed with unknown error")
        }
      } else {
        setErrMsg("Get certificate failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  useEffect(() => {
    if (!id) {
      navigate("/certificates")
      return
    }
    getCertificate(id)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className="min-h-screen flex flex-col justify-between">
      {auth ? <NavbarPrivate /> : <NavbarPublic />}
      <div className="mb-auto">
        <div className="container mx-auto">
          <section id="certificate">
            <div className={!errMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setErrMsg("")
                }}
              >
                <span ref={errRef}>{errMsg}</span>
              </Alert>
            </div>
            {certificate !== undefined ? (
              <div className="text-neutral-900 dark:text-white text-center">
                <div className="mt-16">This document acknowledges</div>
                <div className="mt-4 text-xl font-medium">
                  {certificate?.user.first_name} {certificate?.user.last_name}
                </div>
                <div className="mt-2 border-b border-white w-2/5 mx-auto"></div>
                <div className="mt-3">is certified as an</div>
                <div className="mt-3 text-red-logo text-5xl font-bold">
                  {certificate?.course.slug.toUpperCase()}
                </div>
                <div className="mt-2">({certificate?.course.title})</div>
                <div className="mt-10">
                  And successfully completed the course and hands-on examination
                  administered by Hacktricks Training
                </div>
                <div className="mt-4">This certificate was issued on</div>
                <div className="mt-4">
                  {new Date(certificate?.awarded_at).toLocaleDateString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      timeZone: "utc",
                    }
                  )}
                </div>
                <div className="mt-2 border-b border-white w-1/5 mx-auto"></div>
                <div className="mt-4">
                  <p>Verification ID: {certificate?.id}</p>
                </div>
              </div>
            ) : (
              <div
                className="text-neutral-900 dark:text-white
              "
              >
                No certificate to display
              </div>
            )}
          </section>
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Certificates
