import { Children, FC, ReactNode, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export type CarouselProps = {
  slides: {
    image: ReactNode
    text: ReactNode
  }[]
  radius: number
  x_skew: number
  y_skew: number
  z_skew: number
  scale_skew: number
  scroll_speed: number
}

const CustomCarousel: FC<CarouselProps> = ({
  slides,
  radius,
  x_skew,
  y_skew,
  z_skew,
  scale_skew,
  scroll_speed,
}) => {
  let [current, setCurrent] = useState(0)
  let [slideWidth, setSlideWidth] = useState(0)
  let [slideHeight, setSlideHeight] = useState(0)
  let navigate = useNavigate()

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(
        (((current - 1) % slides.length) + slides.length) % slides.length
      )
    }, scroll_speed * 1000)

    return () => {
      clearInterval(interval)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current])

  return (
    <div
      className="relative "
      style={{ width: `${3 * slideWidth}px`, height: `${3 * slideHeight}px` }}
    >
      {slides.map((slide, index) => {
        const position =
          (((index - current) % slides.length) + slides.length) % slides.length
        let angle = (-360 / slides.length) * position
        const toRadians = (angle: number) => {
          return (angle * Math.PI) / 180
        }
        const scale = (
          value: number,
          in_min: number,
          in_max: number,
          out_min: number,
          out_max: number
        ) => {
          return (
            ((value - in_min) * (out_max - out_min)) / (in_max - in_min) +
            out_min
          )
        }

        let y =
          ((radius * Math.round(Math.cos(toRadians(angle)) * 100)) / 100) *
            y_skew +
          50 * Math.sign(Math.round(Math.cos(toRadians(angle)) * 100) / 100)
        let x =
          ((radius * Math.round(Math.sin(toRadians(angle)) * 100)) / 100) *
            x_skew +
          y *
            z_skew *
            Math.sign(Math.round(Math.sin(toRadians(angle)) * 100) / 100)

        let x_offset = slideWidth
        let y_offset = slideHeight

        let scale_value = scale(
          y,
          radius * Math.cos(toRadians(0)) * y_skew,
          radius * Math.cos(toRadians(180)) * y_skew,
          1,
          scale_skew
        )
        return (
          <div
            key={index}
            onClick={() => {
              if (position !== 0) {
                setCurrent(index)
                return
              }
              if (
                (
                  (Children.toArray(slide.text)[0] as any).props.to &&
                  (Children.toArray(slide.text)[0] as any)
                ).props.to !== "#"
              ) {
                navigate((Children.toArray(slide.text)[0] as any).props.to)
                return
              }
            }}
            className={
              "absolute duration-500 origin-center transform-gpu" +
              (position === 0 ? " cursor-pointer" : "")
            }
            style={{
              transform: `translateX(${x + x_offset}px) translateY(${
                y + y_offset
              }px) scale(${scale_value})`,
            }}
          >
            <div
              className={`w-max`}
              onLoad={(e) => {
                if (e.currentTarget.offsetWidth > slideWidth) {
                  setSlideWidth(e.currentTarget.offsetWidth)
                }
                if (e.currentTarget.offsetHeight > slideHeight) {
                  setSlideHeight(e.currentTarget.offsetHeight)
                }
              }}
            >
              {slide.image}
              {slides.length - 1 === position ||
              position === 0 ||
              position === 1 ? (
                <div
                  className={
                    (position !== 0 ? "max-md:hidden max-lg:scale-75" : "") +
                    " absolute w-60 left-1/2 -translate-x-1/2"
                  }
                >
                  {slide.text}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CustomCarousel
