import { Toast } from "./Toast"
import useToasts, { ToastListData } from "../hooks/useToasts"
import { useEffect, useState } from "react"

const ToastContainer = () => {
  const { toastList } = useToasts()
  const [toasts, setToasts] = useState<ToastListData>([])

  useEffect(() => {
    setToasts(toastList)
  }, [toastList])

  const toastsMap = toasts.map((toast, index) => {
    return (
      <Toast key={index} type={toast.type} link={toast.link}>
        {toast.children}
      </Toast>
    )
  })
  return (
    <div className="fixed flex flex-col gap-4 w-full max-w-xs top-5 right-5 z-50">
      {toastsMap}
    </div>
  )
}

export default ToastContainer
