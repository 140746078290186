import { Table } from "flowbite-react"

const ARTAARTEComparison = () => {
  return (
    <div className="md:w-3/5 mx-auto p-3 mb-12">
      <div className="overflow-x-auto">
        <Table
          theme={{
            root: {
              base: "w-full text-left text-sm text-neutral-500 dark:text-neutral-400",
              shadow:
                "absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-white drop-shadow-md dark:bg-black",
              wrapper: "relative",
            },
            body: {
              base: "group/body",
              cell: {
                base: "px-6 py-4 group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg",
              },
            },
            head: {
              base: "group/head text-lg uppercase text-neutral-700 dark:text-neutral-400",
              cell: {
                base: "bg-neutral-50 px-6 py-3 group-first/head:first:rounded-tl-lg group-first/head:last:rounded-tr-lg dark:bg-neutral-700",
              },
            },
            row: {
              base: "group/row",
              hovered: "hover:bg-neutral-50 dark:hover:bg-neutral-600",
              striped:
                "odd:bg-white even:bg-neutral-50 odd:dark:bg-neutral-900 even:dark:bg-neutral-700",
            },
          }}>
          <Table.Head>
            <Table.HeadCell></Table.HeadCell>
            <Table.HeadCell>ARTA</Table.HeadCell>
            <Table.HeadCell>ARTE</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y bg-white dark:border-neutral-700 dark:bg-neutral-900 border">
            <Table.Row className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                <strong>Certification Level</strong>
              </Table.Cell>
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                Beginner to Intermediate
              </Table.Cell>
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                Beginner to Expert (covers ARTA topics as well)
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                <strong>Video Lessons</strong>
              </Table.Cell>
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                8+ hours
              </Table.Cell>
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                20+ hours
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                <strong>Estimated Completion Time</strong>
              </Table.Cell>
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                25+ hours
              </Table.Cell>
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                70+ hours
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                <strong>Hands-On Labs</strong>
              </Table.Cell>
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                20+ labs
              </Table.Cell>
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                50+ labs
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                <strong>Exam</strong>
              </Table.Cell>
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                Certificate of completion (no exam)
              </Table.Cell>
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                Requires passing an exam
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                <strong>Included Lab Duration</strong>
              </Table.Cell>
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                30 lab days
              </Table.Cell>
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                60 lab days
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
              <Table.Cell rowSpan={3}>
                <strong>Services to Learn (Syllabus)</strong>
              </Table.Cell>
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                IAM (2 labs), STS (2 labs), KMS (1 labs), Secrets Manager (2
                labs), S3 (2 labs), EC2 & VPC (3 labs), Lambda (2 labs), RDS (1
                lab), SQS (2 labs), SNS (1 lab)
              </Table.Cell>
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                IAM (3 labs), STS (4 labs), KMS (3 labs), Secrets Manager (2
                labs), S3 (4 labs), EC2 & VPC (5 labs), LightSail (2 labs),
                Lambda (3 labs), API Gateway (1 lab), EFS (1 lab), RDS (3 labs),
                DynamoDB (1 lab), ECR (2 labs), ECS (2 labs), Elastic Beanstalk
                (1 lab), CodeBuild (4 labs), SQS (2 labs), SNS (2 labs), Step
                Functions (2 labs)
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                Methodologies: WhiteBox (1 lab)
              </Table.Cell>
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                Methodologies: WhiteBox (1 lab), BlackBox 1, BlackBox 2 (3 labs)
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                Detection Systems: CloudTrail (1 lab)
              </Table.Cell>
              <Table.Cell className="bg-white dark:border-neutral-700 dark:bg-neutral-900 divide-x border">
                Detection Systems: CloudTrail (1 lab), Guarduty (1 lab), Other
                Security services (CloudWatch, Security Hub, and more)
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </div>
  )
}

export default ARTAARTEComparison
