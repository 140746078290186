import { Blockquote, Carousel, Rating } from "flowbite-react"
import { FC } from "react"

export type Testimonial = {
  author: string
  quote: string
  rating: number
}

export type TestimonialsProps = {
  testimonials: Array<Testimonial>
}

const TestimonialCarousel: FC<TestimonialsProps> = ({ testimonials }) => {
  return (
    <div className="h-56 sm:h-64 xl:h-80 2xl:h-96 w-full">
      <Carousel
        pauseOnHover
        slideInterval={5000}
        theme={{
          control: {
            base: "inline-flex h-8 w-8 items-center justify-center rounded-full dark:bg-white/30 dark:group-hover:bg-white/50 group-focus:outline-none group-focus:ring-4 dark:group-focus:ring-white bg-neutral-400/30 group-hover:bg-neutral-400/60 group-focus:ring-neutral-400/70 sm:h-10 sm:w-10",
            icon: "h-5 w-5 dark:text-white text-neutral-600 sm:h-6 sm:w-6",
          },
          indicators: {
            active: {
              off: "dark:bg-white/50 dark:hover:bg-white bg-gray-400/50 hover:bg-gray-600",
              on: "dark:bg-white bg-gray-600",
            },
            base: "h-3 w-3 rounded-full",
            wrapper:
              "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3",
          },
        }}
      >
        {testimonials ? (
          testimonials.map((testimonial: Testimonial) => (
            <div className="flex h-full items-center justify-center bg-neutral-200 dark:bg-neutral-700 dark:text-white">
              <figure className="mx-auto max-w-screen-md text-center">
                <Blockquote>
                  <p className="text-xl font-medium italic text-gray-900 dark:text-white">
                    "{testimonial.quote}"
                  </p>
                </Blockquote>
                <figcaption className="mt-6 flex items-center justify-center space-x-3">
                  <div className="flex items-center divide-x-2 dark:divide-gray-500 divide-gray-700">
                    <Rating className="pr-3">
                      <Rating.Star />
                      <p className="ml-2 text-sm font-bold text-gray-900 dark:text-white">
                        {testimonial.rating}
                      </p>
                    </Rating>
                    <cite className="pl-3 font-medium text-gray-900 dark:text-white">
                      {testimonial.author}
                    </cite>
                  </div>
                </figcaption>
              </figure>
            </div>
          ))
        ) : (
          <></>
        )}
      </Carousel>
    </div>
  )
}

export default TestimonialCarousel
