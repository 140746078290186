import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import "./github-markdown.css"
import App from "./App"
import { AuthProvider } from "./context/AuthProvider"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { ToastsProvider } from "./context/ToastsProvider"
import ToastContainer from "./components/ToastContainer"
import { ThemeProvider } from "./context/ThemeProvider"
import { BannersProvider } from "./context/BannersProvider"
import BannerContainer from "./components/BannerContainer"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <div className="bg-neutral-100 dark:bg-neutral-800 font-sans">
    <React.StrictMode>
      <ThemeProvider>
        <BrowserRouter>
          <BannersProvider>
            <BannerContainer />
            <ToastsProvider>
              <ToastContainer />
              <AuthProvider>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </AuthProvider>
            </ToastsProvider>
          </BannersProvider>
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  </div>
)
