import { Spinner } from "flowbite-react"

const Loading = () => {
  return (
    <div className="fixed top-0 left-0 z-50 w-full h-full bg-black bg-opacity-60 text-center">
      <div className="relative top-1/2 -translate-y-1/2">
        <Spinner size="xl" color="failure" />
      </div>
    </div>
  )
}

export default Loading
