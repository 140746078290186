import Footer from "../../components/Footer"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"
import { PublicCertificateSchema } from "../../api"
import useAuthedApi from "../../hooks/useAuthedApi"
import { useState, useEffect, useRef, useMemo } from "react"
import { isAxiosError } from "axios"
import { Alert, Button, Modal, Spinner, Tooltip } from "flowbite-react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import { TbMailForward } from "react-icons/tb"
import useToasts from "../../hooks/useToasts"
import BasicTable from "../../components/tables/BasicTable"
import { ColumnDef } from "@tanstack/react-table"
import { HiOutlineExclamationCircle } from "react-icons/hi"
import { useNavigate } from "react-router-dom"
import Loading from "../../components/Loading"
import { FaLinkedin } from "react-icons/fa6"

const Certificates = () => {
  const [certificates, setCertificates] = useState<PublicCertificateSchema[]>()
  const { CertificatesAuthedApi } = useAuthedApi()
  const [loading, setLoading] = useState(true)
  const [showResendModal, setShowResendModal] = useState(false)
  const [certificateToResend, setCertificateToResend] =
    useState<PublicCertificateSchema>()
  const [resendErrMsg, setResendErrMsg] = useState("")
  const resendErrRef = useRef<HTMLInputElement>(null)
  const [resendLoading, setResendLoading] = useState(false)
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const { toastList, setToastList } = useToasts()
  const navigate = useNavigate()

  const getCertificates = async () => {
    try {
      const response =
        await CertificatesAuthedApi.certificatesReadCertificatesMe()
      if (!response.data) {
        setErrMsg("Get certificates failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setCertificates(response.data)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get certificate failed with unknown error")
        }
      } else {
        setErrMsg("Get certificate failed with unknown error")
      }
      errRef.current?.focus()
    }
    setLoading(false)
  }

  const reSendCertificate = async (
    certificate: PublicCertificateSchema | undefined
  ) => {
    setResendLoading(true)
    if (!certificate) {
      setResendErrMsg(`No certificate to resend`)
      setResendLoading(false)
      return
    }
    try {
      const response =
        await CertificatesAuthedApi.certificatesResendCertificate(
          certificate.id
        )
      if (!response.data) {
        setResendErrMsg("Re-send certificate email failed with unknown error")
        setResendLoading(false)
        return
      }
      // console.log(JSON.stringify(response?.data))

      setToastList(
        toastList.concat({
          type: "success",
          children: "Certificate sent!",
        })
      )
      setShowResendModal(false)
      setCertificateToResend(undefined)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setResendErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setResendErrMsg("Data validation error")
        } else {
          setResendErrMsg("Re-send certificate failed with unknown error")
        }
      } else {
        setResendErrMsg("Re-send certificate failed with unknown error")
      }
      resendErrRef.current?.focus()
    }
    setResendLoading(false)
  }

  useEffect(() => {
    getCertificates()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo<ColumnDef<PublicCertificateSchema>[]>(
    () => [
      {
        header: "CERTIFICATE ID",
        footer: (props) => props.column.id,
        accessorKey: "id",
        cell: (props) => (
          <div
            onClick={() => {
              navigate(`/certificates/${props.row.original.id}`)
            }}
            className="hover:underline hover:cursor-pointer font-medium text-gray-900 dark:text-white"
          >
            {props.row.original.id}
          </div>
        ),
      },
      {
        header: "COURSE",
        footer: (props) => props.column.id,
        accessorKey: "course.title",
        cell: (props) => props.getValue(),
      },
      {
        header: "AWARDED AT",
        footer: (props) => props.column.id,
        accessorKey: "awarded_at",
        cell: (props) => props.getValue(),
        enableSorting: false,
      },
      {
        id: "actions",
        footer: (props) => props.column.id,
        cell: (props) => (
          <div className="flex gap-2">
            <Tooltip
              content="Re-send certificate"
              className="bg-neutral-900 text-white dark:bg-neutral-800"
              theme={{
                arrow: {
                  base: "absolute z-10 h-2 w-2 rotate-45 bg-neutral-900 dark:bg-neutral-800",
                },
              }}
            >
              <button
                onClick={() => {
                  setShowResendModal(true)
                  setCertificateToResend(props.row.original)
                }}
                className="font-medium text-center text-red-logo hover:text-red-900 hover:underline cursor-pointer"
              >
                <TbMailForward size={20} />
              </button>
            </Tooltip>
            <Tooltip
              content={
                <>
                  Add to your LinkedIn profile
                  <br />
                  <small>
                    We suggest attaching the certificate PDF in the "Media"
                    field
                  </small>
                </>
              }
              className="bg-neutral-900 text-white dark:bg-neutral-800"
              theme={{
                arrow: {
                  base: "absolute z-10 h-2 w-2 rotate-45 bg-neutral-900 dark:bg-neutral-800",
                },
              }}
            >
              <button
                onClick={() => {
                  window.open(
                    `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=HackTicks ${
                      props.row.original.course.title
                    }&organizationId=72119507&issueYear=${new Date(
                      props.row.original.awarded_at
                    ).getFullYear()}&issueMonth=${new Date(
                      props.row.original.awarded_at
                    ).getMonth()}&certUrl=https%3A%2F%2Ftraining.hacktricks.xyz%2Fcertificates%2F${
                      props.row.original.id
                    }&certId=${props.row.original.id}`,
                    "_blank",
                    "noreferrer"
                  )
                }}
                className="font-medium text-center text-red-logo hover:text-red-900 hover:underline cursor-pointer"
              >
                <FaLinkedin size={20} />
              </button>
            </Tooltip>
          </div>
        ),
      },
    ],
    //eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <section className="min-h-screen flex flex-col justify-between">
      <NavbarPrivate />
      <div className="mb-auto">
        <div className="container mx-auto">
          {loading ? (
            <Loading />
          ) : (
            <>
              <section id="certificates">
                <h1 className="text-center text-2xl md:text-4xl font-bold text-gray-800 dark:text-white mt-8 mb-5 md:mb-10 mx-3 md:mx-0">
                  CERTIFICATES
                </h1>
                <Modal
                  size="md"
                  dismissible={true}
                  popup={true}
                  show={showResendModal}
                  onClose={() => {
                    setShowResendModal(false)
                    setCertificateToResend(undefined)
                  }}
                >
                  <Modal.Header />
                  <Modal.Body>
                    <div className="text-center">
                      <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                      <div className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        Are you sure you want regenerate and send this
                        certificate {certificateToResend?.id}?
                      </div>
                      <div className={!resendErrMsg ? "sr-only" : ""}>
                        <Alert
                          color="failure"
                          icon={ExclamationTriangleIcon}
                          onDismiss={function onDismiss() {
                            setResendErrMsg("")
                          }}
                        >
                          <span ref={resendErrRef}>{resendErrMsg}</span>
                        </Alert>
                      </div>
                      <div className="flex justify-center gap-4">
                        <Button
                          isProcessing={resendLoading}
                          processingSpinner={<Spinner color="failure" />}
                          color="success"
                          onClick={() => reSendCertificate(certificateToResend)}
                        >
                          Yes, I'm sure
                        </Button>
                        <Button
                          color="gray"
                          onClick={() => setShowResendModal(false)}
                        >
                          No, cancel
                        </Button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
                <div className={!errMsg ? "sr-only" : ""}>
                  <Alert
                    color="failure"
                    icon={ExclamationTriangleIcon}
                    onDismiss={function onDismiss() {
                      setErrMsg("")
                    }}
                  >
                    <span ref={errRef}>{errMsg}</span>
                  </Alert>
                </div>
                <div className="overflow-x-auto mx-3 md:mx-0 mb-10">
                  {certificates?.length ? (
                    <BasicTable columns={columns} data={certificates} />
                  ) : (
                    <Alert color="warning" icon={ExclamationTriangleIcon}>
                      <span>No certificates to display</span>
                    </Alert>
                  )}
                </div>
              </section>
            </>
          )}
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Certificates
