import { Outlet } from "react-router-dom"
import { useState, useEffect } from "react"
import useRefreshToken from "../hooks/useRefreshToken"
import useAuth from "../hooks/useAuth"
import Loading from "./Loading"

const PersistAuth = () => {
  const [isLoading, setIsLoading] = useState(true)
  const refresh = useRefreshToken()
  const { auth } = useAuth()

  useEffect(() => {
    let isMounted = true

    const verifyRefreshToken = async () => {
      try {
        await refresh()
      } catch (err) {
        console.error(err)
      } finally {
        isMounted && setIsLoading(false)
      }
    }

    !auth?.access_token ? verifyRefreshToken() : setIsLoading(false)

    return () => {
      isMounted = false
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   console.log(`isLoading: ${isLoading}`)
  //   console.log(`aT: ${JSON.stringify(auth?.access_token)}`)

  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoading])

  return <>{isLoading ? <Loading /> : <Outlet />}</>
}

export default PersistAuth
