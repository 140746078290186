import { ToggleSwitch } from "flowbite-react"
import { FC, PropsWithChildren } from "react"
import useTheme from "../hooks/useTheme"

export type Props = PropsWithChildren<{
  className?: string
}>

export const CustomDarkThemeToggle: FC<Props> = ({ className }) => {
  const { theme, setTheme } = useTheme()

  return (
    <ToggleSwitch
      aria-label={
        theme === "dark" ? "Currently dark mode" : "Currently light mode"
      }
      className={className}
      checked={
        theme === "dark"
          ? true
          : theme === "light"
          ? false
          : document.documentElement.classList.contains("dark")
      }
      label=""
      onChange={() => {
        setTheme(
          document.documentElement.classList.contains("dark") ? "light" : "dark"
        )
      }}
      color="red"
      theme={{
        root: {
          base: "group relative flex items-center rounded-lg focus:outline-none",
          active: {
            on: "cursor-pointer",
            off: "cursor-not-allowed opacity-50",
          },
          label:
            "ml-3 text-sm font-medium text-neutral-900 dark:text-neutral-300",
        },
        toggle: {
          base: "rounded-full border after:rounded-full after:bg-white group-focus:ring-4 group-focus:ring-cyan-500/25",
          checked: {
            on: "after:translate-x-full after:border-white",
            off: "border-neutral-200 bg-neutral-200 dark:border-neutral-600 dark:bg-neutral-700",
            color: {
              blue: "border-cyan-700 bg-cyan-700",
              dark: "bg-dark-700 border-dark-900",
              failure: "border-red-900 bg-red-700",
              gray: "border-gray-600 bg-gray-500",
              green: "border-green-700 bg-green-600",
              light: "bg-light-700 border-light-900",
              red: "border-red-900 bg-red-logo",
              purple: "border-purple-900 bg-purple-700",
              success: "border-green-500 bg-green-500",
              yellow: "border-yellow-400 bg-yellow-400",
              warning: "border-yellow-600 bg-yellow-600",
              cyan: "border-cyan-500 bg-cyan-500",
              lime: "border-lime-400 bg-lime-400",
              indigo: "border-indigo-400 bg-indigo-400",
              teal: "bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4",
              info: "border-cyan-600 bg-cyan-600",
              pink: "border-pink-600 bg-pink-600",
            },
          },
          sizes: {
            sm: "h-5 w-9 after:absolute after:left-[2px] after:top-[2px] after:h-4 after:w-4",
            md: "h-6 w-11 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5",
            lg: "h-7 w-14 after:absolute after:left-[4px] after:top-0.5 after:h-6 after:w-6",
          },
        },
      }}
    />
  )
}
