import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import { Alert } from "flowbite-react"
import { useParams } from "react-router-dom"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"
import NavbarPublic from "../../components/navbar/NavbarPublic"
import useAuth from "../../hooks/useAuth"

const Feedback = () => {
  const { slug } = useParams()
  const { auth } = useAuth()
  return (
    <section className="min-h-screen flex flex-col justify-between">
      {auth ? <NavbarPrivate /> : <NavbarPublic />}
      <div className="mb-auto min-h-full">
        <div className="container mx-auto min-h-full">
          <section id="feedback" className="min-h-full">
            {slug !== undefined && slug === "arte" ? (
              <>
                <iframe
                  title="ARTE feedback"
                  src="https://docs.google.com/forms/d/e/1FAIpQLSd5NOjAY602S17W6rJ58pb7wXDHL_Yb8OlW7F9DmDxYPblt4A/viewform?embedded=true"
                  className="border-0 w-full min-h-full"
                  height="2100"
                >
                  Loading form...
                </iframe>
              </>
            ) : (
              <Alert color="failure" icon={ExclamationTriangleIcon}>
                No such certificate
              </Alert>
            )}
          </section>
        </div>
      </div>
    </section>
  )
}

export default Feedback
