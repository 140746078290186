import { Banner } from "./Banner"
import useBanners, { BannerListData } from "../hooks/useBanners"
import { useEffect, useState } from "react"

const BannerContainer = () => {
  const { bannerList } = useBanners()
  const [banners, setBanners] = useState<BannerListData>([])

  const localDismissedBanners = localStorage
    .getItem("dismissed_banners")
    ?.split(",")

  useEffect(() => {
    setBanners(bannerList)
  }, [bannerList])

  const bannersMap = banners.map((banner, index) => {
    return (
      <div key={index}>
        {localDismissedBanners && localDismissedBanners.includes(banner.id) ? (
          <></>
        ) : (
          <>
            <Banner link={banner.link} id={banner.id}>
              {banner.children}
            </Banner>
          </>
        )}
      </div>
    )
  })
  return (
    <div className="fixed flex flex-col gap-4 w-full max-w-4xl bottom-0 md:bottom-5 inset-x-1/2 -translate-x-1/2 z-50">
      {bannersMap}
    </div>
  )
}

export default BannerContainer
