import axios from "axios"
import { useEffect } from "react"
import {
  AuthApi,
  UsersApi,
  CoursesApi,
  VouchersApi,
  CertificatesApi,
  FlagsApi,
  LabsApi,
  ExamsApi,
  AdminApi,
} from "../api"
import useAuth from "./useAuth"
import useRefreshToken from "./useRefreshToken"

const useAuthedApi = () => {
  const refresh = useRefreshToken()
  const { auth } = useAuth()
  const BASE_URL = process.env.REACT_APP_API_URL
  const axiosPrivate = axios.create({
    withCredentials: true,
  })
  let requestIntercept: number
  let responseIntercept: number

  const init = () => {
    requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${auth?.access_token}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true
          const newAccessToken = await refresh()
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`
          return axiosPrivate(prevRequest)
        }
        return Promise.reject(error)
      }
    )
  }

  useEffect(() => {
    init()
    return () => {
      // axiosPrivate.interceptors.request.eject(requestIntercept)
      // axiosPrivate.interceptors.response.eject(responseIntercept)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, refresh, axiosPrivate])

  useEffect(() => {
    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept)
      axiosPrivate.interceptors.response.eject(responseIntercept)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const apiPrivate = {
    AuthAuthedApi: new AuthApi(undefined, BASE_URL, axiosPrivate),
    UsersAuthedApi: new UsersApi(undefined, BASE_URL, axiosPrivate),
    CoursesAuthedApi: new CoursesApi(undefined, BASE_URL, axiosPrivate),
    FlagsAuthedApi: new FlagsApi(undefined, BASE_URL, axiosPrivate),
    LabsAuthedApi: new LabsApi(undefined, BASE_URL, axiosPrivate),
    ExamsAuthedApi: new ExamsApi(undefined, BASE_URL, axiosPrivate),
    VouchersAuthedApi: new VouchersApi(undefined, BASE_URL, axiosPrivate),
    CertificatesAuthedApi: new CertificatesApi(
      undefined,
      BASE_URL,
      axiosPrivate
    ),
    AdminAuthedApi: new AdminApi(undefined, BASE_URL, axiosPrivate),
  }
  return apiPrivate
}

export default useAuthedApi
