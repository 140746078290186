import { createContext, PropsWithChildren, useState } from "react"
import { EmailSubscriptionEnum } from "../api"

export type AuthData = {
  user?: {
    username: string
    email: string
    first_name: string
    last_name: string
    company?: string
    discord?: string
    email_subscriptions: EmailSubscriptionEnum[]
    roles: string[]
  }
  access_token?: string
}

interface AuthContextProps {
  auth: AuthData | undefined
  setAuth: (auth: AuthData | undefined) => void
}

const AuthContext = createContext<AuthContextProps>({
  auth: undefined,
  setAuth: () => {},
})

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthData | undefined>()

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
