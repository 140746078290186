import { Navbar } from "flowbite-react"
import ScrollIntoView from "react-scroll-into-view"

import { FC, PropsWithChildren } from "react"

export type NavbarLinkScrollProps = PropsWithChildren<{
  active?: boolean
  disabled?: boolean
  href: string
}>

export const NavbarLink: FC<NavbarLinkScrollProps> = ({
  active,
  disabled,
  href,
  children,
}) => {
  return (
    <ScrollIntoView selector={href}>
      <Navbar.Link
        theme={{
          base: "block py-2 pr-4 pl-3 md:p-0 text-neutral-900 dark:text-white hover:text-neutral-400 hover:dark:text-neutral-400 cursor-pointer  font-bold uppercase font-hacktricks",
          active: {
            off: "text-neutral-900 dark:text-white hover:text-neutral-400",
            on: "text-neutral-900 dark:text-white",
          },
        }}
        className="cursor-pointer"
        active={active}
        disabled={disabled}
      >
        {children}
      </Navbar.Link>
    </ScrollIntoView>
  )
}
