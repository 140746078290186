import { Button } from "flowbite-react"
import Footer from "../../components/Footer"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"
import NavbarPublic from "../../components/navbar/NavbarPublic"
import useAuth from "../../hooks/useAuth"
import { FaDiscord } from "react-icons/fa"
import { MdMail } from "react-icons/md"

const Contact = () => {
  const { auth } = useAuth()
  return (
    <section className="min-h-screen flex flex-col justify-between">
      {auth ? <NavbarPrivate /> : <NavbarPublic />}
      <div className="mb-auto">
        <div className="container mx-auto">
          <section id="contact">
            <h1 className="mt-8 text-4xl font-bold text-center dark:text-white">
              GET IN TOUCH
            </h1>
            <div className="my-10 gap-8 grid grid-cols-1 md:grid-cols-2 justify-items-center">
              <div className="w-full md:w-auto">
                <h2 className="text-3xl font-bold text-center dark:text-white">
                  JOIN OUR DISCORD
                </h2>
                <Button
                  href="https://discord.gg/hRep4RUj7f"
                  target="_blank"
                  className="mt-8 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                >
                  <FaDiscord className="mr-2" /> HackTricks Discord
                </Button>
              </div>
              <div className="w-full md:w-auto">
                <h2 className="text-3xl font-bold text-center dark:text-white">
                  EMAIL US
                </h2>
                <Button
                  href="mailto:training-support@hacktricks.xyz"
                  className="mt-8 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                >
                  <MdMail className="mr-2" /> training-support@hacktricks.xyz
                </Button>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Contact
