import { Alert, Button, Checkbox, Modal, Tooltip } from "flowbite-react"
import { useEffect, useMemo, useRef, useState } from "react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import AdminSidebar from "../../components/sidebar/AdminSidebar"
import { RiEdit2Fill } from "react-icons/ri"
import { ColumnDef } from "@tanstack/react-table"
import { AdminUserSchema } from "../../api"
import useAuthedApi from "../../hooks/useAuthedApi"
import useToasts from "../../hooks/useToasts"
import { isAxiosError } from "axios"
import AdminTable from "../../components/tables/AdminTable"
import { MdDelete, MdLockReset, MdMarkEmailRead } from "react-icons/md"
import { HiOutlineExclamationCircle } from "react-icons/hi"
import AdminEditUser from "../../components/modals/AdminEditUser"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"

const AdminUsers = () => {
  const [users, setUsers] = useState<AdminUserSchema[]>()
  const { AdminAuthedApi } = useAuthedApi()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [userToDelete, setUserToDelete] = useState<AdminUserSchema>()
  const [deleteErrMsg, setDeleteErrMsg] = useState("")
  const deleteErrRef = useRef<HTMLInputElement>(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [userToEdit, setUserToEdit] = useState<AdminUserSchema>()
  const [editErrMsg, setEditErrMsg] = useState("")
  const editErrRef = useRef<HTMLInputElement>(null)
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false)
  const [userToPasswordReset, setUserToPasswordReset] =
    useState<AdminUserSchema>()
  const [passwordResetErrMsg, setPasswordResetErrMsg] = useState("")
  const passwordResetErrRef = useRef<HTMLInputElement>(null)
  const [showActivateEmailModal, setShowActivateEmailModal] = useState(false)
  const [userToActivateEmail, setUserToActivateEmail] =
    useState<AdminUserSchema>()
  const [activateEmailErrMsg, setActivateEmailErrMsg] = useState("")
  const activateEmailErrRef = useRef<HTMLInputElement>(null)
  const { toastList, setToastList } = useToasts()
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(
    window.innerWidth < 640
  )

  const getUsers = async () => {
    try {
      const response = await AdminAuthedApi.adminReadUsers()
      if (!response.data) {
        setErrMsg("Get users failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setUsers(response.data)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get users failed with unknown error")
        }
      } else {
        setErrMsg("Get users failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  const deleteUser = async (user: AdminUserSchema | undefined) => {
    if (!user) {
      setDeleteErrMsg(`No user to delete`)
      return
    }
    if (!users) {
      setDeleteErrMsg(`No users`)
      return
    }
    try {
      const response = await AdminAuthedApi.adminDeleteUser(user.username)
      if (!response.data) {
        setDeleteErrMsg(
          `Delete user ${user.username} failed with unknown error`
        )
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success) {
        setDeleteErrMsg(`No users were deleted`)
        return
      }

      setToastList(
        toastList.concat({
          type: "success",
          children: `User ${user.username} was deleted successfully!`,
        })
      )

      const index = users.indexOf(user)
      let clone = users
      clone.splice(index, 1)
      setUsers(clone)

      setShowDeleteModal(false)
      setUserToDelete(undefined)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setDeleteErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setDeleteErrMsg("Data validation error")
        } else {
          setDeleteErrMsg(
            `Delete user ${user.username} failed with unknown error`
          )
        }
      } else {
        setDeleteErrMsg(
          `Delete user ${user.username} failed with unknown error`
        )
      }
      deleteErrRef.current?.focus()
    }
  }

  const editUser = async (user: AdminUserSchema | undefined) => {
    if (!user) {
      setDeleteErrMsg(`No user to edit`)
      return
    }
    if (!users) {
      setDeleteErrMsg(`No users`)
      return
    }
    console.log(user)
    try {
      const response = await AdminAuthedApi.adminEditUser({
        ...user,
        company: user.company === "" ? undefined : user.company,
      })
      if (!response.data) {
        setEditErrMsg(`Editing user ${user.username} failed with unknown error`)
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success || !response.data.user) {
        setEditErrMsg(`No users were modified`)
        return
      }

      setToastList(
        toastList.concat({
          type: "success",
          children: `User ${user.username} was modified successfully!`,
        })
      )

      const index = users.indexOf(user)
      let clone = users
      clone.splice(index, 1)
      clone.push(response.data.user)
      setUsers(clone)

      setShowEditModal(false)
      setUserToEdit(undefined)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setEditErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setEditErrMsg("Data validation error")
        } else {
          setEditErrMsg(`Edit user ${user.username} failed with unknown error`)
        }
      } else {
        setEditErrMsg(`Edit user ${user.username} failed with unknown error`)
      }
      editErrRef.current?.focus()
    }
  }

  const passwordResetUser = async (user: AdminUserSchema | undefined) => {
    if (!user) {
      setPasswordResetErrMsg(`No user to send password reset`)
      return
    }
    if (!users) {
      setPasswordResetErrMsg(`No users`)
      return
    }
    try {
      const response = await AdminAuthedApi.adminPasswordResetUser(
        user.username
      )
      if (!response.data) {
        setPasswordResetErrMsg(
          `Password reset email for user ${user.username} failed with unknown error`
        )
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success) {
        setPasswordResetErrMsg(`No password reset emails were sent`)
        return
      }

      setToastList(
        toastList.concat({
          type: "success",
          children: `Password reset email sent to user ${user.username}!`,
        })
      )

      const index = users.indexOf(user)
      let clone = users
      clone.splice(index, 1)
      setUsers(clone)

      setShowPasswordResetModal(false)
      setUserToPasswordReset(undefined)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setPasswordResetErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setPasswordResetErrMsg("Data validation error")
        } else {
          setPasswordResetErrMsg(
            `Password reset email for user ${user.username} failed with unknown error`
          )
        }
      } else {
        setPasswordResetErrMsg(
          `Password reset email for user ${user.username} failed with unknown error`
        )
      }
      passwordResetErrRef.current?.focus()
    }
  }

  const activateEmailUser = async (user: AdminUserSchema | undefined) => {
    if (!user) {
      setActivateEmailErrMsg(`No user to send activation email`)
      return
    }
    if (!users) {
      setActivateEmailErrMsg(`No users`)
      return
    }
    try {
      const response = await AdminAuthedApi.adminResendActivateEmail(
        user.username
      )
      if (!response.data) {
        setActivateEmailErrMsg(
          `Activation email for user ${user.username} failed with unknown error`
        )
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success) {
        setActivateEmailErrMsg(`No activation emails were sent`)
        return
      }

      setToastList(
        toastList.concat({
          type: "success",
          children: `Activation email sent to user ${user.username}!`,
        })
      )

      const index = users.indexOf(user)
      let clone = users
      clone.splice(index, 1)
      setUsers(clone)

      setShowActivateEmailModal(false)
      setUserToActivateEmail(undefined)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setActivateEmailErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setActivateEmailErrMsg("Data validation error")
        } else {
          setActivateEmailErrMsg(
            `Activation email for user ${user.username} failed with unknown error`
          )
        }
      } else {
        setActivateEmailErrMsg(
          `Activation email for user ${user.username} failed with unknown error`
        )
      }
      activateEmailErrRef.current?.focus()
    }
  }

  useEffect(() => {
    getUsers()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo<ColumnDef<AdminUserSchema>[]>(
    () => [
      {
        header: "USER ID",
        footer: (props) => props.column.id,
        accessorKey: "id",
        cell: (props) => (
          <span className="font-medium text-gray-900 dark:text-white">
            {props.row.original.id}
          </span>
        ),
      },
      {
        header: "USERNAME",
        footer: (props) => props.column.id,
        accessorKey: "username",
        cell: (props) => props.getValue(),
      },
      {
        header: "EMAIL",
        footer: (props) => props.column.id,
        accessorKey: "email",
        cell: (props) => props.getValue(),
      },
      {
        header: "FIRST NAME",
        footer: (props) => props.column.id,
        accessorKey: "first_name",
        cell: (props) => props.getValue(),
      },
      {
        header: "LAST NAME",
        footer: (props) => props.column.id,
        accessorKey: "last_name",
        cell: (props) => props.getValue(),
      },
      {
        header: "COMPANY",
        footer: (props) => props.column.id,
        accessorKey: "company",
        cell: (props) => props.getValue(),
      },
      {
        header: "IS ACTIVE",
        footer: (props) => props.column.id,
        accessorKey: "is_active",
        cell: (props) => (
          <Checkbox checked={props.getValue() ? true : false} disabled={true} />
        ),
        enableColumnFilter: false,
      },
      {
        id: "actions",
        footer: (props) => props.column.id,
        cell: (props) => (
          <div className="flex flex-row gap-1">
            <Tooltip content="Edit user">
              <button
                onClick={() => {
                  setShowEditModal(true)
                  setUserToEdit(props.row.original)
                }}
                className="font-medium text-center text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
              >
                <RiEdit2Fill size={20} />
              </button>
            </Tooltip>
            <Tooltip content="Delete user">
              <button
                onClick={() => {
                  setShowDeleteModal(true)
                  setUserToDelete(props.row.original)
                }}
                className="font-medium text-center text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
              >
                <MdDelete size={20} />
              </button>
            </Tooltip>
            <Tooltip content="Send password reset">
              <button
                onClick={() => {
                  setShowPasswordResetModal(true)
                  setUserToPasswordReset(props.row.original)
                }}
                className="font-medium text-center text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
              >
                <MdLockReset size={20} />
              </button>
            </Tooltip>
            <Tooltip content="Send activation email">
              <button
                onClick={() => {
                  setShowActivateEmailModal(true)
                  setUserToActivateEmail(props.row.original)
                }}
                className="font-medium text-center text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
              >
                <MdMarkEmailRead size={20} />
              </button>
            </Tooltip>
          </div>
        ),
      },
    ],
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [users]
  )

  return (
    <>
      <div className="fixed top-0 inset-x-0 z-40 flex justify-between items-center">
        <NavbarPrivate />
      </div>

      <AdminSidebar
        onCollapseChange={(collapsed) => {
          setSidebarCollapsed(collapsed)
        }}
      />
      <main
        className={
          sidebarCollapsed
            ? "pt-20 pl-0 transition-all ease-in-out duration-300"
            : "pt-20 sm:pl-64 lg:pl-96 transition-all ease-in-out duration-300"
        }
      >
        <div className="container mx-auto">
          <div className="p-5">
            <section id="users">
              <h1 className="text-left text-2xl md:text-4xl font-bold text-gray-800 dark:text-white mb-5 mx-3 md:mx-0">
                USERS
              </h1>
              <div className={!errMsg ? "sr-only" : ""}>
                <Alert
                  color="failure"
                  icon={ExclamationTriangleIcon}
                  onDismiss={function onDismiss() {
                    setErrMsg("")
                  }}
                >
                  <span ref={errRef}>{errMsg}</span>
                </Alert>
              </div>
              <div className="mb-10">
                {users?.length ? (
                  <AdminTable columns={columns} data={users} />
                ) : (
                  <Alert color="warning" icon={ExclamationTriangleIcon}>
                    <span>No users to display</span>
                  </Alert>
                )}
              </div>
            </section>
          </div>
        </div>
      </main>
      <Modal
        size="md"
        dismissible={true}
        popup={true}
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false)
          setUserToDelete(undefined)
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete the user {userToDelete?.username}?
            </h3>
            <div className={!deleteErrMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setDeleteErrMsg("")
                }}
              >
                <span ref={deleteErrRef}>{deleteErrMsg}</span>
              </Alert>
            </div>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => deleteUser(userToDelete)}>
                Yes, I'm sure
              </Button>
              <Button color="gray" onClick={() => setShowDeleteModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {userToEdit ? (
        <AdminEditUser
          userToEdit={userToEdit}
          setUserToEdit={setUserToEdit}
          editUser={editUser}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          editErrMsg={editErrMsg}
          setEditErrMsg={setEditErrMsg}
          editErrRef={editErrRef}
        />
      ) : (
        <></>
      )}
      <Modal
        size="md"
        dismissible={true}
        popup={true}
        show={showPasswordResetModal}
        onClose={() => {
          setShowPasswordResetModal(false)
          setUserToPasswordReset(undefined)
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to send a password reset email to the user{" "}
              {userToPasswordReset?.username}?
            </h3>
            <div className={!passwordResetErrMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setPasswordResetErrMsg("")
                }}
              >
                <span ref={passwordResetErrRef}>{passwordResetErrMsg}</span>
              </Alert>
            </div>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={() => passwordResetUser(userToPasswordReset)}
              >
                Yes, I'm sure
              </Button>
              <Button
                color="gray"
                onClick={() => setShowPasswordResetModal(false)}
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        dismissible={true}
        popup={true}
        show={showActivateEmailModal}
        onClose={() => {
          setShowActivateEmailModal(false)
          setUserToActivateEmail(undefined)
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to send an activation email to the user{" "}
              {userToActivateEmail?.username}?
            </h3>
            <div className={!activateEmailErrMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setActivateEmailErrMsg("")
                }}
              >
                <span ref={activateEmailErrRef}>{activateEmailErrMsg}</span>
              </Alert>
            </div>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={() => activateEmailUser(userToActivateEmail)}
              >
                Yes, I'm sure
              </Button>
              <Button
                color="gray"
                onClick={() => setShowActivateEmailModal(false)}
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminUsers
