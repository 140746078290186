import { useRef, useState, useEffect } from "react"
import useAuth from "../../hooks/useAuth"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { isAxiosError } from "axios"
import useAuthedApi from "../../hooks/useApi"
import { Alert, Button, Label, TextInput } from "flowbite-react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import HT_TRAINING_logo from "../../assets/images/HT_TRAINING_logo.svg"

const Signin = () => {
  const { setAuth } = useAuth()
  const { AuthApi } = useAuthedApi()

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || "/dashboard"

  const usernameRef = useRef<HTMLInputElement>(null)
  const errRef = useRef<HTMLInputElement>(null)
  const [username, setUsername] = useState("")

  const [pwd, setPwd] = useState("")

  const [errMsg, setErrMsg] = useState("")

  useEffect(() => {
    usernameRef.current?.focus()
  }, [])

  useEffect(() => {
    setErrMsg("")
  }, [username, pwd])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    // if button enabled with JS hack
    const v1 = username !== ""
    const v2 = pwd !== ""
    if (!v1 || !v2) {
      setErrMsg("Invalid data")
      return
    }

    try {
      const response = await AuthApi.authSignin(username, pwd)
      if (!response.data) {
        setErrMsg("Sign in failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))
      setAuth({ ...response.data })

      setUsername("")
      setPwd("")

      if (!response.data.user.is_active) {
        navigate("/inactive_account")
      } else {
        navigate(from, { replace: true })
      }
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 401) {
          setErrMsg("Invalid username or password")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Sign in failed with unknown error")
        }
      } else {
        setErrMsg("Sign in failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  return (
    <section>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-48 w-auto"
              src={HT_TRAINING_logo}
              alt="HackTricks Training"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
              Sign in to your account
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600 dark:text-white">
              Or{" "}
              <Link
                to="/signup"
                className="font-medium text-red-logo hover:text-red-900"
              >
                create a new account now!
              </Link>
            </p>
          </div>
          <div className={!errMsg ? "sr-only" : ""}>
            <Alert
              color="failure"
              icon={ExclamationTriangleIcon}
              onDismiss={function onDismiss() {
                setErrMsg("")
              }}
            >
              <span ref={errRef}>{errMsg}</span>
            </Alert>
          </div>
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="username" value="Username or email" />
              </div>
              <TextInput
                id="username"
                type="text"
                placeholder="Username or email"
                autoComplete="username"
                required={true}
                onChange={(e) => {
                  setUsername(e.target.value)
                }}
                value={username}
                ref={usernameRef}
                color=""
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="password" value="Password" />
              </div>
              <TextInput
                id="password"
                type="password"
                placeholder="Password"
                autoComplete="password"
                required={true}
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                color=""
              />
            </div>

            <div className="text-sm">
              <Link
                to="/forgot_password"
                className="font-medium text-red-logo hover:text-red-900"
              >
                Forgot your password?
              </Link>
            </div>
            <div className="flex flex-col items-center">
              <Button
                className="w-full md:w-auto px-20 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Signin
