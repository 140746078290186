import { Tabs } from "flowbite-react"
import Footer from "../../components/Footer"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"
import NavbarPublic from "../../components/navbar/NavbarPublic"
import useAuth from "../../hooks/useAuth"

const Terms = () => {
  const { auth } = useAuth()
  return (
    <section className="min-h-screen flex flex-col justify-between">
      {auth ? <NavbarPrivate /> : <NavbarPublic />}
      <div className="mb-auto">
        <div className="container mx-auto px-5 md:px-16">
          <section id="terms_conditions">
            <Tabs
              aria-label="Terms and Conditions"
              className=" mt-10"
              theme={{
                tablist: {
                  styles: {
                    pills:
                      "flex-wrap font-medium text-sm text-neutral-500 dark:text-neutral-400 space-x-2",
                  },
                  tabitem: {
                    base: "flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-neutral-400 disabled:dark:text-neutral-500 focus:ring-4 focus:ring-red-900 focus:outline-none",
                    styles: {
                      pills: {
                        base: "",
                        active: {
                          on: "rounded-lg bg-neutral-500 text-white",
                          off: "rounded-lg hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-700 dark:hover:text-white",
                        },
                      },
                    },
                  },
                },
              }}
              // eslint-disable-next-line
              style="pills"
            >
              <Tabs.Item active title="English 🇬🇧">
                <h1 className="text-3xl md:text-5xl font-extrabold dark:text-white">
                  Terms and Conditions
                </h1>
                <p className="mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300 mt-4">
                  Welcome to HackTricks Training. The following terms and
                  conditions (the "Agreement") outline the rules and regulations
                  for the use of our website and the services offered. By using
                  our website and enrolling in our courses, you agree to be
                  bound by the terms and conditions outlined in this Agreement.
                  If you do not agree to these terms and conditions, please do
                  not use our website or enroll in our courses.
                </p>
                <ol className="nested space-y-5 list-inside mb-20">
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    General Provisions
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        This Agreement governs the relationship between our
                        company and you, the user, in relation to the use of our
                        website and the provision of our courses.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Our website and courses are designed for educational
                        purposes only and are not intended to provide legal or
                        professional advice.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        We reserve the right to modify these terms and
                        conditions at any time, without prior notice. It is your
                        responsibility to review the terms and conditions
                        regularly to ensure that you are aware of any changes.
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Registration and Payment
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        To enroll in our courses, you must register on our
                        website and pay the applicable fees.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        The fees for our courses are stated on our website and
                        are subject to change without prior notice.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Payment must be made in full at the time of enrollment.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Upon completion of the registration process and payment
                        of the applicable fees, you will receive access to the
                        course materials.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Refunds are available only if the voucher has not been redeemed and the refund request is made within 30 days of purchase. Please note that fees associated with the purchase (approximately 2%) cannot be refunded, and the refund process typically takes 5 to 10 business days.
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Course Materials
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        The course materials provided are the property of our
                        company and are protected by copyright laws.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        The course materials are intended for your personal,
                        non-commercial use only. You may not reproduce,
                        distribute, or display the course materials without our
                        prior written consent.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        We reserve the right to modify the course materials at
                        any time, without prior notice.
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Exam
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested text-xl md:text-2xl  font-bold dark:text-white">
                        Exam Rules
                        <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            You must complete the Exam within the specified time
                            limit.
                          </li>
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            You are not allowed to ask for help from anyone
                            during the Exam.
                          </li>
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            You are not allowed to share your Exam questions or
                            answers with anyone.
                          </li>
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            You are not allowed to reproduce, distribute, or
                            display Exam content in any form without prior
                            written consent from the Exam administrator.
                          </li>
                        </ol>
                      </li>
                      <li className="nested text-xl md:text-2xl  font-bold dark:text-white">
                        Prohibited Conduct
                        <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            You are not allowed to engage in any behavior that
                            would compromise the integrity of the Exam,
                            including but not limited to cheating, copying, or
                            distributing Exam materials.
                          </li>
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            You are not allowed to attempt to access Exam
                            materials or information that you are not authorized
                            to access.
                          </li>
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            You are not allowed to disrupt or interfere with the
                            Exam, or with the ability of others to take the
                            Exam.
                          </li>
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            You are not allowed to impersonate another person
                            during the Exam.
                          </li>
                        </ol>
                      </li>
                      <li className="nested text-xl md:text-2xl  font-bold dark:text-white">
                        Exam Security
                        <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            The Exam administrator will take measures to ensure
                            the security of the Exam content and prevent
                            unauthorized access to Exam materials.
                          </li>
                        </ol>
                      </li>
                      <li className="nested text-xl md:text-2xl  font-bold dark:text-white">
                        Exam Results
                        <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            Exam results will be determined solely by the Exam
                            administrator.
                          </li>
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            You will receive your Exam results within 30
                            business days of completing the Exam, via email.
                          </li>
                        </ol>
                      </li>
                      <li className="nested text-xl md:text-2xl  font-bold dark:text-white">
                        Disclaimer of Warranties
                        <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            The Exam is provided "as is" without warranty of any
                            kind, whether express or implied. Failure to comply
                            with the above points could lead to the withdrawal
                            of certification and access to the platform.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Privacy Policy
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Our privacy policy outlines how we collect, use, and
                        protect your personal information. By using our website
                        and enrolling in our courses, you agree to be bound by
                        our privacy policy.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        We will not disclose your personal information to third
                        parties, except as required by law.
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Limitation of Liability
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Our company shall not be liable for any damages arising
                        out of or in connection with the use of our website or
                        the provision of our courses.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        We shall not be liable for any indirect, incidental, or
                        consequential damages, including without limitation,
                        loss of profits or revenue, loss of data, or business
                        interruption.
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Governing Law and Jurisdiction
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        This Agreement shall be governed by the laws of the
                        Kingdom of Spain.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Any disputes arising out of or in connection with this
                        Agreement shall be subject to the exclusive jurisdiction
                        of the courts of the Kingdom of Spain.
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Termination
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        We reserve the right to terminate this Agreement and
                        your access to our website and courses at any time,
                        without prior notice, for justified reasons.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Upon termination, you must immediately cease all use of
                        our website and courses.
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Entire Agreement
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        This Agreement constitutes the entire agreement between
                        our company and you in relation to the use of our
                        website and the provision of our courses.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Any other terms or conditions that are inconsistent with
                        this Agreement are hereby excluded. If you have any
                        questions or concerns about these terms and conditions,
                        please contact us at training-support@hacktricks.xyz
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Company Information
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Hacktricks Training SL, ESB56654932, Calle Felix Candela, 28055, Madrid, Spain
                      </li>
                    </ol>
                  </li>
                </ol>
              </Tabs.Item>
              <Tabs.Item title="Español 🇪🇸">
                <h1 className="text-3xl md:text-5xl font-extrabold dark:text-white">
                  Terminos y condiciones
                </h1>
                <p className="mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300 mt-4">
                  Bienvenido a HackTricks Training. Los siguientes términos y
                  condiciones (el "Acuerdo") describen las normas y reglamentos
                  para el uso de nuestro sitio web y los servicios ofrecidos. Al
                  utilizar nuestro sitio web e inscribirse en nuestros cursos,
                  usted acepta estar obligado por los términos y condiciones
                  descritos en este Acuerdo. Si no está de acuerdo con estos
                  términos y condiciones, no utilice nuestro sitio web ni se
                  inscriba en nuestros cursos.{" "}
                </p>
                <ol className="nested space-y-5 list-inside mb-20">
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Disposiciones generales
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        El presente Acuerdo regula la relación entre nuestra
                        empresa y usted, el usuario, en relación con el uso de
                        nuestro sitio web y la impartición de nuestros cursos.{" "}
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Nuestro sitio web y nuestros cursos están concebidos
                        únicamente con fines educativos y no pretenden ofrecer
                        asesoramiento jurídico o profesional.{" "}
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Nos reservamos el derecho a modificar estos términos y
                        condiciones en cualquier momento, sin previo aviso. Es
                        su responsabilidad revisar los términos y condiciones
                        con regularidad para asegurarse de que está al tanto de
                        cualquier cambio.{" "}
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Inscripción y pago
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Para inscribirse en nuestros cursos, debe registrarse en
                        nuestro sitio web y pagar las tasas correspondientes.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Las tarifas de nuestros cursos se indican en nuestro
                        sitio web y están sujetos a cambios sin previo aviso.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        El pago debe efectuarse en su totalidad en el momento de
                        la inscripción.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Una vez completado el proceso de inscripción y abonado
                        el importe correspondiente, recibirá acceso a los
                        materiales del curso.
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Material didáctico
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        El material didáctico proporcionado es propiedad de
                        nuestra empresa y están protegidos por las leyes de
                        propiedad intelectual.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Los materiales del curso están destinados exclusivamente
                        a su uso personal y no comercial. No podrá reproducir,
                        distribuir ni mostrar los materiales del curso sin
                        nuestro consentimiento previo por escrito.{" "}
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Nos reservamos el derecho a modificar los materiales del
                        curso en cualquier momento, sin previo aviso.
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Examen
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested text-xl md:text-2xl  font-bold dark:text-white">
                        Normas del examen
                        <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            Debe completar el examen dentro del plazo
                            especificado.
                          </li>
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            No está permitido pedir ayuda a nadie durante el el
                            examen.
                          </li>
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            No está permitido compartir con nadie las preguntas
                            o respuestas con nadie.
                          </li>
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            No está permitido reproducir, distribuir o mostrar
                            el contenido del examen de ninguna forma sin el
                            consentimiento previo por escrito del administrador
                            del examen.
                          </li>
                        </ol>
                      </li>
                      <li className="nested text-xl md:text-2xl  font-bold dark:text-white">
                        Conductas prohibidas
                        <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            No se le permite participar en ningún comportamiento
                            que pueda comprometa la integridad del examen,
                            incluyendo, entre otros, hacer trampas, copiar o
                            distribuir materiales del examen.
                          </li>
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            No está permitido intentar acceder a materiales o
                            información del examen a los que no esté autorizado
                            a acceder.
                          </li>
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            No está permitido perturbar ni interferir en el
                            examen, ni en la capacidad de los demás para
                            realizarlo.
                          </li>
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            No está permitido hacerse pasar por otra persona
                            durante el examen.
                          </li>
                        </ol>
                      </li>
                      <li className="nested text-xl md:text-2xl  font-bold dark:text-white">
                        Seguridad de los exámenes
                        <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            El administrador del examen tomará medidas para
                            garantizar la seguridad del contenido del examen y
                            evitar el acceso no autorizado a los materiales del
                            examen.
                          </li>
                        </ol>
                      </li>
                      <li className="nested text-xl md:text-2xl  font-bold dark:text-white">
                        Resultados de los exámenes
                        <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            Los resultados del examen serán determinados
                            exclusivamente por el administrador del examen.
                          </li>
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            Recibirá los resultados de su examen en un plazo de
                            30 días laborables a partir de la finalización del
                            examen, por correo electrónico.
                          </li>
                        </ol>
                      </li>
                      <li className="nested text-xl md:text-2xl  font-bold dark:text-white">
                        Exclusión de garantías
                        <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                          <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                            El examen se proporciona "tal cual", sin garantía de
                            ningún tipo, ya sea expresa o implícita. El
                            incumplimiento de los puntos anteriores podría
                            conllevar la retirada de la certificación y del
                            acceso a la plataforma.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Política de privacidad
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Nuestra política de privacidad describe cómo recogemos,
                        utilizamos y protegemos su información personal. su
                        información personal. Al utilizar nuestro sitio web y
                        inscribirse en nuestros cursos, usted acepta que quedará
                        vinculado por nuestra política de privacidad.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        No revelaremos su información personal a terceros salvo
                        en los casos previstos por la ley.
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Limitación de responsabilidad
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Nuestra empresa no será responsable de ningún daño
                        derivado o en relación con el uso de nuestro sitio web o
                        la prestación de nuestros cursos.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        No seremos responsables de ningún daño indirecto,
                        incidental o daños consecuentes, incluyendo sin
                        limitación, pérdida de beneficios o ingresos, pérdida de
                        datos o interrupción de la actividad empresarial.
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Ley aplicable y jurisdicción
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        El presente Acuerdo se regirá por las leyes del Reino de
                        España.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Cualquier disputa que surja de o en relación con este
                        acuerdo estará sujeta a la jurisdicción exclusiva de los
                        tribunales del Reino de España.
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Rescisión
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Nos reservamos el derecho a rescindir este acuerdo y su
                        acceso a nuestro sitio web y cursos en cualquier
                        momento, sin previo aviso, por motivos justificados.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        En caso de rescisión, deberá cesar inmediatamente todo
                        uso de nuestro sitio web y los cursos.
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Acuerdo íntegro
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Este acuerdo constituye el acuerdo completo entre
                        nuestra empresa y usted en relación con el uso de
                        nuestro sitio web y la prestación de nuestros cursos.
                      </li>
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Quedan excluidos cualesquiera otros términos o
                        condiciones que sean incompatibles con el presente
                        acuerdo. Si tiene alguna pregunta o duda sobre estas
                        condiciones, póngase en contacto con nosotros en
                        training-support@hacktricks.xyz.
                      </li>
                    </ol>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Detalles de la compañía
                    <ol className="nested text-gray-500 list-decimal list-inside dark:text-neutral-300 mt-4">
                      <li className="nested mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                        Hacktricks Training SL, ESB56654932, Calle Felix Candela, 28055, Madrid, Spain
                      </li>
                    </ol>
                  </li>
                </ol>
              </Tabs.Item>
            </Tabs>
          </section>
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Terms
