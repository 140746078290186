import { FC, useEffect, useRef, useState } from "react"
import { FlagType, PublicFlagSchema } from "../api"
import useToasts from "../hooks/useToasts"
import useAuthedApi from "../hooks/useAuthedApi"
import { isAxiosError } from "axios"
import {
  Alert,
  Badge,
  Button,
  Spinner,
  TextInput,
  Modal,
  Tooltip,
} from "flowbite-react"
import { AiFillFlag } from "react-icons/ai"
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import rehypeAttrs from "rehype-attr"
import { FaClipboardList, FaEye } from "react-icons/fa6"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { HiOutlineExclamationCircle } from "react-icons/hi"

export type ClassroomFlagProps = {
  flag: PublicFlagSchema
  tf_outputs: any
  onChange: (completed: boolean) => void
  onWalkthroughDownloaded: () => void
  downloadedWalkthroughCount: number
}

const ClassroomFlag: FC<ClassroomFlagProps> = ({
  flag,
  tf_outputs,
  onChange,
  onWalkthroughDownloaded,
  downloadedWalkthroughCount,
}) => {
  const { toastList, setToastList } = useToasts()
  const [value, setValue] = useState("")
  const [loading, setLoading] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const { FlagsAuthedApi } = useAuthedApi()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)

  const [downloadWalkthroughErrMsg, setDownloadWalkthroughErrMsg] = useState("")
  const downloadWalkthroughErrRef = useRef<HTMLInputElement>(null)
  const [walkthroughFlag, setWalkthroughFlag] = useState<PublicFlagSchema>()
  const [downloadWalkthroughLoading, setDownloadWalkthroughLoading] =
    useState(false)
  const [openDownloadWalkthroughModal, setOpenDownloadWalkthroughModal] =
    useState<boolean>(false)

  const checkFlagCompleted = async () => {
    try {
      const response = await FlagsAuthedApi.flagsCheckHasFlagMe(flag.id)
      if (!response.data) {
        setErrMsg("Get flag status failed with unknown error")
        return
      }
      setIsCompleted(response.data.completed)
      onChange(response.data.completed)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get flag status failed with unknown error")
        }
      } else {
        setErrMsg("Get flag status failed with unknown error")
      }
      errRef.current?.focus()
    }
    setLoading(false)
  }

  const submitFlag = async () => {
    setErrMsg("")
    setLoadingSubmit(true)
    try {
      const response = await FlagsAuthedApi.flagsSubmitFlagMe(flag.id, {
        value: value,
      })
      if (!response.data) {
        setErrMsg("Incorrect flag")
        setLoadingSubmit(false)
        return
      }
      //   console.log(JSON.stringify(response?.data))

      setToastList(
        toastList.concat({
          type: "success",
          children: "You got the flag!",
        })
      )
      setIsCompleted(true)
      setValue("")
      onChange(true)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 400) {
          setErrMsg(err.response.data.detail)
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Flag submission failed with unknown error")
        }
      } else {
        setErrMsg("Flag submission failed with unknown error")
      }
      errRef.current?.focus()
    }
    setLoadingSubmit(false)
  }

  useEffect(() => {
    checkFlagCompleted()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isGCPKey = (value: any) => {
    const pattern = new RegExp(
      '^.*"private_key": "-----BEGIN PRIVATE KEY-----.*$',
      "m"
    )
    const result = pattern.test(value)
    return result
  }

  const downloadWalkthrough = async () => {
    setDownloadWalkthroughErrMsg("")
    setDownloadWalkthroughLoading(true)
    if (!walkthroughFlag) {
      setDownloadWalkthroughErrMsg("Flag is undefined")
      downloadWalkthroughErrRef.current?.focus()
      setDownloadWalkthroughLoading(false)
      return
    }
    try {
      const response = await FlagsAuthedApi.flagsGetWalkthroughUrl(
        walkthroughFlag.id
      )
      if (!response.data) {
        setDownloadWalkthroughErrMsg(
          "Walkthrough download failed with unknown error"
        )
        setDownloadWalkthroughLoading(false)
        return
      }

      const link = document.createElement("a")
      link.href = response.data.presigned_url
      link.target = "_blank"
      link.download = `${walkthroughFlag.walkthrough?.replaceAll("/", "_")}`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      setOpenDownloadWalkthroughModal(false)
      setToastList(
        toastList.concat({
          type: "success",
          children: `Walkthrough download triggered successfully!`,
        })
      )
      onWalkthroughDownloaded()
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setDownloadWalkthroughErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setDownloadWalkthroughErrMsg("Data validation error")
        } else if (err.response?.status === 400) {
          setDownloadWalkthroughErrMsg(err.response?.data.detail)
        } else {
          setDownloadWalkthroughErrMsg(
            "Walkthrough download failed with unknown error"
          )
        }
      } else {
        setDownloadWalkthroughErrMsg(
          "Walkthrough download failed with unknown error"
        )
      }
      downloadWalkthroughErrRef.current?.focus()
    }
    setDownloadWalkthroughLoading(false)
  }

  return (
    <>
      <Modal
        show={openDownloadWalkthroughModal}
        dismissible
        size="md"
        popup
        onClose={() => setOpenDownloadWalkthroughModal(false)}
        theme={{
          content: {
            inner:
              "relative rounded-lg bg-white shadow dark:bg-neutral-700 flex flex-col max-h-[90vh]",
          },
        }}>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to download a walkthrough for flag{" "}
              {walkthroughFlag?.title}?
            </h3>
            <div className={!downloadWalkthroughErrMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setDownloadWalkthroughErrMsg("")
                }}>
                <span ref={downloadWalkthroughErrRef}>
                  {downloadWalkthroughErrMsg}
                </span>
              </Alert>
            </div>
            <Alert
              className="mb-4"
              color="warning"
              icon={ExclamationTriangleIcon}>
              <p className="text-start">
                Walkthrough downloads are limited to 5 per course. You have
                currently used {downloadedWalkthroughCount}/5
              </p>
            </Alert>
            <div className="flex justify-center gap-4">
              <Button
                color="warning"
                onClick={() => downloadWalkthrough()}
                isProcessing={downloadWalkthroughLoading}
                disabled={downloadWalkthroughLoading}>
                <FaEye className="mr-1" /> Download walkthrough
              </Button>
              <Button
                color="gray"
                onClick={() => setOpenDownloadWalkthroughModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="mt-5">
        {loading ? (
          <div className="relative top-1/2 -translate-y-1/2">
            <Spinner size="xl" color="failure" />
          </div>
        ) : (
          <>
            <div className="flex">
              <h6 className="text-xl font-bold text-neutral-900 dark:text-white break-all">
                {flag.title}
              </h6>
              {isCompleted ? (
                <Badge
                  className="ml-3 px-4 text-xs !font-sans"
                  icon={AiFillFlag}
                  color="success">
                  Completed
                </Badge>
              ) : (
                <></>
              )}
              {flag.walkthrough ? (
                <div className="ml-auto">
                  <Tooltip content="Download walkthrough">
                    <Button
                      className="text-xs"
                      color="dark"
                      size="sm"
                      pill
                      onClick={() => {
                        setWalkthroughFlag(flag)
                        setOpenDownloadWalkthroughModal(true)
                      }}>
                      <FaEye />
                    </Button>
                  </Tooltip>
                </div>
              ) : (
                <></>
              )}
            </div>
            <Markdown
              className="markdown-body mt-3"
              remarkPlugins={[remarkGfm, rehypeAttrs]}>
              {flag.description}
            </Markdown>
            {(flag.tf_outputs as any).outputs.length > 0 &&
            tf_outputs !== undefined ? (
              <Alert className="mt-3" color="info" icon={InformationCircleIcon}>
                {(flag.tf_outputs as any).outputs.map((output: any) => (
                  <div key={output.key}>
                    {tf_outputs[output.key] !== undefined &&
                    isGCPKey(tf_outputs[output.key].value) ? (
                      <div className="flex flex-row">
                        <span>{output.name}:</span>
                        <CopyToClipboard
                          text={tf_outputs[output.key].value}
                          onCopy={() => {
                            setToastList(
                              toastList.concat({
                                type: "success",
                                children: "Copied to clipboard!",
                              })
                            )
                          }}>
                          <span className="flex flex-row ml-2 hover:underline text-red-logo hover:text-red-900 cursor-pointer">
                            Copy to clipboard
                            <FaClipboardList className="ml-1 my-auto" />
                          </span>
                        </CopyToClipboard>
                      </div>
                    ) : (
                      <>
                        <p className="break-all" key={output.key}>
                          {output.name}:{" "}
                          {tf_outputs[output.key] !== undefined
                            ? tf_outputs[output.key].value
                            : "Lab not deployed"}
                        </p>
                      </>
                    )}
                  </div>
                ))}
              </Alert>
            ) : (
              <></>
            )}
            <div className={!errMsg ? "sr-only" : ""}>
              <Alert
                className="mt-3"
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setErrMsg("")
                }}>
                <span ref={errRef}>{errMsg}</span>
              </Alert>
            </div>
            <div className="flex mt-5">
              {flag.type === FlagType.NoFlag ? (
                <>
                  <Button
                    type="button"
                    onClick={() => {
                      submitFlag()
                    }}
                    disabled={isCompleted}
                    className="ml-2 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2">
                    Complete
                  </Button>
                </>
              ) : (
                <>
                  <TextInput
                    disabled={isCompleted}
                    onChange={(e) => {
                      setValue(e.target.value)
                    }}
                    value={value}
                    className="grow"
                    name="value"
                    placeholder="flag{...}"
                    theme={{
                      field: {
                        input: {
                          base: "block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-neutral-50 border-neutral-300 text-neutral-900 focus:border-red-logo focus:ring-red-logo dark:border-neutral-600 dark:bg-neutral-700 dark:text-white dark:placeholder-neutral-400 dark:focus:border-red-logo dark:focus:ring-red-logo rounded-lg p-2.5 text-sm",
                        },
                      },
                    }}
                  />
                  <Button
                    type="button"
                    isProcessing={loadingSubmit}
                    processingSpinner={<Spinner color="failure" />}
                    onClick={() => {
                      submitFlag()
                    }}
                    disabled={isCompleted}
                    className="ml-2 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2">
                    Submit
                  </Button>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ClassroomFlag
