// import { Link } from "react-router-dom"
import { BannerProps } from "../components/Banner"
import { createContext, PropsWithChildren, useState } from "react"

export type BannerListData = BannerProps[]

interface BannersContextProps {
  bannerList: BannerListData
  setBannerList: (bannerList: BannerListData) => void
}

const BannersContext = createContext<BannersContextProps>({
  bannerList: [],
  setBannerList: () => {},
})

export const BannersProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [bannerList, setBannerList] = useState<BannerListData>([
    // {
    //   id: "black_friday_2024",
    //   children: (
    //     <div className="flex flex-col">
    //       <img
    //         src={BLACK_FRIDAY}
    //         className="w-full"
    //         alt="Black Friday Banner"
    //       />
    //     </div>
    //   ),
    // },
  ])
  return (
    <BannersContext.Provider value={{ bannerList, setBannerList }}>
      {children}
    </BannersContext.Provider>
  )
}

export default BannersContext
