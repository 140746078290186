import { useRef, useState, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { isAxiosError } from "axios"
import useApi from "../../hooks/useApi"
import { Alert, Button, Label, TextInput } from "flowbite-react"
import { ExclamationCircleIcon } from "@heroicons/react/24/solid"
import useToasts from "../../hooks/useToasts"
import useAuth from "../../hooks/useAuth"
import HT_TRAINING_logo from "../../assets/images/HT_TRAINING_logo.svg"

const TOKEN_REGEX = /^([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_\-+/=]*)$/

const ActivateAccount = () => {
  const { toastList, setToastList } = useToasts()
  const { auth } = useAuth()
  const { AuthApi } = useApi()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const errRef = useRef<HTMLInputElement>(null)
  const [token, setToken] = useState("")
  const [validToken, setValidToken] = useState(false)

  const [errMsg, setErrMsg] = useState("")

  useEffect(() => {
    const t = searchParams.get("token")
    if (t !== null) {
      setToken(t)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setErrMsg("")
  }, [token])

  useEffect(() => {
    setValidToken(TOKEN_REGEX.test(token))
  }, [token])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    // if button enabled with JS hack
    if (!TOKEN_REGEX.test(token)) {
      setErrMsg("Invalid data")
      return
    }

    try {
      const response = await AuthApi.authActivateAccount({
        token: token,
      })
      if (!response.data.success) {
        setErrMsg("Account activation failed with unknown error")
        return
      }
      //   console.log(JSON.stringify(response?.data))

      if (auth) {
        setToastList(
          toastList.concat({
            type: "success",
            children: "Account activated successfully!",
          })
        )
        navigate("/dashboard")
      } else {
        setToastList(
          toastList.concat({
            type: "success",
            children: "Account activated successfully!",
          })
        )
        navigate("/signin")
      }

      setToken("")
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Account activation failed with unknown error")
        }
      } else {
        setErrMsg("Account activation failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  return (
    <section>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-48 w-auto"
              src={HT_TRAINING_logo}
              alt="HackTricks Training"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
              Activate your account
            </h2>
          </div>
          <div className={!errMsg ? "sr-only" : ""}>
            <Alert
              color="failure"
              icon={ExclamationCircleIcon}
              onDismiss={function onDismiss() {
                setErrMsg("")
              }}
            >
              <span ref={errRef}>{errMsg}</span>
            </Alert>
          </div>
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="token" value="Token" />
              </div>
              <TextInput
                id="token"
                type="text"
                placeholder="Token"
                autoComplete="off"
                required={true}
                disabled={true}
                value={token}
                aria-invalid={validToken ? "false" : "true"}
                color={validToken ? "success" : !token ? "" : "failure"}
              />
            </div>

            <div className="text-sm">
              <p className="font-medium text-gray-600 dark:text-gray-200">
                Welcome to HackTricks Training!
              </p>
            </div>
            <div className="flex flex-col items-center">
              <Button
                disabled={!validToken ? true : false}
                className="w-full md:w-auto px-20 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default ActivateAccount
