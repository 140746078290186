import { Accordion, Card } from "flowbite-react"
import { FC } from "react"
import { BsFillQuestionSquareFill } from "react-icons/bs"

interface AnyObject {
  [key: string]: any
}

export type FAQsProps = {
  faqs?: AnyObject | undefined
}

const FAQs: FC<FAQsProps> = ({ faqs }) => {
  return (
    <Card
      className="mb-20 mx-3"
      theme={{
        root: {
          base: "flex rounded-lg border bg-white dark:bg-neutral-900 shadow-md border-neutral-300 dark:border-neutral-700 dark:bg-neutral-900 flex-col mb-20",
          children: "flex h-full flex-col justify-center gap-0 p-0",
        },
      }}
    >
      <h5 className=" flex text-2xl font-bold tracking-tight text-neutral-800 dark:text-white p-4">
        <BsFillQuestionSquareFill className="mr-3" />
        FAQs
      </h5>
      {faqs ? (
        <Accordion
          alwaysOpen={true}
          collapseAll={true}
          flush={true}
          className="!border-b-0 !divide-neutral-300 !border-neutral-300 dark:!divide-neutral-700 dark:!border-neutral-700"
          theme={{
            root: {
              base: "divide-y divide-neutral-300 border-neutral-300 dark:divide-neutral-700 dark:border-neutral-700 border-b",
            },
          }}
        >
          {faqs.questions.map((question: any) => (
            <Accordion.Panel key={question.index}>
              <Accordion.Title
                theme={{
                  base: "flex w-full items-center justify-between first:rounded-t-lg last:rounded-b-lg py-5 px-5 text-left font-medium text-neutral-800 dark:text-white !bg-transparent dark:!bg-transparent",
                }}
              >
                {question.question}
              </Accordion.Title>
              <Accordion.Content
                theme={{
                  base: "py-5 px-5 last:rounded-b-lg border-neutral-100 bg-neutral-100 dark:border-neutral-700 dark:bg-neutral-700 first:rounded-t-lg",
                }}
              >
                <p className="text-neutral-700 dark:text-white whitespace-pre-line">
                  {question.answer}
                </p>
              </Accordion.Content>
            </Accordion.Panel>
          ))}
        </Accordion>
      ) : (
        <p>No FAQs just yet</p>
      )}
    </Card>
  )
}

export default FAQs
