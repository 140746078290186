import { useContext } from "react"
import ToastsContext, {
  ToastListData as ToastListDataType,
} from "../context/ToastsProvider"

export type ToastListData = ToastListDataType

const useToasts = () => {
  return useContext(ToastsContext)
}

export default useToasts
