import { FC, PropsWithChildren } from "react"
import Chart from "react-apexcharts"
import useTheme from "../../hooks/useTheme"

export type DonutProps = PropsWithChildren<{
  colors: string[]
  labels: string[]
  series: number[]
  total_label: string
}>

const Donut: FC<DonutProps> = ({ colors, labels, series, total_label }) => {
  const { theme } = useTheme()
  return (
    <Chart
      options={{
        colors: colors,
        chart: {},
        stroke: {
          colors: ["transparent"],
          lineCap: "square",
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontFamily: "Inter, sans-serif",
                  offsetY: 20,
                  color: theme === "dark" ? "#fff" : "#000",
                },
                total: {
                  showAlways: true,
                  show: true,
                  label: total_label,
                  fontFamily: "Inter, sans-serif",
                  color: theme === "dark" ? "#fff" : "#000",
                  formatter: function (w) {
                    const sum = w.globals.seriesTotals.reduce(
                      (a: any, b: any) => {
                        return a + b
                      },
                      0
                    )
                    return `${sum}`
                  },
                },
                value: {
                  show: true,
                  fontFamily: "Inter, sans-serif",
                  color: theme === "dark" ? "#fff" : "#000",
                  offsetY: -20,
                  formatter: function (value) {
                    return value
                  },
                },
              },
              size: "80%",
            },
          },
        },
        grid: {
          padding: {
            top: -2,
          },
        },
        labels: labels,
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "bottom",
          fontFamily: "Inter, sans-serif",
          labels: {
            colors: theme === "dark" ? "#fff" : "#000",
          },
        },
        yaxis: {
          labels: {
            formatter: function (value: number) {
              return value.toString()
            },
          },
        },
        xaxis: {
          labels: {
            formatter: function (value: string) {
              return value
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
      }}
      series={series}
      type="donut"
      width="100%"
    />
  )
}

export default Donut
