import { Button } from "flowbite-react"
import { Link, useNavigate } from "react-router-dom"
import Footer from "../../components/Footer"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"
import NavbarPublic from "../../components/navbar/NavbarPublic"
import useAuth from "../../hooks/useAuth"

const Unauthorized = () => {
  const navigate = useNavigate()
  const { auth } = useAuth()
  const goBack = () => navigate(-1)

  return (
    <section className="min-h-screen flex flex-col justify-between">
      {auth ? <NavbarPrivate /> : <NavbarPublic />}
      <div className="mb-auto">
        <div className="container mx-auto">
          <section>
            {/* TODO the error page is not centered vertically */}
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
              <div className="mx-auto max-w-screen-sm text-center">
                <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-gray-800 dark:text-white">
                  401
                </h1>
                <p className="mb-4 text-3xl tracking-tight font-bold text-gray-800 md:text-4xl dark:text-white">
                  Nothing to see here.
                </p>
                <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                  Sorry, you don't have access to this page. You'll find lots to
                  explore on the home page.
                </p>
                <div className="grid grid-cols-2 gap-4">
                  <Button
                    outline={true}
                    onClick={goBack}
                    gradientDuoTone="purpleToBlue"
                  >
                    Go back
                  </Button>
                  <Button as={Link} to="/" gradientDuoTone="purpleToBlue">
                    Back to homepage
                  </Button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Unauthorized
